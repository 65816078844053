import { useContext, useEffect, useState } from "react";
import { MessageThread, TMUser } from "../../types";
import { UserContext } from "../../App";
import { fetchUser } from "../../utils";
import ProfileImage from "../ProfileImage/ProfileImage";
import Anchor from "../Anchor/Anchor";
import Button from "../Button/Button";

import styles from "./messageThreadPreviewStyles.module.css";

type MessageThreadPreviewProps = {
  data: MessageThread;
  onClick: (u: TMUser) => void;
};

const MessageThreadPreview = (props: MessageThreadPreviewProps) => {
  const [otherUser, setOtherUser] = useState<TMUser>();
  const session = useContext(UserContext);
  const numUnreadMessages = props.data.unreadMessages;

  useEffect(() => {
    fetchUser(props.data.docId).then((data) => {
      setOtherUser(data);
    });
  }, [props.data]);

  if (otherUser) {
    return (
      <Button
        noStyling
        onClick={() => {
          props.onClick(otherUser);
        }}
        className={styles.wrapper}
      >
        <ProfileImage
          imgLocation={otherUser?.imgLocation}
          size="xs"
          style={{ marginRight: "10px" }}
        />
        <div
          className={[
            styles.contentWrapper,
            numUnreadMessages > 0 ? styles.unread : null,
          ].join(" ")}
        >
          <div className={styles.otherUserWrapper}>
            {otherUser?.firstName} {otherUser?.lastName}
          </div>
          <div className={styles.messagePreview}>
            {props.data.lastSender === session?.uid
              ? "Message Sent"
              : numUnreadMessages > 1
              ? `${numUnreadMessages} new messages`
              : props.data.lastMessage}
          </div>
        </div>
      </Button>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default MessageThreadPreview;
