import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import styles from "./postDetailStyles.module.css";
import { ReactNode, useContext, useEffect, useState } from "react";
import {
  addToLikedPosts,
  postIsLiked,
  removeFromLikedPosts,
} from "../../utils";
import { FlashPost, TMUser, TattooPost } from "../../types";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faShare } from "@fortawesome/free-solid-svg-icons";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import Anchor from "../../components/Anchor/Anchor";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import HeartIcon from "../../components/HeartIcon/HeartIcon";
import { UserContext } from "../../App";
import Spinner from "../../components/Spinner/Spinner";
import Auth from "../../components/Auth/Auth";

type PostDetailProps = {
  data: FlashPost | TattooPost;
  imgUrl: string;
  artistData: TMUser;
  loadingData: boolean;
  children?: ReactNode;
  type: string;
};

const PostDetail = (props: PostDetailProps) => {
  const user = useContext(UserContext);
  const [liked, setLiked] = useState(false);
  const [showAuthPopUp, setShowAuthPopUp] = useState(false);

  const navigate = useNavigate();

  const checkIfLiked = (uid: string, idFlash: string) => {
    postIsLiked(uid, idFlash).then((isLiked) => {
      setLiked(isLiked);
    });
  };

  useEffect(() => {
    if (user) {
      checkIfLiked(user.uid, props.data.docId);
    }
  }, []);

  const handleLike = () => {
    if (!user) {
      setShowAuthPopUp(true);
    }
    if (user && props.data) {
      if (!liked) {
        addToLikedPosts(
          user.uid,
          props.data.docId,
          props.data.artistId,
          props.type
        ).then(() => {
          setLiked(true);
          checkIfLiked(user.uid, props.data.docId);
        });
      } else {
        removeFromLikedPosts(user.uid, props.data.docId).then(() => {
          setLiked(false);
          checkIfLiked(user.uid, props.data.docId);
        });
      }
    }
  };

  if (props.loadingData) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }

  return (
    <Layout
      showPopUp={showAuthPopUp}
      popUpComponent={
        <Auth
          handleExit={() => {
            setShowAuthPopUp(false);
          }}
        />
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <img src={props.imgUrl} className={styles.image} />
        </div>
        <div className={styles.bodyWrapper}>
          <div>
            <div className={styles.iconsWrapper}>
              <Button
                round
                color="white"
                onClick={handleLike}
                className={styles.iconButton}
              >
                <HeartIcon liked={liked} />
              </Button>
              <Button
                round
                color="white"
                onClick={() => {
                  // ...
                }}
                className={styles.iconButton}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </div>
            <div
              className={styles.artistWrapper}
              style={{ marginBottom: "20px" }}
            >
              <ProfileImage
                imgLocation={props.artistData.imgLocation}
                size="xs"
                style={{ marginRight: "10px" }}
                href={`/u/${props.artistData.username}`}
              />
              <div>
                <h4>{`${props.artistData.firstName} ${props.artistData.lastName}`}</h4>
                <Link
                  className={styles.artistLink}
                  to={`/u/${props.artistData.username}`}
                >{`@${props.artistData.username}`}</Link>
              </div>
            </div>
            {props.data?.caption ? (
              <p style={{ marginBottom: "20px", fontSize: "1.2em" }}>
                {props.data.caption}
              </p>
            ) : null}
            <h4>Tags</h4>
            <div className={styles.tagsWrapper}>
              {props.data?.tags.map((tag) => (
                <div key={tag} className={styles.tagBubble}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
          {props.type === "flash" && (
            <Button
              href={`/booking-request/${props.artistData.username}/f/${props.data.docId}`}
            >
              Book Now <FontAwesomeIcon icon={faArrowRightLong} />
            </Button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PostDetail;
