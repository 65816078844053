import { createRef, MouseEvent } from "react";
import RadioButton from "../RadioButton/RadioButton";
import styles from "./locationRadioStyles.module.css";

type LocationRadioProps = {
  onChange: () => void;
  checked: boolean;
  label: string;
  id: string;
  header?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export const LocationRadio = (props: LocationRadioProps) => {
  const ref = createRef<HTMLInputElement>();
  return (
    <div
      className={[
        styles.locationRadioWrapper,
        props.checked ? styles.checked : null,
      ].join(" ")}
      onClick={() => {
        ref.current?.click();
      }}
      style={props.style}
    >
      {props.header ? (
        <p className={styles.description}>{props.header}</p>
      ) : null}

      <RadioButton
        ref={ref}
        onChange={props.onChange}
        checked={props.checked}
        id={props.id}
        label={props.label}
      />
      {props.children && (
        <div
          style={{ marginTop: "10px" }}
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
          }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default LocationRadio;
