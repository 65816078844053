import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../services/firebase/firebase";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const PayDeposit = () => {
  const { appointmentId } = useParams();
  const getClientSecret = httpsCallable<
    { appointmentId: string },
    { clientSecret: string }
  >(functions, "createDestinationCheckoutSession");
  const fetchClientSecret = useCallback(() => {
    return getClientSecret({ appointmentId: appointmentId! }).then(
      (result) => result.data.clientSecret
    );
  }, []);

  const options = { fetchClientSecret };

  return (
    <Layout>
      <br />
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Layout>
  );
};

export default PayDeposit;
