import { Fragment, useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { TMUserContext, UserContext } from "../../App";
import Button from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import FlashCard from "../../components/FlashCard/FlashCard";
import { functions } from "../../services/firebase/firebase";
import {
  fetchAppointments,
  fetchClientAppointments,
  fetchFavoriteArtists,
  fetchImageUrl,
  fetchLikedPosts,
  fetchUser,
} from "../../utils";
import {
  Appointment,
  FavoriteArtist,
  FlashPost,
  LikedPosts,
  MyUser,
  TMUser,
} from "../../types";
import styles from "./accountDashboardStyles.module.css";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faPlus,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { AppointmentPreview } from "../../components/CalendarSidebar/CalendarSidebar";
import Anchor from "../../components/Anchor/Anchor";
import { httpsCallable } from "firebase/functions";
import Spinner from "../../components/Spinner/Spinner";
import TattooCard from "../../components/TattooCard/TattooCard";
import FavoriteArtistPreview from "../../components/FavoriteArtistPreview/FavoriteArtistPreview";
import { faFaceSmile, faStar } from "@fortawesome/free-regular-svg-icons";
import DropdownButton from "../../components/DropdownButton/DropdownButton";
import BooksOpenOrClosed from "../../components/BooksOpenOrClosed/BooksOpenOrClosed";
import CalendarWidget from "./components/CalendarWidget/CalendarWidget";

const AccountDashboard = () => {
  const session = useContext(UserContext);
  const { userData, setUserData } = useContext(TMUserContext);

  const [likedPosts, setLikedPosts] = useState<LikedPosts[]>([]);
  const [favoriteArtists, setFavoriteArtists] = useState<FavoriteArtist[]>([]);
  const [suggestedPosts, setSuggestedPosts] = useState<FlashPost[]>([]);
  const [loadingSuggestedPosts, setLoadingSuggestedPosts] = useState(true);

  const getLikedPosts = (uid: string) => {
    fetchLikedPosts(uid).then((data) => {
      setLikedPosts(data);
    });
  };

  useEffect(() => {
    if (userData) {
      if (!userData.clientOnboardingCompleted) {
        navigate("/onboarding");
      }
    }
  }, [userData]);

  useEffect(() => {
    if (session) {
      const getUserData = httpsCallable<undefined, MyUser>(
        functions,
        "getUserData"
      );
      getUserData().then((result) => {
        setUserData(result.data);
      });
      getLikedPosts(session.uid);
      fetchFavoriteArtists(session.uid).then((data) => {
        setFavoriteArtists(data);
      });

      const getSuggestedPosts = httpsCallable(functions, "getSuggestedPosts");

      getSuggestedPosts({
        uid: session.uid,
      })
        .then((result) => {
          const data = result.data as { posts: FlashPost[]; error: string };
          if (data.posts) {
            setSuggestedPosts(data.posts);
          } else {
            // ...
          }
        })
        .catch((error) => {
          const code = error.code;
          const message = error.message;
          const details = error.details;
        })
        .finally(() => {
          setLoadingSuggestedPosts(false);
        });
    }
  }, [session]);

  const navigate = useNavigate();

  if (session && userData) {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <div className={[styles.leftWrapper].join(" ")}>
            <div
              className={[styles.profileWrapper, styles.shadowWrapper].join(
                " "
              )}
            >
              <ProfileImage
                imgUrl={userData.imageURL}
                href={`/u/${userData.username}`}
                size="m"
              />
              <h3>
                {userData.firstName} {userData.lastName}
              </h3>
              <div className="mb10">@{userData.username}</div>
              {userData.accountType === "artist" && (
                <BooksOpenOrClosed
                  open={userData.booksOpen || false}
                  className="mb10"
                />
              )}
              <div className={[styles.accountTypeWrapper, "mb10"].join(" ")}>
                <div className="mr5">
                  <FontAwesomeIcon icon={faUser} className="mr5" />
                  {userData.accountType === "artist"
                    ? "Artist Account"
                    : "Client Account"}
                </div>
                {userData.isProUser ? (
                  <div className={styles.accountPlanWrapper}>
                    <FontAwesomeIcon icon={faStar} className="mr5" />
                    TatMap Pro
                  </div>
                ) : (
                  userData.accountType === "artist" && (
                    <div className={styles.accountPlanWrapper}>
                      <FontAwesomeIcon icon={faFaceSmile} className="mr5" />
                      Free Tier
                    </div>
                  )
                )}
              </div>
              {userData.accountType === "artist" && !userData.isProUser && (
                <Link
                  to="/pro-account-dashboard"
                  style={{ fontSize: "0.8em" }}
                  className="mb10"
                >
                  Upgrade to Pro
                </Link>
              )}
              <div className={styles.actionsWrapper}>
                <Button
                  onClick={() => {
                    navigate("/new-flash-post");
                  }}
                  className={styles.actionButton}
                >
                  <FontAwesomeIcon icon={faPlus} /> post flash
                </Button>
                <Button
                  onClick={() => {
                    navigate("/tattoo-post");
                  }}
                  color="blue"
                  className={styles.actionButton}
                >
                  <FontAwesomeIcon icon={faPlus} /> post a tattoo
                </Button>
                <Button
                  onClick={() => {
                    navigate("/calendar");
                  }}
                  color="gray"
                  className={styles.actionButton}
                >
                  <FontAwesomeIcon icon={faCalendarDays} /> manage calendar
                </Button>
              </div>
            </div>

            <div className={styles.shadowWrapper}>
              <CalendarWidget />
            </div>
          </div>
          <div className={styles.middleWrapper}>
            <div className={styles.shadowWrapper}>
              <h4 style={{ marginBottom: "10px" }}>suggested posts</h4>
              <div className={styles.suggestedPostsWrapper}>
                {loadingSuggestedPosts ? (
                  <Spinner />
                ) : (
                  suggestedPosts.map((post) => (
                    <FlashCard
                      artistId={post.artistId}
                      key={post.docId}
                      flashId={post.docId}
                      data={post}
                      showArtistLabel
                      size="responsive50"
                      style={{ padding: "5px" }}
                      onChangeLike={() => {
                        getLikedPosts(session.uid);
                      }}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
          <div className={styles.rightWrapper}>
            <div className={styles.shadowWrapper}>
              <h3 style={{ marginBottom: "10px" }}>liked posts</h3>
              <div className={styles.likedPostsWrapper}>
                {likedPosts.length > 0 ? (
                  <Fragment>
                    {likedPosts.map((p) => {
                      if (p.type === "flash") {
                        return (
                          <FlashCard
                            artistId={p.artistId}
                            key={p.docId}
                            flashId={p.docId}
                            size="responsive50"
                            showArtistLabel
                            onChangeLike={() => {
                              getLikedPosts(session.uid);
                            }}
                          />
                        );
                      }
                      if (p.type === "tattoo") {
                        return (
                          <TattooCard
                            artistId={p.artistId}
                            key={p.docId}
                            docId={p.docId}
                            size="responsive50"
                            showArtistLabel
                            onChangeLike={() => {
                              getLikedPosts(session.uid);
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </Fragment>
                ) : (
                  <div className={styles.nothingHere}>
                    <p className={styles.nothingHereText}>nothing here yet</p>
                    <Button
                      onClick={() => {
                        navigate("/discover");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ marginRight: "10px" }}
                      />{" "}
                      find designs
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.shadowWrapper}>
              <h3 style={{ marginBottom: "10px" }}>favorite artists</h3>
              <div className={styles.favoriteArtistsWrapper}>
                {favoriteArtists.length > 0 ? (
                  <Fragment>
                    {favoriteArtists.map((artist) => (
                      <FavoriteArtistPreview key={artist.docId} data={artist} />
                    ))}
                  </Fragment>
                ) : (
                  <div className={styles.nothingHere}>
                    <p className={styles.nothingHereText}>nothing here yet</p>
                    <Button
                      onClick={() => {
                        navigate("/discover/artists");
                      }}
                      style={{ margin: "0 auto" }}
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ marginRight: "10px" }}
                      />
                      find artists
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
  return <Spinner />;
};

export default AccountDashboard;
