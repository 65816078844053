import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { ChangeEvent, Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../services/firebase/firebase";
import {
  addToFavoriteArtists,
  artistIsFavorite,
  converter,
  fetchFavoriteArtists,
  fetchLikedPosts,
  fetchMessageThread,
  fetchMessages,
  fetchOpenings,
  fetchUserByUsername,
  openingConverter,
  removeFromFavoriteArtists,
} from "../../utils";
import {
  FavoriteArtist,
  FlashPost,
  LikedPosts,
  Message,
  MessageThread,
  Opening,
  TMUser,
  TattooPost,
} from "../../types";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import Layout from "../../components/Layout/Layout";
import styles from "./artistPageStyles.module.css";
import FlashCard from "../../components/FlashCard/FlashCard";
import Tabs from "../../components/Tabs/Tabs";
import CalendarPlusSidebar from "../../components/CalendarPlusSidebar/CalendarPlusSidebar";
import Button from "../../components/Button/Button";
import PopUp from "../../components/PopUp/PopUp";
import { UserContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import TattooCard from "../../components/TattooCard/TattooCard";
import BooksOpenOrClosed from "../../components/BooksOpenOrClosed/BooksOpenOrClosed";
import PostCard from "../../components/PostCard/PostCard";
import FavoriteArtistPreview from "../../components/FavoriteArtistPreview/FavoriteArtistPreview";

type MessagePopUpProps = {
  handleExit: () => void;
  recipient: TMUser;
};

const MessagePopUp = (props: MessagePopUpProps) => {
  const session = useContext(UserContext);
  const navigate = useNavigate();
  const [thread, setThread] = useState<MessageThread>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageContent, setMessageContent] = useState("");

  useEffect(() => {
    if (session) {
      fetchMessageThread(session.uid, props.recipient.docId).then((data) => {
        setThread(data);
        if (data) {
          fetchMessages(session.uid, props.recipient.docId).then((d) => {
            setMessages(d);
          });
        }
      });
    }
  }, []);
  return (
    <PopUp handleExit={props.handleExit}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <ProfileImage imgLocation={props.recipient.imgLocation} size="xs" />
        <h5>
          {props.recipient.firstName} {props.recipient.lastName}
        </h5>
        <div
          className={styles.handle}
          onClick={() => {
            navigate(`/u/${props.recipient.uid}`);
          }}
        >
          @{props.recipient.username}
        </div>
      </div>
      <textarea
        className={styles.messageText}
        value={messageContent}
        onChange={(v: ChangeEvent<HTMLTextAreaElement>) => {
          setMessageContent(v.currentTarget.value);
        }}
      ></textarea>
      <Button
        onClick={async () => {
          const threadRef = thread
            ? doc(db, "messageThreads", thread.docId)
            : doc(collection(db, "messageThreads"));
          if (!thread) {
            await setDoc(threadRef, {
              uid1: session?.uid,
              uid2: props.recipient.docId,
              updatedAt: serverTimestamp(),
              numMembers: 2,
              unreadMessages: 1,
              lastMessage: messageContent,
              lastSender: session?.uid,
            });
          } else {
            await setDoc(
              threadRef,
              {
                unreadMessages: increment(1),
                lastMessage: messageContent,
                updatedAt: serverTimestamp(),
              },
              { merge: true }
            );
          }
          const messageRef = doc(
            collection(db, "messageThreads", threadRef.id, "messages")
          );
          await setDoc(messageRef, {
            sender: session?.uid,
            recipient: props.recipient.docId,
            content: messageContent,
            createdAt: serverTimestamp(),
            read: false,
          });
          props.handleExit();
        }}
      >
        Send Message
      </Button>
    </PopUp>
  );
};

const ArtistPage = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const artistId: string = useParams().artistId!;
  const [userData, setUserData] = useState<TMUser>();
  const [flashPosts, setFlashPosts] = useState<FlashPost[]>();
  const [tattooPosts, setTattooPosts] = useState<TattooPost[]>();
  const [openings, setOpenings] = useState<Opening[]>([]);
  const [tabs, setTabs] = useState<string[]>();
  const [selectedTab, setSelectedTab] = useState<string>();
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [likedPosts, setLikedPosts] = useState<LikedPosts[]>();
  const [favoriteArtists, setFavoriteArtists] = useState<FavoriteArtist[]>();

  const fetchPosts = async (uid: string) => {
    const q = query(
      collection(db, "users", uid, "flash"),
      limit(100)
    ).withConverter(converter<FlashPost>());
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());
    setFlashPosts(data);
  };

  const fetchTattoos = async (uid: string) => {
    const q = query(
      collection(db, "users", uid, "tattoos"),
      limit(100)
    ).withConverter(converter<TattooPost>());
    const querySnapshot = await getDocs(q);
    const posts = querySnapshot.docs.map((doc) => doc.data());
    setTattooPosts(posts);
  };

  const getOpenings = async (uid: string) => {
    fetchOpenings(uid).then((data) => {
      setOpenings(data);
    });
  };

  useEffect(() => {
    fetchUserByUsername(artistId).then((data) => {
      setIsCurrentUser(data.docId === user?.uid);
      setUserData(data);
      fetchPosts(data.docId);
      fetchTattoos(data.docId);
      getOpenings(data.docId);
      fetchLikedPosts(data.docId).then((p) => {
        setLikedPosts(p);
      });
      fetchFavoriteArtists(data.docId).then((a) => {
        setFavoriteArtists(a);
      });
    });
  }, [artistId]);

  useEffect(() => {
    const t = [
      ...(flashPosts && flashPosts.length > 0 ? ["Flash"] : []),
      ...(tattooPosts && tattooPosts.length > 0 ? ["Tattoos"] : []),
      ...(userData?.accountType === "artist" ? ["Availability"] : []),
      ...["Likes"],
      ...["Favorite Artists"],
    ];
    setTabs(t);
    setSelectedTab(t[0]);
  }, [flashPosts, tattooPosts, userData]);

  const checkIfFavorite = (uid: string, artId: string) => {
    artistIsFavorite(uid, artId).then((result) => {
      setIsFavorite(result);
    });
  };

  useEffect(() => {
    if (userData && user) {
      checkIfFavorite(user.uid, userData.docId);
    }
  }, [userData]);

  const handleAddToFavorites = () => {
    if (user && userData) {
      setIsFavorite(true);
      addToFavoriteArtists(user.uid, userData.docId, userData.imgLocation).then(
        () => {
          checkIfFavorite(user.uid, userData.docId);
        }
      );
    }
  };

  const handleRemoveFromFavorites = () => {
    if (user && userData) {
      setIsFavorite(false);
      removeFromFavoriteArtists(user.uid, userData.docId).then(() => {
        checkIfFavorite(user.uid, userData.docId);
      });
    }
  };

  if (userData) {
    return (
      <Layout
        showPopUp={showMessagePopUp}
        popUpComponent={
          <MessagePopUp
            recipient={userData}
            handleExit={() => {
              setShowMessagePopUp(false);
            }}
          ></MessagePopUp>
        }
      >
        <div className={styles.wrapper}>
          <div className={[styles.headerWrapper].join(" ")}>
            <ProfileImage imgLocation={userData?.imgLocation} size="m" />
            <h2 className={styles.name}>
              {userData?.firstName} {userData?.lastName}
            </h2>
            <div className={[styles.handle, "mb10"].join(" ")}>
              @{userData?.username}
            </div>
            {userData.accountType === "artist" && (
              <BooksOpenOrClosed open={userData.booksOpen || false} />
            )}
            <div className={styles.aboutWrapper}>{userData?.about}</div>
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonLeftWrapper}>
                {isCurrentUser ? (
                  <Button
                    onClick={() => {
                      navigate("/new-flash-post");
                    }}
                    color="gray"
                  >
                    <FontAwesomeIcon icon={faPlus} /> Create Post
                  </Button>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      onClick={() => {
                        setShowMessagePopUp(true);
                      }}
                      color="gray"
                      circleSmall
                      style={{ marginRight: "5px" }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(`/booking-request/${userData.username}`);
                      }}
                      color="blue"
                    >
                      Book Appt.
                    </Button>
                  </div>
                )}
              </div>
              {isCurrentUser ? (
                <Button
                  onClick={() => {
                    navigate("/settings/edit-profile");
                  }}
                >
                  Edit Profile
                </Button>
              ) : isFavorite ? (
                <Button color="red" onClick={handleRemoveFromFavorites}>
                  Remove from Favorites
                </Button>
              ) : (
                <Button onClick={handleAddToFavorites}>Add to Favorites</Button>
              )}
            </div>
          </div>
          <div className={styles.tabsWrapper}>
            <Tabs
              className={styles.tabs}
              values={[
                ...(flashPosts && flashPosts.length > 0 ? ["Flash"] : []),
                ...(tattooPosts && tattooPosts.length > 0 ? ["Tattoos"] : []),
                ...(userData.accountType === "artist" ? ["Availability"] : []),
                ...["Likes"],
                ...["Favorite Artists"],
              ]}
              onChange={(v) => {
                setSelectedTab(v);
              }}
              selected={selectedTab}
            />
          </div>
          <div className={styles.bodyWrapper}>
            {selectedTab === "Flash" && (
              <div className={styles.postsWrapper}>
                {flashPosts?.map((data, i) => (
                  <FlashCard
                    artistId={data.artistId}
                    flashId={data.docId}
                    data={data}
                    key={data.docId}
                    style={{ padding: "3px" }}
                  ></FlashCard>
                ))}
              </div>
            )}
            {selectedTab === "Tattoos" && (
              <div className={styles.postsWrapper}>
                {tattooPosts?.map((data, i) => (
                  <TattooCard
                    artistId={data.artistId}
                    docId={data.docId}
                    data={data}
                    key={data.docId}
                    style={{ padding: "3px" }}
                  ></TattooCard>
                ))}
              </div>
            )}
            {selectedTab === "Availability" && (
              <CalendarPlusSidebar
                calendarOwner={userData}
                openings={openings}
                reloadOpenings={() => {
                  fetchOpenings(userData.docId);
                }}
                clientView
              />
            )}
            <div className={styles.postsWrapper}>
              {selectedTab === "Likes" &&
                likedPosts?.map((p) =>
                  p.type === "flash" ? (
                    <FlashCard
                      key={p.docId}
                      flashId={p.docId}
                      artistId={p.artistId}
                      style={{ padding: "3px" }}
                    ></FlashCard>
                  ) : (
                    <TattooCard
                      key={p.docId}
                      docId={p.docId}
                      artistId={p.artistId}
                      style={{ padding: "3px" }}
                    ></TattooCard>
                  )
                )}
            </div>
            <div>
              {selectedTab === "Favorite Artists" &&
                favoriteArtists?.map((a) => (
                  <FavoriteArtistPreview key={a.docId} data={a} />
                ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default ArtistPage;
