import { useEffect, useState, MouseEvent, useContext } from "react";
import { TMUser, TattooPost } from "../../types";
import { fetchTattooPost, fetchUser } from "../../utils";

import PostCard, { PostCardPlaceholder } from "../PostCard/PostCard";

type Props = {
  docId: string;
  artistId: string;
  data?: TattooPost;
  showArtistLabel?: boolean;
  size?: string;
  onChangeLike?: () => void;
  style?: React.CSSProperties;
  onShowAuthPopUp?: () => void;
};

const TattooCard = (props: Props) => {
  const [postData, setPostData] = useState<TattooPost | null>(
    props.data || null
  );
  const [artistData, setArtistData] = useState<TMUser>();

  const getArtistData = async (artistId: string) => {
    const data = await fetchUser(artistId);
    setArtistData(data);
  };

  useEffect(() => {
    if (!postData) {
      fetchTattooPost(props.docId, props.artistId).then((data) => {
        setPostData(data);
      });
    }
    getArtistData(props.artistId);
  }, []);

  if (artistData && postData) {
    return (
      <PostCard
        data={postData}
        artistData={artistData}
        showArtistLabel={props.showArtistLabel}
        onChangeLike={props.onChangeLike}
        type="tattoo"
        size={props.size}
        onShowAuthPopUp={props.onShowAuthPopUp}
        style={props.style}
      />
    );
  }

  // Loading/Placeholder Sqaure
  return (
    <PostCardPlaceholder
      size={props.size}
      showArtistLabel={props.showArtistLabel}
      style={props.style}
    />
  );
};

export default TattooCard;
