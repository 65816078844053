import { Fragment } from "react/jsx-runtime";
import Button from "../Button/Button";
import styles from "./tabsStyles.module.css";

type TabsProps = {
  values: string[];
  onChange: (v: string) => void;
  selected?: string;
  className?: string;
};

const Tabs = (props: TabsProps) => {
  return (
    <Fragment>
      {props.values.map((v) => (
        <Button
          key={v}
          noStyling
          onClick={() => {
            props.onChange(v);
          }}
          className={[
            styles.tab,
            props.selected === v ? styles.selected : null,
            props.className,
          ].join(" ")}
        >
          {v}
        </Button>
      ))}
    </Fragment>
  );
};

export default Tabs;
