import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./filterBarStyles.module.css";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../Dropdown/Dropdown";
import { useState } from "react";
import Button from "../Button/Button";

type FilterBarDropdownProps = {
  handleExit: () => void;
  children?: React.ReactNode;
};

const FilterBarDropdown = (props: FilterBarDropdownProps) => {
  return (
    <Dropdown left handleExit={props.handleExit} className={styles.dropdown}>
      <div>{props.children}</div>
      <div>
        <Button style={{ minWidth: "150px" }} onClick={props.handleExit}>
          Apply
        </Button>
      </div>
    </Dropdown>
  );
};

type FilterBarButtonProps = {
  children?: React.ReactNode;
  dropdownContent?: React.ReactNode;
  onClick?: () => void;
};

export const FilterBarButton = (props: FilterBarButtonProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className={styles.buttonWrapper}>
      <button
        className={styles.filterButton}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          } else {
            setShowDropdown(true);
          }
        }}
      >
        {props.children}
        <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: "10px" }} />
      </button>
      {showDropdown && (
        <FilterBarDropdown
          handleExit={() => {
            setShowDropdown(false);
          }}
        >
          {props.dropdownContent}
        </FilterBarDropdown>
      )}
    </div>
  );
};

type FilterBarProps = {
  children?: React.ReactNode;
};

const FilterBar = (props: FilterBarProps) => {
  return <div className={styles.wrapper}>{props.children}</div>;
};

export default FilterBar;
