export const firebaseConfig = {
  apiKey: "AIzaSyDLits-ltmkQi5ODgqrzuJ980MO94lGl18",
  authDomain: "openbook-c57a5.firebaseapp.com",
  projectId: "openbook-c57a5",
  storageBucket: "openbook-c57a5.appspot.com",
  messagingSenderId: "497317933876",
  appId: "1:497317933876:web:bd48c09b01760fbfdd6b61",
  measurementId: "G-67QNJC1K32",
  mapsApiKey: "AIzaSyBmF5NA9Pd46a6OMlZWyriVhgItd59Rg1c",
};
