import Button from "../Button/Button";
import styles from "./headerStyles.module.css";
import { Fragment, MouseEvent, useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../services/firebase/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCaretDown,
  faGear,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faCalendarDays,
  faCommentDots,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import { MessageThread, TMNotification } from "../../types";
import { fetchMessageThreads, fetchNotifications } from "../../utils";
import Anchor from "../Anchor/Anchor";
import MyNotification from "../MyNotification/MyNotification";
import Dropdown from "../Dropdown/Dropdown";
import ProfileImage from "../ProfileImage/ProfileImage";
import { TMUserContext, UserContext } from "../../App";
import SearchBar from "../SearchBar/SearchBar";
import Messages from "../Messages/Messages";
import Notifications from "../Notifications/Notifications";

const logo = require("../../assets/logo_yellow.png");
const mobileLogo = require("../../assets/logo_small.png");

type DropdownElementProps = {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
};

const DropdownElement = (props: DropdownElementProps) => {
  if (props.href) {
    return (
      <Link className={styles.dropdownElement} to={props.href}>
        {props.children}
      </Link>
    );
  }

  return (
    <Anchor className={styles.dropdownElement} onClick={props.onClick}>
      {props.children}
    </Anchor>
  );
};

type DropdownButtonProps = {
  onClick: () => void;
  alerts?: number;
  children?: React.ReactNode;
  dropdownContent?: React.ReactNode;
  className?: string;
  href?: string;
};

const DropdownButton = (props: DropdownButtonProps) => {
  return (
    <Button
      circleSmall
      color="white"
      href={props.href}
      onClick={props.onClick}
      className={[styles.dropDownButton, props.className].join(" ")}
    >
      {props.alerts ? (
        <div className={styles.alerts}>{props.alerts}</div>
      ) : null}
      <div className={styles.dropdownButtonChildren}>{props.children}</div>
    </Button>
  );
};

const HeaderLogo = () => {
  const session = useContext(UserContext);
  return (
    <Button href={session ? "/home" : "/"} noStyling>
      <img
        src={logo}
        style={{ height: "3.2em" }}
        className={"desktopComponent"}
      />
      <img
        src={mobileLogo}
        style={{ height: "2em" }}
        className={"mobileComponent"}
      />
    </Button>
  );
};

type HeaderProps = {
  handleLogin: (event: MouseEvent<HTMLElement>) => void;
  handleSignUp: (event: MouseEvent<HTMLElement>) => void;
  handleShowSearchPopUp: () => void;
};

const Header = (props: HeaderProps) => {
  const session = useContext(UserContext);
  const { userData } = useContext(TMUserContext);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<TMNotification[]>([]);
  const [messageThreads, setMessageThreads] = useState<MessageThread[]>([]);
  const [unreadMessageThreads, setUnreadMessageThreads] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const loadMessageThreads = () => {
    if (session) {
      fetchMessageThreads(session.uid).then((d) => {
        setMessageThreads(d);
      });
    }
  };

  useEffect(() => {
    if (session) {
      fetchNotifications(session.uid).then((d) => {
        setNotifications(d);
      });
      loadMessageThreads();
    }
  }, [session]);

  useEffect(() => {
    const numUnread = messageThreads.reduce((accumulator, val) => {
      if (val.unreadMessages > 0) {
        return accumulator + 1;
      } else {
        return accumulator;
      }
    }, 0);
    setUnreadMessageThreads(numUnread);
  }, [messageThreads]);

  return (
    <header className={styles.wrapper}>
      {showNotifications && (
        <Dropdown
          handleExit={() => {
            setShowNotifications(false);
          }}
        >
          <Notifications data={notifications} />
        </Dropdown>
      )}
      {showMessages && (
        <Dropdown
          handleExit={() => {
            setShowMessages(false);
            loadMessageThreads();
          }}
        >
          <Messages
            threads={messageThreads}
            reloadThreads={loadMessageThreads}
          />
        </Dropdown>
      )}
      {showDropdown && (
        <Dropdown
          handleExit={() => {
            setShowDropdown(false);
          }}
        >
          <Fragment>
            {userData?.accountType === "artist" && (
              <DropdownElement href="/pro-account-dashboard">
                <FontAwesomeIcon icon={faStar} className="mr5" />
                TatMap Pro
              </DropdownElement>
            )}
            <DropdownElement href="/settings">
              <FontAwesomeIcon icon={faGear} className="mr5" />
              Settings
            </DropdownElement>
            <DropdownElement href="/">
              <div className={styles.dropdownElementTextWrapper}>
                <div>Terms of Service</div>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </div>
            </DropdownElement>
            <DropdownElement href="/">
              <div className={styles.dropdownElementTextWrapper}>
                <div>Privacy Policy</div>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </div>
            </DropdownElement>
            <DropdownElement
              onClick={() => {
                signOut(auth)
                  .then(() => {
                    setShowDropdown(false);
                    navigate("/");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Log Out
            </DropdownElement>
          </Fragment>
        </Dropdown>
      )}
      <div className={styles.leftNavWrapper}>
        <HeaderLogo />
        <Button color="white" href="/discover" className={styles.navLink}>
          Discover
        </Button>
      </div>
      <div className={styles.searchBarWrapper}>
        <SearchBar
          onSelect={(v) => {
            navigate("/discover/posts", { state: v });
          }}
          className="desktopComponent"
        />
      </div>
      {session ? (
        <div className={styles.loggedInRightSideWrapper}>
          <DropdownButton
            className={styles.searchButton}
            onClick={props.handleShowSearchPopUp}
          >
            <FontAwesomeIcon icon={faSearch} />
          </DropdownButton>
          <DropdownButton
            alerts={notifications.filter((n) => n.viewed === false).length}
            onClick={() => {
              setShowNotifications(true);
            }}
          >
            <FontAwesomeIcon icon={faBell} />
          </DropdownButton>
          <DropdownButton
            alerts={unreadMessageThreads}
            onClick={() => {
              setShowMessages(true);
            }}
          >
            <FontAwesomeIcon icon={faCommentDots} />
          </DropdownButton>
          <DropdownButton
            onClick={() => {
              navigate("/calendar");
            }}
          >
            <FontAwesomeIcon icon={faCalendarDays} />
          </DropdownButton>
          <ProfileImage
            imgUrl={userData?.imageURL}
            size="xxs"
            href={`/u/${userData?.username}`}
          />
          <DropdownButton
            onClick={() => {
              setShowDropdown(true);
            }}
          >
            <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
          </DropdownButton>
        </div>
      ) : (
        <div className={styles.signedOutRightWrapper}>
          <DropdownButton
            className={styles.searchButton}
            onClick={props.handleShowSearchPopUp}
          >
            <FontAwesomeIcon icon={faSearch} />
          </DropdownButton>
          <Button
            round
            onClick={props.handleLogin}
            style={{ marginRight: "10px" }}
          >
            Log In
          </Button>
          <Button round onClick={props.handleSignUp} color="gray">
            Sign Up
          </Button>
        </div>
      )}
    </header>
  );
};

export default Header;
