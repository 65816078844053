import { MouseEvent } from "react";
import styles from "./toggleStyles.module.css";

type Props = {
  id: string;
  checked: boolean;
  onChange: () => void;
  label?: string;
  disabled?: boolean;
};

const Toggle = (props: Props) => {
  return (
    <div className={styles.wrapper}>
      <input
        type="checkbox"
        className={styles.hidden}
        checked={props.checked}
        readOnly
        id={props.id}
      />
      {props.label ? (
        <span style={{ marginRight: "20px" }}>{props.label}</span>
      ) : null}
      <label
        className={[
          styles.pseudoBox,
          props.checked ? styles.checkedBox : "",
        ].join(" ")}
        htmlFor={props.id}
        onClick={(e: MouseEvent<HTMLLabelElement>) => {
          e.preventDefault();
          e.stopPropagation();
          if (!props.disabled) props.onChange();
        }}
      >
        <button
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (!props.disabled) props.onChange();
          }}
          className={[
            styles.innerCircle,
            props.checked ? styles.checked : styles.unchecked,
            props.disabled && styles.disabled,
          ].join(" ")}
        ></button>
      </label>
    </div>
  );
};

export default Toggle;
