import { useState, useEffect } from "react";
import { loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { httpsCallable } from "firebase/functions";
import { functions } from "../services/firebase/firebase";

export const useStripeConnect = (connectedAccountId: string | undefined) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance>();

  useEffect(() => {
    if (connectedAccountId) {
      type GetSessionRequest = {
        account: string; 
      };
      type GetSessionResponse = {
        client_secret: string;
        error?: string;
      };
      const getAccountSession = httpsCallable<GetSessionRequest, GetSessionResponse>(functions, "connectSession");
      const fetchClientSecret = async () => {
        const result = await getAccountSession({ account: connectedAccountId });
        return result.data.client_secret;
      };
      /*
      const fetchClientSecret = async () => {
        const response = await fetch("/account_session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            account: connectedAccountId,
          }),
        });

        if (!response.ok) {
          // Handle errors on the client side here
          const { error } = await response.json();
          throw ("An error occurred: ", error);
        } else {
          const { client_secret: clientSecret } = await response.json();
          return clientSecret;
        }
      };*/

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        })
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};

export default useStripeConnect;
