import Input from "../Input/Input";
import styles from "./moneyInputStyles.module.css";

type MoneyInputProps = {
  value: string;
  onChange: (v: string) => void;
};

const MoneyInput = (props: MoneyInputProps) => {
  const handleInput = (val: string) => {
    const isMoney = /^\d{0,9}(?:\.\d{0,2})?$/.test(val);
    if (isMoney) props.onChange(val);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.moneySign}>$</div>
      <Input
        value={props.value}
        onChange={handleInput}
        className={styles.input}
        placeholder="0.00"
      ></Input>
    </div>
  );
};

export default MoneyInput;
