import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "../../services/firebase/firebase";
import styles from "./profileImageStyles.module.css";
import PropTypes from "prop-types";
import { fetchImageUrl } from "../../utils";
import Anchor from "../Anchor/Anchor";
import { Link, useNavigate } from "react-router-dom";

type ProfileImageProps = {
  imgUrl?: string;
  imgLocation?: string | undefined;
  imgFile?: File | undefined;
  size?: string;
  style?: React.CSSProperties;
  href?: string;
};

const ProfileImage = (props: ProfileImageProps) => {
  const [imgUrl, setImgUrl] = useState<string>();
  const [imgFileUrl, setImgFileUrl] = useState<string>();
  const [loadingUrl, setLoadingUrl] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.imgUrl) {
      setImgUrl(props.imgUrl);
      setLoadingUrl(false);
    }
  }, [props.imgUrl]);

  useEffect(() => {
    if (props.imgLocation) {
      fetchImageUrl(props.imgLocation).then((url) => {
        setImgUrl(url);
        setLoadingUrl(false);
      });
    }
  }, [props.imgLocation]);

  useEffect(() => {
    if (props.imgFile) {
      setImgFileUrl(URL.createObjectURL(props.imgFile));
      setLoadingUrl(false);
    }
  }, [props.imgFile]);

  if (loadingUrl) {
    return (
      <div
        className={[
          styles.placeholder,
          props.size ? styles[props.size] : null,
        ].join(" ")}
        style={props.style}
      ></div>
    );
  }

  const Pic = () => (
    <img
      className={[styles.image, props.size ? styles[props.size] : null].join(
        " "
      )}
      style={props.style}
      src={imgFileUrl || imgUrl}
      alt="Profile Photo"
    />
  );

  if (props.href) {
    return (
      <Link to={props.href}>
        <Pic />
      </Link>
    );
  }

  return <Pic />;
};

ProfileImage.propTypes = {
  size: PropTypes.oneOf(["xxxs", "xxs", "xs", "s", "m", "l", "xl"]),
};

export default ProfileImage;
