import styles from "./landingPageAnimation2Styles.module.css";

const image1: string = require("../../assets/tat.jpg");
const image2: string = require("../../assets/tat2.jpg");
const image3: string = require("../../assets/tat3.jpg");
const image4: string = require("../../assets/tat4.jpg");
const image5: string = require("../../assets/tat5.jpg");
const image6: string = require("../../assets/tat6.jpg");
const image7: string = require("../../assets/tat7.jpg");
const image8: string = require("../../assets/tat8.jpg");
const image9: string = require("../../assets/tat9.jpg");
const image10: string = require("../../assets/tat10.jpg");
const image11: string = require("../../assets/tat11.jpg");
const image12: string = require("../../assets/tat12.jpg");
const image13: string = require("../../assets/tat13.jpg");
const image14: string = require("../../assets/tat14.jpg");
const image15: string = require("../../assets/tat15.jpg");
const image16: string = require("../../assets/tat16.jpg");
const image17: string = require("../../assets/tat17.jpg");
const image18: string = require("../../assets/tat18.jpg");
const image19: string = require("../../assets/tat19.jpg");
const image20: string = require("../../assets/tat20.jpg");
const image21: string = require("../../assets/tat21.jpg");

type PicProps = {
  source: string;
  className?: string;
};

const Pic = (props: PicProps) => {
  return (
    <img
      className={[styles.box, props.className].join(" ")}
      src={props.source}
    />
  );
};

const LandingPageAnimation2 = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.overlay} />
      <div className={styles.rowWrapper}>
        <Pic source={image15} className={styles.box3} />
        <Pic source={image16} className={styles.box3} />
        <div className={[styles.emptyBox, styles.box].join(" ")}></div>
        <div className={[styles.emptyBox, styles.box].join(" ")}></div>
        <div className={[styles.emptyBox, styles.box].join(" ")}></div>
        <Pic source={image12} className={styles.box3} />
        <Pic source={image18} className={styles.box3} />
      </div>
      <div className={styles.rowWrapper}>
        {[image8, image9, image4, image11, image6, image13, image14].map(
          (x) => {
            return <Pic source={x} className={styles.box2} />;
          }
        )}
      </div>
      <div className={styles.rowWrapper}>
        {[image1, image2, image3, image10, image5, image17, image7].map((x) => {
          return <Pic source={x} />;
        })}
      </div>
    </div>
  );
};

export default LandingPageAnimation2;
