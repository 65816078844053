import { Fragment, useEffect, useRef, useState } from "react";
import FormLabel from "../FormLabel/FormLabel";
import Input from "../Input/Input";
import styles from "./searchBarStyles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  AutoCompleteSuggestion,
  PlaceDetails,
  StudioLocation,
} from "../../types";
import { fetchAutocompleteSuggestions, fetchPlaceDetails } from "../../utils";
import useClickOutside from "../../hooks/useClickOutside";

type SearchBarProps = {
  onSelect: (place: StudioLocation | undefined) => void;
  types?: string[];
  placeholder?: string;
  label?: string;
  className?: string;
};

const SearchBar = (props: SearchBarProps) => {
  const [suggestions, setSuggestions] = useState<AutoCompleteSuggestion[]>();
  const [location, setLocation] = useState<AutoCompleteSuggestion>();
  const [placeDetails, setPlaceDetails] = useState<PlaceDetails>();
  const [value, setValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(wrapperRef, () => {
    setSuggestions(undefined);
  });

  const getSuggestions = (v: string) => {
    fetchAutocompleteSuggestions(v, props.types).then((results) => {
      setSuggestions(results);
    });
  };

  const handleInput = (v: string) => {
    setValue(v);
    if (v) {
      getSuggestions(v);
    }
  };

  const handleSelect = (s: AutoCompleteSuggestion) => {
    setSuggestions(undefined);
    setValue(
      `${s.placePrediction.structuredFormat.mainText.text} ${s.placePrediction.structuredFormat.secondaryText?.text}`
    );
    setLocation(s);
    fetchPlaceDetails(s.placePrediction.placeId).then((d) => {
      setPlaceDetails(d);
    });
  };

  useEffect(() => {
    //props.onSelect(placeDetails);
    if (placeDetails) {
      const localityComponent = placeDetails.addressComponents.find((c) =>
        c.types.includes("locality")
      );
      const postalCodeComponent = placeDetails.addressComponents.find((c) =>
        c.types.includes("postal_code")
      );
      const postalCodeSuffixComponent = placeDetails.addressComponents.find(
        (c) => c.types.includes("postal_code_suffix")
      );
      const administrativeAreaLevel1Component =
        placeDetails.addressComponents.find((c) =>
          c.types.includes("administrative_area_level_1")
        );
      const countryComponent = placeDetails.addressComponents.find((c) =>
        c.types.includes("country")
      );
      const structuredData = {
        location: placeDetails?.location,
        text: {
          mainText: location?.placePrediction.structuredFormat.mainText.text,
          secondaryText:
            location?.placePrediction.structuredFormat.secondaryText?.text,
        },
        googleId: placeDetails.id,
        types: placeDetails.types,
        formattedAddress: placeDetails.formattedAddress,
        shortFormattedAddress: placeDetails.shortFormattedAddress,
        addressComponents: placeDetails.addressComponents,
        utcOffsetMinutes: placeDetails.utcOffsetMinutes,
        viewport: {
          low: placeDetails.viewport.low,
          high: placeDetails.viewport.high,
        },
        googleMapsUri: placeDetails.googleMapsUri,
        ...(localityComponent?.longText && {
          locality: localityComponent.longText,
        }),
        ...(postalCodeComponent && {
          postalCode: parseInt(postalCodeComponent.longText),
        }),
        ...(postalCodeSuffixComponent && {
          postalCodeSuffix: parseInt(postalCodeSuffixComponent.longText),
        }),
        ...(administrativeAreaLevel1Component && {
          administrativeAreaLevel1: administrativeAreaLevel1Component,
        }),
        ...(countryComponent && { country: countryComponent }),
      } as StudioLocation;
      props.onSelect(structuredData);
    }
  }, [placeDetails]);

  return (
    <div
      className={[styles.wrapper, props.className].join(" ")}
      ref={wrapperRef}
    >
      {props.label ? (
        <FormLabel htmlFor="location">{props.label}</FormLabel>
      ) : null}
      <div className={styles.inputWrapper}>
        <div
          className={[
            styles.placeholder,
            inputFocused || value ? styles.hidden : null,
          ].join(" ")}
        >
          <FontAwesomeIcon icon={faSearch} style={{ marginRight: "10px" }} />{" "}
          Search By Location
        </div>
        <Input
          autoComplete="off"
          id="location"
          value={value}
          onChange={handleInput}
          error={false}
          readonly={typeof location !== "undefined"}
          placeholder={inputFocused ? "Search By Location" : ""}
          className={styles.input}
          onFocus={() => {
            setInputFocused(true);
            if (value) {
              getSuggestions(value);
            }
          }}
          onBlur={() => {
            setInputFocused(false);
          }}
        />
        <button
          className={[styles.xWrapper, !value ? styles.noDisplay : null].join(
            " "
          )}
          onClick={() => {
            setValue("");
            props.onSelect(undefined);
            setLocation(undefined);
            setSuggestions(undefined);
          }}
        >
          <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
        </button>
      </div>
      {suggestions && (
        <ul className={styles.suggestionList}>
          {suggestions.map((s) => (
            <li
              key={s.placePrediction.placeId}
              onClick={() => {
                handleSelect(s);
              }}
              className={styles.suggestion}
            >
              <strong>
                {s.placePrediction.structuredFormat.mainText.text}
              </strong>{" "}
              <small>
                {s.placePrediction.structuredFormat.secondaryText?.text}
              </small>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
