import { Fragment } from "react/jsx-runtime";
import styles from "./booksOpenOrClosedStyles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCalendarXmark,
} from "@fortawesome/free-solid-svg-icons";

type BooksOpenOrClosedProps = {
  open: boolean;
  className?: string;
};

const BooksOpenOrClosed = (props: BooksOpenOrClosedProps) => {
  return (
    <div className={["flexRowCenter", props.className].join(" ")}>
      {props.open ? (
        <Fragment>
          <FontAwesomeIcon
            icon={faCalendarCheck}
            className={["mr5", styles.booksOpenIcon].join(" ")}
          />
          <h5>BOOKS OPEN</h5>
        </Fragment>
      ) : (
        <Fragment>
          <FontAwesomeIcon
            icon={faCalendarXmark}
            className={["mr5", styles.booksClosedIcon].join(" ")}
          />
          <h5>BOOKS CLOSED</h5>
        </Fragment>
      )}
    </div>
  );
};

export default BooksOpenOrClosed;
