import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback } from "react";
import Layout from "../../components/Layout/Layout";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../services/firebase/firebase";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

export const Checkout = () => {
  const getClientSecret = httpsCallable(functions, "createCheckoutSession");
  const fetchClientSecret = useCallback(() => {
    return getClientSecret().then((result) => {
      const data = result.data as { clientSecret: string };

      return data.clientSecret;
    });
  }, []);

  const options = { fetchClientSecret };

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};

const CheckoutPage = () => {
  return (
    <Layout>
      <div className="m20">
        <Checkout />
      </div>
    </Layout>
  );
};

export default CheckoutPage;
