import styles from "./skeletonStyles.module.css";

type SkeletonProps = {
  className?: string;
  height?: number;
};

const Skeleton = (props: SkeletonProps) => {
  return (
    <div
      className={[
        styles.box,
        props.height && styles[`height${props.height}`],
        props.className,
      ].join(" ")}
    ></div>
  );
};

export const SkeletonPage = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton className={[styles.header, "mb10"].join(" ")}></Skeleton>
      <Skeleton className="mb10" />
      <Skeleton className="mb10" />
      <div className={styles.flexWrapper}>
        <Skeleton
          className={[styles.halfWidth, "mr10", "mb10"].join(" ")}
          height={2}
        />
        <Skeleton className={styles.halfWidth} height={2} />
      </div>
      <div className={styles.flexWrapper}>
        <Skeleton className={[styles.halfWidth, "mr10"].join(" ")} height={6} />
        <Skeleton className={styles.halfWidth} height={6} />
      </div>
    </div>
  );
};

export default Skeleton;
