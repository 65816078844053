import { useEffect, useState } from "react";
import { FavoriteArtist, TMUser } from "../../types";
import { useNavigate } from "react-router-dom";
import { fetchImageUrl, fetchUser } from "../../utils";
import Spinner from "../Spinner/Spinner";
import ProfileImage from "../ProfileImage/ProfileImage";
import Anchor from "../Anchor/Anchor";
import styles from "./favoriteArtistPreviewStyles.module.css";

type FavoriteArtistPreviewProps = {
  data: FavoriteArtist;
};

const FavoriteArtistPreview = (props: FavoriteArtistPreviewProps) => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<TMUser>();
  const [imgUrl, setImgUrl] = useState<string>();
  const navigate = useNavigate();

  const loadData = async () => {
    setLoading(true);
    const results = await Promise.all([
      fetchUser(props.data.docId),
      fetchImageUrl(props.data.imgLocation),
    ]);
    const userResult = results[0];
    const urlResult = results[1];
    setUserData(userResult);
    setImgUrl(urlResult);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.wrapper}>
      <ProfileImage
        imgUrl={imgUrl}
        size="xs"
        href={`/u/${userData?.username}`}
        style={{ marginRight: "10px" }}
      />
      <div>
        <h4>
          {userData?.firstName} {userData?.lastName}
        </h4>
        <Anchor
          onClick={() => {
            if (userData) navigate(`/u/${userData.username}`);
          }}
        >
          @{userData?.username || ""}
        </Anchor>
      </div>
    </div>
  );
};

export default FavoriteArtistPreview;
