import { QueryDocumentSnapshot, SnapshotOptions, WithFieldValue, collection, collectionGroup, deleteDoc, doc, getDoc, getDocs, limit, limitToLast, orderBy, query, serverTimestamp, setDoc, startAfter, startAt, where } from "firebase/firestore";
import { db, storage } from "./services/firebase/firebase";
import { Appointment, FavoriteArtist, FlashPost, LikedPosts, Message, MessageThread, Opening, StudioLocation, TMNotification, TMUser, TMUserWithImageURL, TattooPost, UserSettings } from "./types";
import { getDownloadURL, ref } from "firebase/storage";

function deg2rad(deg: number) {
  return deg * (Math.PI/180)
}

export const getDistanceFromLatLngInKm = (lat1: number, lng1: number, lat2: number, lng2: number) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLng = deg2rad(lng2-lng1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLng/2) * Math.sin(dLng/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

export const converter = <T>() => ({
    toFirestore(data: WithFieldValue<T>){return data},
    fromFirestore (snap: QueryDocumentSnapshot, options: SnapshotOptions) {
      const d = snap.data(options);
      return {...d, docId: snap.id, createdAt: d.createdAt ? d.createdAt.toDate() : null} as T},
  });


export const openingConverter = <T>() => ({
    toFirestore(data: WithFieldValue<T>){return data},
    fromFirestore (snap: QueryDocumentSnapshot, options: SnapshotOptions) {
      const d = snap.data(options);
      const artistId = snap.ref.parent.parent!.id;
      return {...d, date: d.date.toDate(), docId: snap.id, artistId} as T},
  });

  export const appointmentConverter = <T>() => ({
    toFirestore(data: WithFieldValue<T>){return data},
    fromFirestore (snap: QueryDocumentSnapshot, options: SnapshotOptions) {
      const d = snap.data(options);
      return {...d, date: d.date.toDate(), docId: snap.id} as T},
  })

  export const messageConverter = <T>() => ({
    toFirestore(data: WithFieldValue<T>){return data},
    fromFirestore (snap: QueryDocumentSnapshot, options: SnapshotOptions) {
      const d = snap.data(options);
      return {...d, docId: snap.id, createdAt: d.createdAt.toDate()} as T},
  })

  export const messageThreadConverter = <T>() => ({
    toFirestore(data: WithFieldValue<T>){return data},
    fromFirestore (snap: QueryDocumentSnapshot, options: SnapshotOptions) {
      const d = snap.data(options);
      return {...d, docId: snap.id, updatedAt: d.updatedAt.toDate()} as T},
  })

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  export const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]

  export const datesAreSameDay = (a: Date, b: Date) => {
    if (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    ) {
      return true;
    } else {
      return false;
    }
  };

  export const fetchUser = async (uid: string) => {
    const docRef = doc(db, "users", uid).withConverter(converter<TMUser>());
    const docSnap = await getDoc(docRef);
    const d: TMUser | undefined = docSnap.data();
    if (d) {
      return d;
    } else {
      return Promise.reject(new Error("No Data Returned"));
    }
  };

  export const searchUsersByUsername = async (username: string) => {
    const q = query(
      collection(db, "users"),
      where('username', '>=', username),
      where('username', '<=', username+ '\uf8ff'), 
      limit(10)
    ).withConverter(converter<TMUser>());
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((doc) => doc.data());
    if(results.length > 0) {
      return results;
    } else {
      return Promise.reject(new Error("No Users Found"))
    }
  }

  export const fetchUserByUsername = async (username: string) => {
    const q = query(
      collection(db, "users"),
      where('username', '==', username.toLowerCase()),
      limit(10)
    ).withConverter(converter<TMUser>());
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((doc) => doc.data());
    if(results.length === 1) {
      return results[0];
    } else if (results.length > 1) {
      return Promise.reject(new Error("Multiple Users Found"))
    } else {
      return Promise.reject(new Error("No User Found"))
    }

  }

  export const fetchOpening = async (uid: string, openingId: string) => {
    const docRef = doc(db, 'users', uid, 'openings', openingId).withConverter(openingConverter<Opening>());
    const docSnap = await getDoc(docRef);
    const d: Opening | undefined = docSnap.data();
    if (d) {
      return d;
    } else {
      return Promise.reject(new Error("No Data Returned"));
    }
  }

  export const fetchFlashPost = async (flashId: string, artistId: string) => {
    const docRef = doc(db, 'users', artistId, 'flash', flashId).withConverter(converter<FlashPost>());
    const docSnap = await getDoc(docRef);
    const d: FlashPost | undefined = docSnap.data();
    if (d) {
      return d;
    } else {
      return Promise.reject(new Error("No Data Returned"));
    }
  }

  export const fetchTattooPost = async (id: string, artistId: string) => {
    const docRef = doc(db, 'users', artistId, 'tattoos', id).withConverter(converter<TattooPost>());
    const docSnap = await getDoc(docRef);
    const d: TattooPost | undefined = docSnap.data();
    if (d) {
      return d;
    } else {
      return Promise.reject(new Error("No Data Returned"));
    }
  }

  export const fetchImageUrl = async (storageLocation: string) => {
      const url = await getDownloadURL(ref(storage, `${storageLocation}`))
      return url;
  };

  export const fetchUserWithImageUrl = async (uid: string) => {
      const docRef = doc(db, "users", uid).withConverter(converter<TMUser>());
      const userDoc = await getDoc(docRef);
      const userDocData = userDoc.data();
      if (userDocData) {
        const url = await getDownloadURL(ref(storage, userDocData.imgLocation));
        return {
          ...userDocData,
          imageURL: url,
        } as TMUserWithImageURL;
      }
  };

  export const fetchSearchLocation = async (uid: string, searchId: string) => {
    const docRef = doc(
      db,
      "users",
      uid,
      "studioLocations",
      searchId
    ).withConverter(converter<StudioLocation>());
    const docSnapshot = await getDoc(docRef);
    return docSnapshot.data();
  };
    

  export const fetchFlashPosts = async (limitTo?: number) => {
    const collectionRef = collection(db, 'flash')
    const q = query(
      collectionRef,
      limit(limitTo || 100),
    ).withConverter(converter<FlashPost>());
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  };

  

  export const fetchOpenings = async(artistId: string) => {
    const d = new Date();
    d.setHours(0,0,0,0);
    const q = query(
      collection(db, 'users', artistId, "openings"), where('status', '==', 'available'), orderBy('date'), startAt(d)
    ).withConverter(openingConverter<Opening>());
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  export const fetchNotifications = async (uid: string) => {
    const q = query(
      collection(db, 'users', uid, "notifications"),
      orderBy('createdAt', 'desc'),
      limitToLast(30)
    ).withConverter(converter<TMNotification>());
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  export const fetchAppointments = async (artistId: string) => {
    const d = new Date();
    d.setHours(0,0,0,0);
    const q = query(
      collection(db, "appointments"),
      where("artistId", "==", artistId), 
      orderBy('date'), 
      startAt(d)
    ).withConverter(appointmentConverter<Appointment>());
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  export const fetchClientAppointments = async (clientId: string) => {
    const d = new Date();
    d.setHours(0,0,0,0);
    const q = query(
      collection(db, "appointments"),
      where("clientId", "==", clientId), 
      orderBy('date'), 
      startAt(d)
    ).withConverter(appointmentConverter<Appointment>());
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  export const fetchUserSettings = async (uid: string) => {
    const docRef = doc(db, 'userSettings', uid).withConverter(converter<UserSettings>());
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    return data;
  }

  export const fetchMessageThread = async (currentUid: string, otherUid: string) => {
    const docRef = doc(db, 'users', currentUid, 'messageThreads', otherUid).withConverter(messageThreadConverter<MessageThread>());
    const querySnapshot = await getDoc(docRef);
    const data = querySnapshot.data()
    return data;
  }

  export const fetchMessageThreads = async (uid: string) => {
    const threadsRef = collection(db, 'users', uid, 'messageThreads').withConverter(messageThreadConverter<MessageThread>());
    const querySnapshot = await getDocs(threadsRef);
    const data = querySnapshot.docs.map((doc) => doc.data());
    return data;
  }

  export const fetchMessages = async (currentUserId: string, otherUserId: string) => {
    const sentMessagesRef = collection(db, 'users', currentUserId, 'messageThreads', otherUserId, 'messages');
    const receivedMessagesRef = collection(db, 'users', otherUserId, 'messageThreads', currentUserId, 'messages');
    const q = query(
      sentMessagesRef,orderBy('createdAt', 'desc'),
      limitToLast(100)
    ).withConverter(messageConverter<Message>());
    const q2 = query(
      receivedMessagesRef,orderBy('createdAt', 'desc'),
      limitToLast(100)
    ).withConverter(messageConverter<Message>());
    const querySnapshots = await Promise.all([getDocs(q), getDocs(q2)])
    const allMessages = [...querySnapshots[0].docs.map(doc => doc.data()), ...querySnapshots[1].docs.map(doc => doc.data())]
    allMessages.sort((a,b) => +a.createdAt - +b.createdAt)
    return allMessages;
  }

  export const fetchAppointment = async (id: string) => {
    const docRef = doc(db, 'appointments', id).withConverter(appointmentConverter<Appointment>());
    const docSnap = await getDoc(docRef);
    const d: Appointment | undefined = docSnap.data();
    if (d) {
      return d;
    } else {
      return Promise.reject(new Error("No Data Returned"));
    }
  }

  export const addToLikedPosts = async (uid: string, flashId: string, artistId: string, type: string) => {
    const docRef = doc(db, 'users',uid,'likedPosts', flashId);
    await setDoc(docRef, { artistId, createdAt: serverTimestamp(), type });
  }

  export const removeFromLikedPosts = async (uid:string, flashId: string) => {
    const docRef = (doc(db, 'users', uid, 'likedPosts', flashId));
    await deleteDoc(docRef);
  }

  export const addToFavoriteArtists = async (uid: string, artistId: string, artistImgLocation: string) => {
    const docRef = (doc(db, 'users',uid, 'favoriteArtists', artistId));
    await setDoc(docRef, {imgLocation: artistImgLocation, createdAt: serverTimestamp()});
  }

  export const removeFromFavoriteArtists = async (uid: string, artistId: string) => {
    const docRef = (doc(db, 'users', uid, 'favoriteArtists', artistId));
    await deleteDoc(docRef);
  }

  export const fetchFavoriteArtists = async (uid: string) => {
    const collectionRef = collection(db, 'users', uid, 'favoriteArtists');
    const q = query(collectionRef, orderBy('createdAt'), limit(10)).withConverter(converter<FavoriteArtist>());
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data())
  }

  export const fetchLikedPosts = async (uid: string) => {
    const collectionRef = collection(db, 'users', uid, 'likedPosts');
    const q = query(collectionRef, orderBy('createdAt', 'desc'), limit(10)).withConverter(converter<LikedPosts>());
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data())
  }

  export const postIsLiked = async (uid: string, flashId: string) => {
    const docRef = doc(db, 'users', uid, 'likedPosts', flashId);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  }

  export const artistIsFavorite = async (uid: string, artistId: string) => {
    const docRef = doc(db, 'users', uid, 'favoriteArtists', artistId);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  }

  export const sortDatesByDate = (data: Date[]) => {
    return data.sort((a: Date, b: Date) => {
      const aDate: number = parseInt(
        `${a.getFullYear()}${a.getMonth()}${a.getDate()}`
      );
      const bDate: number = parseInt(
        `${b.getFullYear()}${b.getMonth()}${b.getDate()}`
      );
      return aDate - bDate;
    });
  }

  export const sortByDate = (data: Opening[] | Appointment[]) => {
    return data.sort((a: Opening, b: Opening) => {
      const aDate: number = parseInt(
        `${a.date.getFullYear()}${a.date.getMonth().toString().padStart(2,'0')}${a.date.getDate().toString().padStart(2,'0')}`
      );
      const bDate: number = parseInt(
        `${b.date.getFullYear()}${b.date.getMonth().toString().padStart(2,'0')}${b.date.getDate().toString().padStart(2,'0')}`
      );

      return aDate - bDate;
    });
  };

  export const formattedDayName = (d: Date) => {
    const today = new Date();
    if (datesAreSameDay(d, today)) {
      return "Today";
    } else {
      return days[d.getDay()].substring(0, 3);
    }
  };

  export const formattedDateString = (d: Date) => {
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  };

  // Google Places API Functions

  export const fetchAutocompleteSuggestions = async (
    input: string,
    types?: string[]
  ) => {
    const response = await fetch(
      "https://places.googleapis.com/v1/places:autocomplete",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY!,
        },
        body: JSON.stringify(
          types
            ? { input, includedPrimaryTypes: types }
            : {
                input,
              }
        ),
      }
    );
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }
    const json = await response.json();
    return json.suggestions;
  };
  
  export const fetchPlaceDetails = async (id: string) => {
    const response = await fetch(
      `https://places.googleapis.com/v1/places/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY!,
          "X-Goog-FieldMask":
            "name,id,types,formattedAddress,addressComponents,location,viewport,googleMapsUri,utcOffsetMinutes,displayName,shortFormattedAddress",
        },
      }
    );
    const json = await response.json();
    return json;
  };

  export const fetchNearbyPlaces = async (lat: number, lng: number) => {
    const response = await fetch(
      "https://places.googleapis.com/v1/places:searchNearby",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY!,
          "X-Goog-FieldMask":
            "name,id,types,formattedAddress,addressComponents,location,viewport,googleMapsUri,utcOffsetMinutes,displayName,shortFormattedAddress",
        },
        body: JSON.stringify({
          includedTypes: ["locality"],
          maxResultCount: 10,
          locationRestriction: {
            circle: {
              center: {
                latitude: lat,
                longitude: lng,
              },
              radius: 50,
            },
          },
        }),
      }
    );
    const json = await response.json();
    return json;
  };