import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./heartIconStyles.module.css";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";

type HeartIconProps = {
  liked?: boolean;
};

const HeartIcon = (props: HeartIconProps) => {
  if (props.liked) {
    return <FontAwesomeIcon icon={faSolidHeart} className={styles.liked} />;
  } else {
    return <FontAwesomeIcon icon={faHeart} />;
  }
};

export default HeartIcon;
