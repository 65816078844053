import React, { useEffect, useState } from "react";
import { months } from "../../utils";
import Button from "../Button/Button";
import styles from "./calendarStyles.module.css";
import { Appointment, Opening } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";

type DateSquareProps = {
  children?: React.ReactNode;
  empty?: boolean;
  selected?: boolean;
  date: number;
  onClick?: (v: number) => void;
  openings: Opening[];
  requests: number;
  appointments: number;
  past?: boolean;
};

const DateSquare = (props: DateSquareProps) => {
  return (
    <button
      className={[
        styles.dateSquare,
        props.selected ? styles.selected : null,
        props.date === 0 ? styles.emptySquare : null,
        props.past ? styles.past : null,
      ].join(" ")}
      onClick={() => {
        if (props.onClick && props.date > 0 && !props.past) {
          props.onClick(props.date);
        }
      }}
    >
      {props.date === 0 ? null : (
        <div className={styles.dateLabel}>
          {props.date}
          {(props.appointments > 0 ||
            props.requests > 0 ||
            props.openings.length > 0) && (
            <div className={styles.mobileBubbleWrapper}>
              <div className={styles.mobileBubble} />
            </div>
          )}
        </div>
      )}
      <div className={styles.bubblesWrapper}>
        {props.appointments > 0 ? (
          <div className={[styles.appointmentsBubble, styles.bubble].join(" ")}>
            {props.appointments} Appointment
            {props.appointments > 1 ? "s" : null}
          </div>
        ) : null}
        {props.requests > 0 ? (
          <div className={[styles.requestsBubble, styles.bubble].join(" ")}>
            {props.requests} Request
            {props.requests > 1 ? "s" : null}
          </div>
        ) : null}
        {props.openings.length > 0 ? (
          <div className={[styles.openingsBubble, styles.bubble].join(" ")}>
            {props.openings.length} Opening
            {props.openings.length > 1 ? "s" : null}
          </div>
        ) : null}
      </div>
    </button>
  );
};

type CalRowProps = {
  children?: React.ReactNode;
};

const CalRow = (props: CalRowProps) => {
  return <div className={styles.rowWrapper}>{props.children}</div>;
};

const generateCal = (d: Date) => {
  const month = d.getMonth();
  const year = d.getFullYear();
  const firstOfMonth = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const squares = daysInMonth + firstOfMonth;
  const trailingDays = squares % 7;
  const numRows =
    trailingDays > 0 ? Math.floor(squares / 7) + 1 : Math.floor(squares / 7);
  const rows: number[][] = [];
  let dayCount = 1;
  for (let i = 0; i < numRows; i++) {
    let thisRow: number[] = [];
    if (i === 0) {
      for (let x = 0; x < firstOfMonth; x++) {
        thisRow.push(0);
      }
      for (let y = 0; y < 7 - firstOfMonth; y++) {
        thisRow.push(dayCount);
        dayCount++;
      }
    } else if (i !== numRows - 1) {
      for (let z = 0; z < 7; z++) {
        thisRow.push(dayCount);
        dayCount++;
      }
    } else {
      if (trailingDays === 0) {
        for (let a = 0; a < 7; a++) {
          thisRow.push(dayCount);
          dayCount++;
        }
      } else {
        for (let a = 0; a < trailingDays; a++) {
          thisRow.push(dayCount);
          dayCount++;
        }
        for (let b = 0; b < 7 - trailingDays; b++) {
          thisRow.push(0);
        }
      }
    }
    rows.push(thisRow);
  }
  return rows;
};

type CalendarProps = {
  selectedDates: Date[];
  openings: Opening[];
  appointments?: Appointment[];
  requests?: Appointment[];
  onChange: (n: Date[]) => void;
  onChangeMonth: (m: number, y: number) => void;
  currentMonth?: number;
  currentYear?: number;
  small?: boolean;
};

const isPast = (year: number, month: number, day: number) => {
  const today = new Date();
  if (year < today.getFullYear()) return true;
  if (year > today.getFullYear()) return false;
  if (month < today.getMonth()) return true;
  if (month > today.getMonth()) return false;
  if (day < today.getDate()) return true;
  if (day > today.getDate()) return false;
  return false;
};

const Calendar = (props: CalendarProps) => {
  const today = new Date();
  const { currentMonth, currentYear } = props;
  const [month, setMonth] = useState(currentMonth || today.getMonth());
  const [year, setYear] = useState(currentYear || today.getFullYear());

  const [cal, setCal] = useState(generateCal(today));

  useEffect(() => {
    if (month && year) {
      setCal(generateCal(new Date(year, month, 1)));
      props.onChangeMonth(month, year);
    }
  }, [month, year]);

  useEffect(() => {
    if (currentMonth) setMonth(currentMonth);
  }, [currentMonth]);

  useEffect(() => {
    if (currentYear) setYear(currentYear);
  }, [currentYear]);

  return (
    <div className={[styles.calWrapper, props.small && styles.small].join(" ")}>
      <div className={styles.dayHeader}>
        {["S", "M", "T", "W", "T", "F", "S"].map((letter, i) => (
          <div key={`${letter}${i}`} className={styles.dayHeaderElement}>
            {letter}
          </div>
        ))}
      </div>
      {cal.map((row, i) => {
        return (
          <CalRow key={i}>
            {row!.map((day, j) => {
              return (
                <DateSquare
                  key={j}
                  date={day}
                  past={day === 0 || isPast(year, month, day)}
                  openings={props.openings.filter(
                    (o) =>
                      o.date.getDate() === day &&
                      o.date.getMonth() === month &&
                      o.date.getFullYear() === year
                  )}
                  requests={
                    props.requests?.filter(
                      (a) =>
                        a.date.getDate() === day &&
                        a.date.getMonth() === month &&
                        a.date.getFullYear() === year
                    ).length || 0
                  }
                  appointments={
                    props.appointments?.filter(
                      (a) =>
                        a.date.getDate() === day &&
                        a.date.getMonth() === month &&
                        a.date.getFullYear() === year
                    ).length || 0
                  }
                  selected={
                    typeof props.selectedDates.find(
                      (d) =>
                        d.getDate() === day &&
                        d.getMonth() === month &&
                        d.getFullYear() === year
                    ) !== "undefined"
                  }
                  onClick={(v) => {
                    const datestring = `${month.toString().padStart(2, "0")}${v
                      .toString()
                      .padStart(2, "0")}${year}`;
                    const sDates = props.selectedDates.map(
                      (d) =>
                        `${d.getMonth().toString().padStart(2, "0")}${d
                          .getDate()
                          .toString()
                          .padStart(2, "0")}${d.getFullYear()}`
                    );
                    const i = sDates.indexOf(datestring);
                    if (i === -1) {
                      props.onChange([
                        ...props.selectedDates,
                        new Date(year, month, v),
                      ]);
                    } else {
                      props.onChange(
                        [...props.selectedDates].filter(
                          (d) =>
                            d.getDate() !== v ||
                            d.getMonth() !== month ||
                            d.getFullYear() !== year
                        )
                      );
                    }
                  }}
                ></DateSquare>
              );
            })}
          </CalRow>
        );
      })}
    </div>
  );
};

export default Calendar;
