import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button/Button";
import FormLabel from "../FormLabel/FormLabel";
import TimePicker, { Time } from "../TimePicker/TimePicker";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useContext, useEffect, useState } from "react";
import styles from "./createNewOpeningStyles.module.css";
import { converter, months } from "../../utils";
import { writeBatch, doc, collection, getDocs } from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import { UserContext } from "../../App";
import NewLocationInput from "../NewLocationInput/NewLocationInput";
import { StudioLocation, TMUser } from "../../types";
import SelectionBubble from "../SelectionBubble/SelectionBubble";
import LocationRadio from "../LocationRadio/LocationRadio";

const timeIsValid = (t: Time) => {
  if (t.hour === "hh") return false;
  if (t.minute === "mm") return false;
  if (t.ampm === "aa") return false;
  return true;
};

type RowProps = {
  children?: React.ReactNode;
};

const Row = (props: RowProps) => {
  return <div className={styles.row}>{props.children}</div>;
};

type CreateOpeningFormProps = {
  selectedDates: Date[];
  onRemoveDate: (d: Date) => void;
  onExit: () => void;
  onCreate: () => void;
  asUser?: TMUser;
};

const CreateNewOpening = (props: CreateOpeningFormProps) => {
  const user = useContext(UserContext);
  const [startTime, setStartTime] = useState<Time>({
    hour: "hh",
    minute: "mm",
    ampm: "aa",
  });
  const [selectedLocation, setSelectedLocation] = useState<StudioLocation>();
  const [studioLocations, setStudioLocations] = useState<StudioLocation[]>();
  const [usingNewStudio, setUsingNewStudio] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const getLocations = async (uid: string) => {
    const collectionRef = collection(
      db,
      "users",
      uid,
      "studioLocations"
    ).withConverter(converter<StudioLocation>());
    const snapshot = await getDocs(collectionRef);
    const data = snapshot.docs.map((snap) => snap.data());
    setStudioLocations(
      data.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary))
    );
  };

  useEffect(() => {
    if (user) {
      getLocations(user.uid);
    }
  }, []);

  useEffect(() => {
    if (studioLocations) {
      const primary = studioLocations.find((sl) => sl.isPrimary);
      setSelectedLocation(primary);
    }
  }, [studioLocations]);

  const handleSubmit = async () => {
    const batch = writeBatch(db);
    props.selectedDates.forEach((d) => {
      batch.set(
        doc(
          collection(db, "users", props.asUser?.docId || user!.uid, "openings")
        ),
        {
          date: d,
          startTime,
          location: selectedLocation,
          status: "available",
        }
      );
    });
    await batch.commit();
  };

  const formIsValid = () => {
    if (!selectedLocation) return false;
    if (!timeIsValid(startTime)) return false;
    if (props.selectedDates.length === 0) return false;
    return true;
  };

  return (
    <div className={styles.wrapper}>
      <Button onClick={props.onExit} className={styles.exitButton} noStyling>
        <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
      </Button>
      <h2 style={{ marginBottom: "20px" }}>Create New Opening</h2>
      <Row>
        <FormLabel>Date{props.selectedDates.length > 1 ? "s" : null}</FormLabel>
        <div className={styles.dateBubbleWrapper}>
          {props.selectedDates.length > 0 ? (
            props.selectedDates.map((d) => (
              <SelectionBubble
                key={`${d.getMonth()}${d.getDate()}`}
                onRemove={() => {
                  props.onRemoveDate(d);
                }}
              >{`${months[d.getMonth()]} ${d.getDate()}`}</SelectionBubble>
            ))
          ) : (
            <div style={{ color: "red" }}>
              At least one date must be selected.
            </div>
          )}
        </div>
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "stretch",
          }}
        >
          <div style={{ flex: "1", paddingRight: "5px" }}>
            <FormLabel>Start Time</FormLabel>
            <TimePicker time={startTime} onChange={(t) => setStartTime(t)} />
          </div>
        </div>
      </Row>
      <Row>
        <FormLabel>Studio Location</FormLabel>
        {studioLocations ? (
          <Fragment>
            {studioLocations.map((sl) => (
              <LocationRadio
                key={sl.docId}
                style={{ marginBottom: "10px" }}
                onChange={() => {
                  setSelectedLocation(sl);
                  setUsingNewStudio(false);
                }}
                checked={selectedLocation?.googleId === sl.googleId}
                label={`${sl.text.mainText} ${sl.text.secondaryText}`}
                id={sl.googleId}
                header={sl.isPrimary ? "Primary Studio" : "Guest Studio"}
              ></LocationRadio>
            ))}
            <LocationRadio
              id={"new"}
              checked={usingNewStudio}
              onChange={() => {
                setUsingNewStudio(true);
                setSelectedLocation(undefined);
              }}
              label={"Use a different studio."}
            >
              {usingNewStudio ? (
                <NewLocationInput
                  onSelect={(l) => {
                    setSelectedLocation(l);
                  }}
                  value={selectedLocation}
                  types={[
                    "street_address",
                    "establishment",
                    "premise",
                    "store",
                  ]}
                  placeholder="Enter Address or Studio Name"
                ></NewLocationInput>
              ) : null}
            </LocationRadio>
          </Fragment>
        ) : (
          <NewLocationInput
            onSelect={(l) => {
              setSelectedLocation(l);
            }}
            value={selectedLocation}
            types={["street_address", "establishment", "premise", "store"]}
            placeholder="Enter Address or Studio Name"
          ></NewLocationInput>
        )}
      </Row>
      <Button
        disabled={!formIsValid()}
        loading={submitting}
        onClick={() => {
          try {
            setSubmitting(true);
            handleSubmit().then(() => {
              setSubmitting(false);
              props.onCreate();
              props.onExit();
            });
          } catch (e) {
            // ...
          }
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default CreateNewOpening;
