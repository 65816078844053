import { MouseEvent, ReactNode, useContext, useEffect, useState } from "react";
import styles from "./onboardingStyles.module.css";
import Layout from "../../components/Layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db, functions } from "../../services/firebase/firebase";
import { TMUserContext, UserContext } from "../../App";
import Spinner from "../../components/Spinner/Spinner";
import Input from "../../components/Input/Input";
import FormLabel from "../../components/FormLabel/FormLabel";
import Button from "../../components/Button/Button";
import {
  ArtistMembership,
  MembershipRadio,
} from "../Settings/views/ArtistOnboarding/ArtistOnboarding";
import NewLocationInput from "../../components/NewLocationInput/NewLocationInput";
import { MyUser, StudioLocation } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { httpsCallable } from "firebase/functions";
import useUserData from "../../hooks/useUserData";
import Skeleton, { SkeletonPage } from "../../components/Skeleton/Skeleton";
import { converter } from "../../utils";
const logo = require("../../assets/logo_yellow.png");

type AccountButtonProps = {
  title: string;
  description: string;
  plans?: string[];
  onClick: () => void;
};

const AccountButton = (props: AccountButtonProps) => {
  return (
    <button
      className={styles.accountButton}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <h3 className={[styles.buttonHeader, "mb10"].join(" ")}>{props.title}</h3>
      <p className={styles.buttonText}>{props.description}</p>
    </button>
  );
};

type ViewProps = {
  userData: MyUser;
};

const AccountType = (props: ViewProps) => {
  const session = useContext(UserContext);
  const { userData } = props;
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.clientOnboardingCompleted) navigate("/home");
  }, [userData]);

  const handleSelect = async (type: string) => {
    if (session) {
      setSubmitting(true);
      const docRef = doc(db, "users", session?.uid);
      await setDoc(
        docRef,
        {
          accountType: type,
          ...(type === "artist"
            ? { booksOpen: true, appointmentTypes: "flash and custom" }
            : {}),
        },
        { merge: true }
      );
      navigate("/onboarding/personal-info", { state: type });
    }
  };

  if (session === null || typeof userData === "undefined" || submitting) {
    return <Spinner />;
  }

  return (
    <section className={styles.wrapper}>
      <h2 className="textCenter mb20">How do you plan on using TatMap?</h2>
      <div className={styles.accountButtonWrapper}>
        {[
          {
            title: "Tattoo Enjoyer",
            description: "Find artists and book appointments",
            onClick: () => {
              handleSelect("client");
            },
          },
          {
            title: "Tattoo Artist",
            description:
              "Upload flash, book with clients, and manage your calendar. Only choose this if you are a practicing tattoo artist",
            onClick: () => {
              handleSelect("artist");
            },
          },
        ].map((type) => (
          <AccountButton
            key={type.title}
            title={type.title}
            description={type.description}
            onClick={type.onClick}
          />
        ))}
      </div>
    </section>
  );
};

const PersonalInfo = (props: ViewProps) => {
  const session = useContext(UserContext);
  const { userData } = props;
  const [firstName, setFirstName] = useState(userData.firstName || "");
  const [lastName, setLastName] = useState(userData.lastName || "");
  const [pronouns, setPronouns] = useState(userData.pronouns || "");
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (userData?.clientOnboardingCompleted) navigate("/home");
  }, [userData]);

  const handleSubmit = async () => {
    if (session) {
      setSubmitting(true);
      const docRef = doc(db, "users", session.uid);
      await setDoc(
        docRef,
        {
          firstName,
          lastName,
          pronouns,
          clientOnboardingCompleted: state === "client" ? true : false,
        },
        { merge: true }
      );
      if (state === "client") navigate("/home");
      if (state === "artist") navigate("/onboarding/studio-location");
    }
  };

  if (session === null || submitting) {
    return <Spinner />;
  }

  return (
    <section className={styles.wrapper}>
      <h2 className="textCenter">Tell us more about yourself</h2>
      <p className={[styles.subheader, "mb20", "textCenter"].join(" ")}>
        You can always change this information later
      </p>
      <div className={styles.formWrapper}>
        <FormLabel>First Name</FormLabel>
        <Input
          value={firstName}
          onChange={(v) => {
            setFirstName(v);
          }}
          className="mb10"
        />
        <FormLabel>Last Name</FormLabel>
        <Input
          value={lastName}
          onChange={(v) => {
            setLastName(v);
          }}
          className="mb10"
        />
        <FormLabel>Pronouns (optional)</FormLabel>
        <Input
          value={pronouns}
          onChange={(v) => {
            setPronouns(v);
          }}
          className="mb20"
        />
        <Button onClick={handleSubmit}>Next</Button>
      </div>
    </section>
  );
};

const SetStudioLocation = (props: ViewProps) => {
  const navigate = useNavigate();
  const { userData } = props;
  const session = useContext(UserContext);
  const [studio, setStudio] = useState<StudioLocation>();

  const getStudio = async () => {
    if (session) {
      const collectionRef = collection(
        db,
        "users",
        session.uid,
        "studioLocations"
      ).withConverter(converter<StudioLocation>());
      const q = query(collectionRef, where("isPrimary", "==", true));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((d) => d.data());
      if (data.length > 0) {
        setStudio(data[0]);
      }
    }
  };

  useEffect(() => {
    getStudio();
  }, [session]);

  useEffect(() => {
    if (userData?.artistOnboardingCompleted) navigate("/home");
  }, [userData]);

  const handleSubmit = async () => {
    if (session && studio) {
      const docRef = doc(db, "users", session.uid);
      const studioDocRef = doc(
        db,
        "users",
        session.uid,
        "studioLocations",
        studio.googleId
      );
      const setUserDoc = setDoc(
        docRef,
        {
          studioLocationCompleted: true,
          searchLocation: {
            googleId: studio.googleId,
            lat: studio.location.latitude,
            lng: studio.location.longitude,
          },
        },
        { merge: true }
      );
      const setStudioDoc = setDoc(studioDocRef, {
        ...studio,
        isPrimary: true,
      });
      await Promise.all([setUserDoc, setStudioDoc]);
      navigate("/onboarding/choose-plan");
    }
  };

  return (
    <section className={styles.wrapper}>
      <h2 className="textCenter">Enter your studio location.</h2>
      <p className="textCenter mb40">You can always change this later.</p>
      <div>
        <NewLocationInput
          className="mb40"
          onSelect={(l) => {
            setStudio(l);
          }}
          value={studio}
          types={["establishment", "street_address", "premise", "store"]}
          placeholder="Enter Address or Studio Name"
        />
      </div>
      {studio && (
        <div>
          <Button onClick={handleSubmit}>
            Next <FontAwesomeIcon icon={faArrowRightLong} />
          </Button>
        </div>
      )}
    </section>
  );
};

const ChoosePlan = (props: ViewProps) => {
  const session = useContext(UserContext);
  const { userData } = props;
  const navigate = useNavigate();
  const [selected, setSelected] = useState<ArtistMembership>();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (userData?.artistOnboardingCompleted) navigate("/home");
  }, [userData]);

  const handleSubmit = async (path: string) => {
    if (session) {
      setSubmitting(true);
      const docRef = doc(db, "users", session.uid);
      await setDoc(
        docRef,
        {
          accountType: "artist",
          artistOnboardingCompleted: true,
          clientOnboardingCompleted: true,
        },
        { merge: true }
      );
      navigate(path);
    }
  };

  return (
    <section className={styles.wrapper}>
      <h2 className="textCenter">Choose your plan</h2>
      <p className="textCenter mb20">You can always change this later.</p>
      <div className={[styles.flexWrap, "mb20"].join(" ")}>
        <MembershipRadio
          type="free"
          onClick={() => {
            setSelected("free");
          }}
          selected={selected === "free" || false}
          className="m5"
        />
        <MembershipRadio
          type="pro"
          onClick={() => {
            setSelected("pro");
          }}
          selected={selected === "pro" || false}
          className="m5"
        />
      </div>
      {selected && (
        <Button
          style={{ alignSelf: "center" }}
          onClick={() => {
            if (selected === "free") handleSubmit("/home");
            if (selected === "pro") handleSubmit("/checkout");
          }}
        >
          Submit
        </Button>
      )}
    </section>
  );
};

const Redirect = (props: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(props.to);
  });
  return null;
};

const Onboarding = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const userData = useUserData([page]);

  useEffect(() => {
    if (typeof page === "undefined") {
      navigate("/onboarding/account-type");
    }
  }, [page]);

  const renderPage = (data: MyUser) => {
    switch (page) {
      case "account-type":
        return <AccountType userData={data} />;
      case "personal-info":
        return <PersonalInfo userData={data} />;
      case "studio-location":
        return <SetStudioLocation userData={data} />;
      case "choose-plan":
        return <ChoosePlan userData={data} />;
      default:
        return <Redirect to="/onboarding" />;
    }
  };

  if (typeof userData === "undefined") {
    return (
      <div className={styles.wrapper}>
        <SkeletonPage />
      </div>
    );
  }

  return (
    <div className="textCenter">
      <h2 style={{ margin: "20px 0" }}>Welcome to</h2>
      <img
        src={logo}
        alt="TatMap Logo"
        style={{ height: "80px", marginBottom: "40px" }}
      />
      {renderPage(userData)}
    </div>
  );
};

export default Onboarding;
