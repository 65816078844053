import { useContext, useEffect, useState } from "react";
import { TMUserContext, UserContext } from "../../../../App";
import { AppointmentType, MyUser } from "../../../../types";
import { doc, setDoc } from "firebase/firestore";
import { db, functions } from "../../../../services/firebase/firebase";
import { httpsCallable } from "firebase/functions";
import Spinner from "../../../../components/Spinner/Spinner";
import Toggle from "../../../../components/Toggle/Toggle";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Button from "../../../../components/Button/Button";

import styles from "../../settingsStyles.module.css";
import bookingStyles from "./bookingSettingsStyles.module.css";
import FormLabel from "../../../../components/FormLabel/FormLabel";
import MoneyInput from "../../../../components/MoneyInput/MoneyInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faCalendarCheck,
  faCalendarXmark,
} from "@fortawesome/free-solid-svg-icons";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import { setApp } from "firebase-functions/lib/common/app";

const BookingSettings = () => {
  const session = useContext(UserContext);
  const { userData, setUserData } = useContext(TMUserContext);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [booksOpen, setBooksOpen] = useState(false);
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType>();
  const [savingBooks, setSavingBooks] = useState(false);
  const [savingChanges, setSavingChanges] = useState(false);
  const [depositRequired, setDepositRequired] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [savingDeposit, setSavingDeposit] = useState(false);

  const getUserData = httpsCallable<undefined, MyUser>(
    functions,
    "getUserData"
  );

  const appointmentTypeChanges = () => {
    const current = userData?.appointmentTypes;
    if (appointmentTypes === current) return false;
    return true;
  };

  const depositDataIsValid = () => {
    if (
      depositRequired === userData?.depositRequired &&
      depositAmount ===
        (userData?.depositAmount ? `${userData?.depositAmount / 100}` : "")
    ) {
      return false;
    }
    if (depositRequired && depositAmount === "") return false;
    return true;
  };

  const handleSaveBooksOpen = async () => {
    if (session) {
      setSavingBooks(true);
      const docRef = doc(db, "users", session.uid);
      await setDoc(docRef, { booksOpen }, { merge: true });
      const results = await getUserData();
      const u = results.data;
      setUserData(u || null);
      setSavingBooks(false);
    }
  };

  const handleSaveChanges = async () => {
    if (session) {
      setSavingChanges(true);
      const docRef = doc(db, "users", session.uid);
      await setDoc(docRef, { appointmentTypes }, { merge: true });
      const results = await getUserData();
      const u = results.data;
      setUserData(u || null);
      setSavingChanges(false);
    }
  };

  const handleSaveDepositData = async () => {
    if (session) {
      setSavingDeposit(true);
      await setDoc(
        doc(db, "users", session.uid),
        {
          depositRequired: depositRequired,
          depositAmount: parseFloat(depositAmount) * 100,
        },
        { merge: true }
      );
      const results = await getUserData();
      const u = results.data;
      setUserData(u || null);
      setSavingDeposit(false);
    }
  };

  useEffect(() => {
    if (userData) {
      setBooksOpen(userData.booksOpen || false);
      setAppointmentTypes(userData.appointmentTypes);
      setDepositAmount(
        userData.depositAmount ? `${userData.depositAmount / 100}` : ""
      );
      setDepositRequired(userData.depositRequired || false);
      if (loadingUserData) setLoadingUserData(false);
    }
  }, [userData]);

  if (loadingUserData) {
    return <Spinner />;
  }

  return (
    <div>
      <h2 className="mb20">Booking Settings</h2>
      <h4 className="mb10">Books Open/Closed</h4>
      <div
        className={["flexRowCenter"].join(" ")}
        style={{
          minWidth: "300px",
          maxWidth: "400px",
          justifyContent: "space-between",
        }}
      >
        <p>
          <FontAwesomeIcon
            icon={booksOpen ? faCalendarCheck : faCalendarXmark}
            className={[
              booksOpen ? bookingStyles.openIcon : bookingStyles.closedIcon,
              "mr5",
            ].join(" ")}
          />
          {booksOpen
            ? "Books Open (Accepting Appointments)"
            : "Books Closed (Not Accepting Appointments)"}
        </p>
        <Toggle
          id="booksOpen"
          checked={booksOpen}
          onChange={() => {
            setBooksOpen((current) => !current);
          }}
        />
      </div>
      <p className={[styles.description, "mb10"].join(" ")}>
        Turning this off temporarily disables appointment requests. It will not
        delete your openings or affect any currently booked appointments.
      </p>
      <Button
        onClick={handleSaveBooksOpen}
        loading={savingBooks}
        className="mb40"
        disabled={booksOpen === userData?.booksOpen}
      >
        Save Changes
      </Button>
      <h4>Appointment Types</h4>
      <p className={styles.description}>
        Choose which appointment types you are accepting
      </p>
      {(
        ["flash only", "customs only", "flash and customs"] as AppointmentType[]
      ).map((t) => (
        <RadioButton
          className="mb10"
          key={t}
          id={t}
          label={t}
          checked={appointmentTypes === t}
          onChange={() => {
            setAppointmentTypes(t);
          }}
        ></RadioButton>
      ))}
      <Button
        onClick={handleSaveChanges}
        disabled={!appointmentTypeChanges()}
        loading={savingChanges}
        className="mb40"
      >
        Save Changes
      </Button>
      <div className="flexRowCenter">
        <h4 className="mr5">Deposits</h4>

        <span className={bookingStyles.proLabel}>
          <FontAwesomeIcon icon={faStar} className="mr5" />
          TatMap Pro
        </span>
      </div>
      <p className={[styles.description, "mb10"].join(" ")}>
        Choose whether you want to require a deposit in order to book an
        appointment.
      </p>
      <div className="flexRowCenter mb10">
        <p className="mr20">Deposit required to book</p>
        <Toggle
          disabled={userData?.isProUser ? false : true}
          id="deposit"
          checked={depositRequired && (userData?.isProUser || false)}
          onChange={() => {
            setDepositRequired((current) => !current);
          }}
        />
      </div>
      {depositRequired && (
        <div className="mb10">
          <FormLabel>Deposit Amount</FormLabel>
          <MoneyInput
            value={depositAmount}
            onChange={(v) => {
              setDepositAmount(v);
            }}
          />
        </div>
      )}
      {userData?.isProUser ? (
        <Button
          loading={savingDeposit}
          onClick={handleSaveDepositData}
          disabled={!depositDataIsValid()}
        >
          Save Changes
        </Button>
      ) : (
        <div>This feature is only available to TatMap Pro users.</div>
      )}
    </div>
  );
};

export default BookingSettings;
