import React, { useRef } from "react";
import Button from "../Button/Button";
import styles from "./fileUploadStyles.module.css";

type FileUploadProps = {
  setFile: (file: File) => void;
  children?: React.ReactNode;
  image?: boolean;
};

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    hiddenFileInput.current && hiddenFileInput.current.click();
  };
  return (
    <div className={props.image ? styles.imageWrapper : ""}>
      <Button onClick={handleClick}>{props.children || "Upload Image"}</Button>
      <input
        type="file"
        name="myImage"
        ref={hiddenFileInput}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.files) {
            props.setFile(event.target.files[0]);
          }
        }}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileUpload;
