import { Fragment } from "react/jsx-runtime";
import { TMNotification } from "../../types";
import { useEffect, useState } from "react";
import MyNotification from "../MyNotification/MyNotification";
import styles from "./notificationsStyles.module.css";

type NotificationsProps = {
  data: TMNotification[];
};

const Notifications = (props: NotificationsProps) => {
  const [newNotifications, setNewNotifications] = useState(
    props.data.filter((n) => n.viewed === false)
  );
  const [seenNotifications, setSeenNotifications] = useState(
    props.data.filter((n) => n.viewed === true)
  );

  useEffect(() => {
    setNewNotifications(props.data.filter((n) => n.viewed === false));
    setSeenNotifications(props.data.filter((n) => n.viewed === true));
  }, [props.data]);

  return (
    <Fragment>
      <h4 style={{ textAlign: "center", margin: "15px 0" }}>Notifications</h4>
      {props.data.length > 0 ? (
        <div className={styles.wrapper}>
          {newNotifications.length > 0 ? (
            <Fragment>
              <h5>New ({newNotifications.length})</h5>
              {newNotifications.map((n) => (
                <MyNotification key={n.docId} data={n}></MyNotification>
              ))}
            </Fragment>
          ) : null}
          {seenNotifications.length > 0 ? (
            <Fragment>
              <h5>Seen</h5>
              {seenNotifications.map((n) => (
                <MyNotification key={n.docId} data={n}></MyNotification>
              ))}
            </Fragment>
          ) : null}
        </div>
      ) : (
        <div>No notifications yet</div>
      )}
    </Fragment>
  );
};

export default Notifications;
