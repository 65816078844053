import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import FileUpload from "../../components/FileUpload/FileUpload";
import styles from "./postTattooStyles.module.css";
import RadioButton from "../../components/RadioButton/RadioButton";
import { TMUserContext, UserContext } from "../../App";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { db, storage } from "../../services/firebase/firebase";
import { ref, uploadBytes } from "firebase/storage";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import TextArea from "../../components/TextArea/TextArea";
import { tags } from "../../constants";
import { StudioLocation } from "../../types";
import { converter } from "../../utils";

type TagButtonProps = {
  checked: boolean;
  onChange: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
};

export const TagButton = (props: TagButtonProps) => {
  return (
    <button
      className={[
        styles.tagButton,
        props.checked ? styles.checked : null,
        props.disabled ? styles.disabled : null,
      ].join(" ")}
      onClick={props.onChange}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

const PostTattoo = () => {
  const [mainImage, setMainImage] = useState<File | null>(null);
  const [caption, setCaption] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [technique, setTechnique] = useState<string>("Machine");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [searchLocation, setSearchLocation] = useState<StudioLocation>();
  const session = useContext(UserContext);
  const { userData } = useContext(TMUserContext);
  const navigate = useNavigate();

  const getSearchLocation = async (uid: string, searchId: string) => {
    const docRef = doc(
      db,
      "users",
      uid,
      "studioLocations",
      searchId
    ).withConverter(converter<StudioLocation>());
    const snapshot = await getDoc(docRef);
    const searchLocationData = snapshot.data();
    setSearchLocation(searchLocationData);
  };

  useEffect(() => {
    if (session && userData?.searchLocation) {
      getSearchLocation(session.uid, userData.searchLocation.googleId);
    }
  }, [userData]);

  const handleSubmit = async () => {
    if (mainImage && session) {
      setSubmitting(true);
      const docRef = doc(collection(db, "users", session?.uid, "tattoos"));
      const mainImageUrl = `${session.uid}/${docRef.id}`;
      const storageRef = ref(storage, mainImageUrl);
      const postData = {
        artistId: session.uid,
        ...(caption && { caption }),
        mainImageUrl: mainImageUrl,
        tags: selectedTags,
        ...(userData?.searchLocation && {
          searchLocation: { ...userData.searchLocation },
        }),
        technique,
        createdAt: serverTimestamp(),
      };
      try {
        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, mainImage).then((snapshot) => {
          setDoc(docRef, postData).then(() => {
            navigate("/home");
          });
        });
      } catch {
      } finally {
        setSubmitting(false);
      }
    }
  };

  const formIsValid = () => {
    if (!mainImage) {
      return false;
    }
    if (selectedTags.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 style={{ marginBottom: "10px" }}>Post Tattoo</h1>
        <div className={styles.bodyWrapper}>
          <div className={styles.imageWrapper}>
            {mainImage ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <img
                  alt="not found"
                  className={styles.flashImage}
                  src={URL.createObjectURL(mainImage)}
                />
                <Button color="red" onClick={() => setMainImage(null)}>
                  Delete
                </Button>
              </div>
            ) : (
              <FileUpload
                image
                setFile={(f: File) => {
                  setMainImage(f);
                }}
              ></FileUpload>
            )}
          </div>
          <div className={styles.rightWrapper}>
            <h3>Caption (optional)</h3>
            <TextArea
              value={caption}
              onChange={(v: string) => {
                setCaption(v);
              }}
            ></TextArea>
            <h3>Tags</h3>
            <p className={styles.description}>
              Select up to five. At least one must be selected.
            </p>
            <div className={styles.tagsWrapper}>
              {tags.sort().map((tag) => {
                return (
                  <TagButton
                    disabled={
                      !selectedTags.includes(tag) && selectedTags.length === 5
                    }
                    key={tag}
                    checked={selectedTags.includes(tag)}
                    onChange={() => {
                      if (selectedTags.includes(tag)) {
                        setSelectedTags(selectedTags.filter((t) => t !== tag));
                      } else {
                        setSelectedTags((current) => [...current, tag]);
                      }
                    }}
                  >
                    {tag}
                  </TagButton>
                );
              })}
            </div>
            <h3>Technique</h3>
            {["Machine", "Handpoke", "Hybrid"].map((v) => (
              <RadioButton
                key={v}
                id={v}
                checked={technique === v}
                onChange={() => {
                  setTechnique(v);
                }}
                label={v}
                style={{ marginBottom: "5px" }}
              />
            ))}
            <h3>Search Results</h3>
            {searchLocation ? (
              <Fragment>
                <p className={styles.description}>
                  All of your posts are currently showing up in location-based
                  searches using the following studio location:
                </p>
                <div className={styles.studioLocationWrapper}>
                  {searchLocation?.text.mainText}{" "}
                  {searchLocation?.text.secondaryText}
                </div>
              </Fragment>
            ) : (
              <p
                className={styles.description}
                style={{ marginBottom: "15px" }}
              >
                Your posts are not currently showing up in location-based
                searches because you do not have a studio location set.
              </p>
            )}

            <p className={styles.description}>
              You can change this at any time in <strong>Settings</strong> {">"}{" "}
              <strong>Location Settings</strong> {">"}{" "}
              <strong>Search Results</strong>
            </p>

            <Button
              onClick={handleSubmit}
              loading={submitting}
              disabled={!formIsValid()}
            >
              Post Tattoo
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostTattoo;
