import styles from "./formLabelStyles.module.css";

type LabelProps = {
  children?: React.ReactNode;
  htmlFor?: string;
  className?: string;
};
const FormLabel = (props: LabelProps) => {
  return (
    <label
      htmlFor={props.htmlFor}
      className={[styles.label, props.className].join(" ")}
    >
      {props.children}
    </label>
  );
};

export default FormLabel;
