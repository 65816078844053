import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import FileUpload from "../../components/FileUpload/FileUpload";
import styles from "./postFlashStyles.module.css";
import RadioButton from "../../components/RadioButton/RadioButton";
import NumberInput from "../../components/NumberInput/NumberInput";
import Toggle from "../../components/Toggle/Toggle";
import { TMUserContext, UserContext } from "../../App";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { db, storage } from "../../services/firebase/firebase";
import { ref, uploadBytes } from "firebase/storage";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import TextArea from "../../components/TextArea/TextArea";
import { tags } from "../../constants";
import { StudioLocation } from "../../types";
import { converter } from "../../utils";

type TagButtonProps = {
  checked: boolean;
  onChange: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
};

export const TagButton = (props: TagButtonProps) => {
  return (
    <button
      className={[
        styles.tagButton,
        props.checked ? styles.checked : null,
        props.disabled ? styles.disabled : null,
      ].join(" ")}
      onClick={props.onChange}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

const PostFlash = () => {
  const [mainImage, setMainImage] = useState<File | null>(null);
  const [caption, setCaption] = useState<string>("");
  const [repeatable, setRepeatable] = useState<string>("unlimited");
  const [quantity, setQuantity] = useState<string | null>("1");
  const [depositRequired, setDepositRequired] = useState<boolean>(true);
  const [depositAmount, setDepositAmount] = useState<string | null>("50");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [technique, setTechnique] = useState<string>("Machine");
  const [instantBook, setInstantBook] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const session = useContext(UserContext);
  const { userData } = useContext(TMUserContext);
  const [searchLocation, setSearchLocation] = useState<StudioLocation>();
  const d = new Date();
  const navigate = useNavigate();

  const loadSearchLocation = async (uid: string, searchId: string) => {
    const docRef = doc(
      db,
      "users",
      uid,
      "studioLocations",
      searchId
    ).withConverter(converter<StudioLocation>());
    const docSnapshot = await getDoc(docRef);
    setSearchLocation(docSnapshot.data());
  };

  useEffect(() => {
    if (session && userData?.searchLocation) {
      loadSearchLocation(session.uid, userData.searchLocation.googleId);
    }
  }, [session, userData]);

  const handleRadioChange = (id: string) => {
    setRepeatable(id);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const filename = new Date().valueOf();
    const mainImageUrl = `${session?.uid}/${filename}`;
    const flashData = {
      flashId: filename,
      artistId: session?.uid,
      ...(caption && { caption }),
      isActive: true,
      mainImageUrl: mainImageUrl,
      isRepeatable: repeatable === "unlimited" ? true : false,
      quantity: parseInt(quantity || ""),
      tags: selectedTags,
      ...(userData?.searchLocation && {
        searchLocation: { ...userData.searchLocation },
      }),
      technique,
      createdAt: serverTimestamp(),
    };
    try {
      if (mainImage && session) {
        const storageRef = ref(storage, mainImageUrl);
        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, mainImage).then((snapshot) => {
          setDoc(
            doc(collection(db, "users", session.uid, "flash")),
            flashData
          ).then(() => {
            setSubmitting(false);
            navigate("/home");
          });
        });
      }
    } catch {
      setSubmitting(false);
    } finally {
    }
  };

  const formIsValid = () => {
    if (!mainImage) {
      return false;
    }
    if (selectedTags.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 style={{ marginBottom: "10px" }}>new flash post</h1>
        <div className={styles.bodyWrapper}>
          <div className={styles.imageWrapper}>
            {mainImage ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <img
                  alt="not found"
                  className={styles.flashImage}
                  src={URL.createObjectURL(mainImage)}
                />
                <Button color="red" onClick={() => setMainImage(null)}>
                  Delete
                </Button>
              </div>
            ) : (
              <FileUpload
                image
                setFile={(f: File) => {
                  setMainImage(f);
                }}
              ></FileUpload>
            )}
          </div>
          <div className={styles.rightWrapper}>
            <h3>caption (optional)</h3>
            <TextArea
              value={caption}
              onChange={(v: string) => {
                setCaption(v);
              }}
              className="mb10"
            ></TextArea>
            <h3>repeatabililty</h3>
            <p className={[styles.description, "mb5"].join(" ")}>
              Select the number of times this design can be booked.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "10px",
              }}
            >
              <RadioButton
                checked={repeatable === "unlimited"}
                onChange={handleRadioChange}
                id="unlimited"
                label="Unlimited"
              ></RadioButton>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <RadioButton
                  checked={repeatable === "fixed"}
                  onChange={handleRadioChange}
                  id="fixed"
                  label="Fixed Amount"
                  style={{ marginRight: "40px" }}
                />
                <NumberInput
                  disabled={repeatable === "unlimited"}
                  max={1000}
                  min={1}
                  value={quantity}
                  onChange={(v) => {
                    setQuantity(v);
                  }}
                ></NumberInput>
              </div>
            </div>
            <h3>tags</h3>
            <p className={styles.description}>
              Select up to five. At least one must be selected.
            </p>
            <div className={[styles.tagsWrapper, "mb10"].join(" ")}>
              {tags.sort().map((tag) => {
                return (
                  <TagButton
                    disabled={
                      !selectedTags.includes(tag) && selectedTags.length === 5
                    }
                    key={tag}
                    checked={selectedTags.includes(tag)}
                    onChange={() => {
                      if (selectedTags.includes(tag)) {
                        setSelectedTags(selectedTags.filter((t) => t !== tag));
                      } else {
                        setSelectedTags((current) => [...current, tag]);
                      }
                    }}
                  >
                    {tag}
                  </TagButton>
                );
              })}
            </div>
            <h3>technique</h3>
            <div className="mb10">
              {["Machine", "Handpoke", "Hybrid"].map((v) => (
                <RadioButton
                  key={v}
                  id={v}
                  checked={technique === v}
                  onChange={() => {
                    setTechnique(v);
                  }}
                  label={v}
                  style={{ marginBottom: "5px" }}
                />
              ))}
            </div>
            <h3>search results</h3>
            {searchLocation ? (
              <Fragment>
                <p className={[styles.description, "mb10"].join(" ")}>
                  All of your available designs are currently showing up in
                  location-based searches using the following studio location:
                </p>
                <div
                  className={[styles.studioLocationWrapper, "mb10"].join(" ")}
                >
                  {searchLocation.text.mainText}{" "}
                  {searchLocation.text.secondaryText}
                </div>
              </Fragment>
            ) : (
              <p
                className={styles.description}
                style={{ marginBottom: "15px" }}
              >
                Your designs are not currently showing up in location-based
                searches because you do not have a studio location set.
              </p>
            )}

            <p className={[styles.description, "mb10"].join(" ")}>
              You can change this at any time in <strong>Settings</strong> {">"}{" "}
              <strong>Location Settings</strong> {">"}{" "}
              <strong>Search Results</strong>
            </p>
            <Button
              onClick={handleSubmit}
              loading={submitting}
              disabled={!formIsValid()}
            >
              Post Flash
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostFlash;
