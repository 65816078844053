import styles from "./spinnerStyles.module.css";

type SpinnerProps = {
  noWrapper?: boolean;
};

const Spinner = (props: SpinnerProps) => {
  if (props.noWrapper) {
    return <div className={styles.spinner}></div>;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.ldsRing}>
        <div className={styles.innerRing}></div>
        <div className={styles.innerRing}></div>
        <div className={styles.innerRing}></div>
        <div className={styles.innerRing}></div>
      </div>
    </div>
  );
};

export default Spinner;
