import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import Auth from "../Auth/Auth";
import Header from "../Header/Header";
import Wrapper from "../Wrapper/Wrapper";
import styles from "./layoutStyles.module.css";
import PopUp from "../PopUp/PopUp";
import SearchBar from "../SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
const logo = require("../../assets/logo_yellow.png");

type LayoutProps = {
  children: React.ReactNode;
  showPopUp?: boolean;
  popUpComponent?: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [signingUp, setSigningUp] = useState<boolean>(false);
  const [showSearchPopUp, setShowSearchPopUp] = useState(false);
  const handleExit = () => {
    setLoggingIn(false);
    setSigningUp(false);
  };
  const session = useContext(UserContext);
  useEffect(() => {
    if (props.showPopUp) {
      setShowSearchPopUp(false);
    }
  }, [props.showPopUp]);
  return (
    <div className={styles.wrapper}>
      <Header
        handleLogin={() => {
          setLoggingIn(true);
        }}
        handleSignUp={() => {
          setSigningUp(true);
        }}
        handleShowSearchPopUp={() => {
          setShowSearchPopUp(true);
        }}
      ></Header>
      <Wrapper>{props.children}</Wrapper>
      {loggingIn && <Auth handleExit={handleExit}></Auth>}
      {signingUp && <Auth handleExit={handleExit} signup></Auth>}
      {props.showPopUp && props.popUpComponent}
      {showSearchPopUp && (
        <PopUp
          handleExit={() => {
            setShowSearchPopUp(false);
          }}
        >
          <img
            src={logo}
            alt="TatMap Logo"
            style={{ height: "auto", width: "150px", margin: "10px auto" }}
          />
          <SearchBar
            onSelect={(v) => {
              setShowSearchPopUp(false);
              navigate("/discover/posts", { state: v });
            }}
          />
        </PopUp>
      )}
    </div>
  );
};

export default Layout;
