import { Fragment, useEffect, useState } from "react";
import { UserContext } from "../../App";
import FlashCard from "../../components/FlashCard/FlashCard";
import Layout from "../../components/Layout/Layout";
import { db, functions } from "../../services/firebase/firebase";
import { FlashPost, StudioLocation, TMUser } from "../../types";
import styles from "./discoverStyles.module.css";
import FilterBar, {
  FilterBarButton,
} from "../../components/FilterBar/FilterBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faSliders } from "@fortawesome/free-solid-svg-icons";
import NewLocationInput from "../../components/NewLocationInput/NewLocationInput";
import { httpsCallable } from "firebase/functions";
import { tags } from "../../constants";
import { TagButton } from "../PostFlash/PostFlash";
import ToggleContent, {
  ToggleItem,
} from "../../components/ToggleContent/ToggleContent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArtistPreview from "../../components/ArtistPreview/ArtistPreview";
import Spinner from "../../components/Spinner/Spinner";
import Auth from "../../components/Auth/Auth";
import PopUp from "../../components/PopUp/PopUp";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";

type DiscoverArtistsProps = {
  locationFilter?: StudioLocation;
  selectedTags?: string[];
  techniques?: string[];
};

const DiscoverArtists = (props: DiscoverArtistsProps) => {
  const { locationFilter, selectedTags, techniques } = props;
  const [filteredArtists, setFilteredArtists] = useState<TMUser[]>([]);
  const [loadingPosts, setLoadingPosts] = useState(true);
  useEffect(() => {
    setLoadingPosts(true);
    const getFilteredArtists = httpsCallable(functions, "getFilteredArtists");

    getFilteredArtists({
      ...(locationFilter && { locationFilter: locationFilter }),
      ...(selectedTags &&
        selectedTags.length > 0 && { tagsFilter: selectedTags }),
      ...(techniques &&
        techniques.length < 2 &&
        techniques.length > 0 && { techniquesFilter: techniques[0] }),
    })
      .then((result) => {
        const data = result.data as { artists: TMUser[] };
        setFilteredArtists(data.artists);
      })
      .catch((error) => {
        const code = error.code;
        const message = error.message;
        const details = error.details;

        // ...
      })
      .finally(() => {
        setLoadingPosts(false);
      });
  }, [selectedTags, locationFilter, techniques]);

  if (loadingPosts) {
    return <Spinner />;
  }

  return (
    <div>
      <h5 style={{ margin: "20px 0 20px 10px" }}>
        {filteredArtists.length} result
        {filteredArtists.length !== 1 ? "s" : null}{" "}
        {locationFilter
          ? `near ${locationFilter.text.mainText} ${locationFilter.text.secondaryText}`
          : null}
      </h5>
      <div className={styles.artistsWrapper}>
        {filteredArtists?.map((artist) => (
          <ArtistPreview
            key={artist.docId}
            data={artist}
            style={{ marginBottom: "30px" }}
          />
        ))}
      </div>
    </div>
  );
};

const Discover = () => {
  const [posts, setPosts] = useState<FlashPost[] | undefined>();
  const { type } = useParams();
  const { state } = useLocation();
  const [searchType, setSearchType] = useState(type || "posts");
  const [postTypes, setPostTypes] = useState<string[]>(["Flash", "Tattoo"]);
  const [techniques, setTechniques] = useState<string[]>([
    "Machine",
    "Handpoke",
    "Hybrid",
  ]);
  const [locationFilter, setLocationFilter] = useState<
    StudioLocation | undefined
  >(state || undefined);
  const [filteredPosts, setFilteredPosts] = useState<FlashPost[] | undefined>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [showAuthPopUp, setShowAuthPopUp] = useState(false);
  const [showFilterPopUp, setShowFilterPopUp] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setSearchType(type || "posts");
  }, [type]);

  useEffect(() => {
    setLoadingPosts(true);
    const getFilteredPosts = httpsCallable(functions, "getFilteredPosts");

    getFilteredPosts({
      ...(locationFilter && { locationFilter: locationFilter }),
      ...(selectedTags.length > 0 && { tagsFilter: selectedTags }),
      ...(techniques.length < 3 && { techniquesFilter: techniques }),
      ...(postTypes.length === 1 && { postTypesFilter: postTypes[0] }),
    })
      .then((result) => {
        const data = result.data as { posts: FlashPost[] };

        setFilteredPosts(data.posts);
      })
      .catch((error) => {
        const code = error.code;
        const message = error.message;
        const details = error.details;

        // ...
      })
      .finally(() => {
        setLoadingPosts(false);
      });
  }, [selectedTags, locationFilter, techniques, postTypes]);

  useEffect(() => {
    if (state) {
      setLocationFilter(state);
    }
  }, [state]);

  const LocationFilter = () => (
    <Fragment>
      <div style={{ minWidth: "300px" }}>
        <NewLocationInput
          suggestionsType="relative"
          onSelect={(place) => {
            setLocationFilter(place);
          }}
          value={locationFilter}
        />
        <div style={{ margin: "10px 0" }}>Showing results within 10 miles.</div>
      </div>
    </Fragment>
  );

  const TagFilter = () => (
    <div style={{ minWidth: "300px" }}>
      {tags.sort().map((t) => (
        <TagButton
          key={t}
          checked={selectedTags.includes(t)}
          onChange={() => {
            if (selectedTags.includes(t)) {
              setSelectedTags((current) => current.filter((tag) => tag !== t));
            } else {
              setSelectedTags((current) => [...current, t]);
            }
          }}
        >
          {t}
        </TagButton>
      ))}
    </div>
  );

  const TechniquesFilter = () => (
    <Fragment>
      {["Machine", "Handpoke", "Hybrid"].map((s) => (
        <Checkbox
          key={s}
          label={s}
          checked={techniques.includes(s)}
          onChange={() => {
            if (techniques.includes(s)) {
              setTechniques([...techniques.filter((p) => p !== s)]);
            } else {
              setTechniques([s, ...techniques]);
            }
          }}
        />
      ))}
    </Fragment>
  );

  return (
    <Layout
      showPopUp={showAuthPopUp || showFilterPopUp}
      popUpComponent={
        showAuthPopUp ? (
          <Auth
            handleExit={() => {
              setShowAuthPopUp(false);
            }}
          />
        ) : showFilterPopUp ? (
          <PopUp
            handleExit={() => {
              setShowFilterPopUp(false);
            }}
            style={{ alignItems: "stretch", justifyContent: "flex-start" }}
          >
            <div className={styles.mobileFiltersContent}>
              <h2 style={{ textAlign: "center" }}>Filters</h2>
              <div className={styles.filterSection}>
                <h4>Location</h4>
                <LocationFilter />
              </div>
              <div className={styles.filterSection}>
                <h4>Tags</h4>
                <TagFilter />
              </div>
              <div className={styles.filterSection}>
                <h4>Techniques</h4>
                <TechniquesFilter />
              </div>
            </div>
            <div className={styles.bottomBarMobileFilters}>
              <Button
                className={styles.button}
                onClick={() => {
                  setShowFilterPopUp(false);
                }}
              >
                Apply
              </Button>
            </div>
          </PopUp>
        ) : null
      }
    >
      <FilterBar>
        <ToggleContent style={{ marginRight: "10px" }}>
          <ToggleItem
            onClick={() => {
              navigate("/discover/posts");
            }}
            selected={searchType === "posts"}
          >
            Posts
          </ToggleItem>
          <ToggleItem
            onClick={() => {
              navigate("/discover/artists");
            }}
            selected={searchType === "artists"}
          >
            Artists
          </ToggleItem>
        </ToggleContent>
        <div className={styles.mobileFiltersWrapper}>
          <FilterBarButton
            onClick={() => {
              setShowFilterPopUp(true);
            }}
          >
            <FontAwesomeIcon icon={faSliders} style={{ marginRight: "5px" }} />
            Filters
          </FilterBarButton>
        </div>
        <div className={styles.desktopFiltersWrapper}>
          <FilterBarButton dropdownContent={<LocationFilter />}>
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{ marginRight: "5px" }}
            />
            {locationFilter ? locationFilter.text.mainText : "Location"}
          </FilterBarButton>
          <FilterBarButton dropdownContent={<TagFilter />}>
            Tags {selectedTags.length > 0 ? `(${selectedTags.length})` : null}
          </FilterBarButton>
          <FilterBarButton dropdownContent={<TechniquesFilter />}>
            Technique ({techniques.length})
          </FilterBarButton>
        </div>
      </FilterBar>

      {searchType === "artists" ? (
        <DiscoverArtists
          locationFilter={locationFilter}
          selectedTags={selectedTags}
          techniques={techniques}
        />
      ) : loadingPosts ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className={styles.postsWrapper}>
            {filteredPosts?.map((data, i) => (
              <FlashCard
                artistId={data.artistId}
                flashId={data.docId}
                data={data}
                key={data.docId}
                showArtistLabel
                size="fullyresponsive"
                onShowAuthPopUp={() => {
                  setShowAuthPopUp(true);
                }}
                style={{ padding: "5px" }}
              ></FlashCard>
            ))}
          </div>
        </Fragment>
      )}
    </Layout>
  );
};

export default Discover;
