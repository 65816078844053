import { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./phoneInputStyles.module.css";
import Input from "../Input/Input";

type PhoneInputProps = {
  value: string;
  onChange: (v: string) => void;
};

const PhoneInput = (props: PhoneInputProps) => {
  const [displayValue, setDisplayValue] = useState<string>("");
  const [countryValue, setCountryValue] = useState();
  const hiddenRef = useRef<HTMLInputElement>(null);

  const handlePhoneChange = (val: string) => {
    const newVal = val.replace(/\D/g, "");
    const isOnlyDigits = /^\d+$/.test(val) || val === "";

    if (newVal.length <= 10) {
      props.onChange(newVal);
    }
  };

  useEffect(() => {
    if (props.value.length === 0) setDisplayValue("");
    if (props.value.length > 0 && props.value.length < 4)
      setDisplayValue(props.value);
    if (props.value.length >= 4 && props.value.length < 7)
      setDisplayValue(
        `(${props.value.substring(0, 3)}) ${props.value.substring(3)}`
      );
    if (props.value.length >= 7)
      setDisplayValue(
        `(${props.value.substring(0, 3)}) ${props.value.substring(
          3,
          6
        )}-${props.value.substring(6)}`
      );
  }, [props.value]);

  return (
    <Input
      placeholder="(555) 555-5555"
      value={displayValue}
      onChange={handlePhoneChange}
    ></Input>
  );
};

export default PhoneInput;
