import Layout from "../../components/Layout/Layout";

const DepositPaid = () => {
  return (
    <Layout>
      <div>Deposit Paid!</div>
    </Layout>
  );
};

export default DepositPaid;
