import styles from "./toggleContentStyles.module.css";

type ToggleItemProps = {
  selected: boolean;
  onClick: () => void;
  children?: React.ReactNode;
};

export const ToggleItem = (props: ToggleItemProps) => {
  return (
    <button
      onClick={props.onClick}
      className={[
        styles.toggleItem,
        props.selected ? styles.selected : null,
      ].join(" ")}
    >
      {props.children?.toString().toUpperCase()}
    </button>
  );
};

type ToggleContentProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const ToggleContent = (props: ToggleContentProps) => {
  return (
    <div className={styles.toggleWrapper} style={props.style}>
      {props.children}
    </div>
  );
};

export default ToggleContent;
