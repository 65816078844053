import styles from "./checkboxStyles.module.css";

type CheckboxProps = {
  label: string;
  checked: boolean;
  onChange: () => void;
};

const Checkbox = (props: CheckboxProps) => {
  return (
    <div className={styles.checkboxWrapper}>
      <input
        className={styles.input}
        id={props.label}
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
      />
      <label className={styles.inputLabel} htmlFor={props.label}>
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
