import {
  CSSProperties,
  Fragment,
  MouseEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";
import Anchor from "../Anchor/Anchor";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db, functions, storage } from "../../services/firebase/firebase";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import PopUp from "../PopUp/PopUp";
import styles from "./authStyles.module.css";
import { httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleHalfStroke,
  faEye,
  faEyeSlash,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Spinner/Spinner";
import { fetchUserWithImageUrl } from "../../utils";
import { TMUserContext } from "../../App";
import FormLabel from "../FormLabel/FormLabel";
import { MyUser } from "../../types";

const logo = require("../../assets/logo_yellow.png");

type UsernameInputProps = {
  value: string;
  onChange: (v: string) => void;
  onValidityChange: (isValid: boolean) => void;
  onAvailabilityChange: (isAvailable: boolean) => void;
  onCheckingChange: (isChecking: boolean) => void;
  hideInfo?: boolean;
};

export const UsernameInput = (props: UsernameInputProps) => {
  const [usernameIsValid, setUsernameIsValid] = useState(false);
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(false);
  const [checkingUsername, setCheckingUsername] = useState(false);

  const checkIfUsernameExists = httpsCallable(
    functions,
    "checkIfUsernameExists"
  );

  const handleUsernameInput = (v: string) => {
    setCheckingUsername(true);
    checkIfUsernameExists({ username: v })
      .then((result) => {
        const data = result.data as { userExists: boolean };
        if (data.userExists) {
          setUsernameIsAvailable(false);
        } else {
          setUsernameIsAvailable(true);
        }
      })
      .catch((error) => {
        const message = error.message;
        const details = error.details;

        // ...
      })
      .finally(() => {
        setCheckingUsername(false);
      });
  };

  useEffect(() => {
    props.onAvailabilityChange(usernameIsAvailable);
  }, [usernameIsAvailable]);

  useEffect(() => {
    props.onCheckingChange(checkingUsername);
  }, [checkingUsername]);

  return (
    <Fragment>
      <div className={styles.usernameInputWrapper}>
        <Input
          type="text"
          value={props.value}
          placeholder="Username"
          label="Username"
          onChange={(v) => {
            props.onChange(v);
            handleUsernameInput(v);
          }}
          className={styles.usernameInput}
        />
        <span className={styles.at}>@</span>
      </div>
      {props.hideInfo ? null : (
        <Fragment>
          {props.value && usernameIsValid && (
            <AvailabilityText
              isChecking={checkingUsername}
              isAvailable={usernameIsAvailable}
            />
          )}
          <UsernameValidation
            username={props.value}
            onChange={(isValid) => {
              setUsernameIsValid(isValid);
              props.onValidityChange(isValid);
            }}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

type PasswordInputProps = {
  value: string;
  onChange: (v: string) => void;
  placeholder?: string;
  label?: string;
  style?: CSSProperties;
  className?: string;
};

export const PasswordInput = (props: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div
      className={[styles.passwordInputWrapper, props.className].join(" ")}
      style={props.style}
    >
      <Input
        placeholder={props.placeholder}
        className={styles.passwordInput}
        value={props.value}
        onChange={props.onChange}
        type={showPassword ? "text" : "password"}
        label={props.label}
      ></Input>
      <button
        type="button"
        className={styles.eyeIconWrapper}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setShowPassword((current) => !current);
        }}
      >
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          className={styles.eyeIcon}
        />
      </button>
    </div>
  );
};

type AuthProps = {
  handleExit: () => void;
  signup?: boolean;
  redirect?: boolean;
};

type AuthMode = "signin" | "signup" | "emailVerification" | "forgotPassword";

const Auth = (props: AuthProps) => {
  const navigate = useNavigate();
  const [authType, setAuthType] = useState<string>(
    props.signup ? "signup" : "signin"
  );
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [email, setEmail] = useState<string>();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [mode, setMode] = useState<AuthMode>(
    props.signup ? "signup" : "signin"
  );

  const renderMode = () => {
    switch (mode) {
      case "signup":
        return (
          <SignUp
            redirect={
              typeof props.redirect === "undefined" ? true : props.redirect
            }
            onEmailVerificationSent={(email) => {
              setEmail(email);
              setMode("emailVerification");
            }}
            onSetAuthMode={(v: AuthMode) => {
              setMode(v);
            }}
          />
        );
      case "signin":
        return (
          <SignIn
            redirect={
              typeof props.redirect === "undefined" ? true : props.redirect
            }
            onSetAuthMode={(v: AuthMode) => {
              setMode(v);
            }}
          />
        );
      case "emailVerification":
        return (
          <div className={["textCenter", styles.modeWrapper].join(" ")}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={[styles.accountCreatedIcon, "mb10"].join(" ")}
            />
            <h2 className="mb20">Account Created!</h2>
            <p className="mb10">An email verification was sent to {email}.</p>

            <p className="mb20">Please check your inbox.</p>
            <Button href="/onboarding" style={{ margin: "0px auto" }}>
              Continue
            </Button>
          </div>
        );
      case "forgotPassword":
        return (
          <ForgotPassword
            onSetAuthMode={(v: AuthMode) => {
              setMode(v);
            }}
          />
        );
      default:
        return <div>Error</div>;
    }
  };

  return <PopUp handleExit={props.handleExit}>{renderMode()}</PopUp>;
};

type ForgotPasswordProps = {
  onSetAuthMode: (v: AuthMode) => void;
};

const ForgotPassword = (props: ForgotPasswordProps) => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [sendingEmail, setSendingEmail] = useState(false);

  const handleSendEmail = async () => {
    setSendingEmail(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        setSendingEmail(false);
      });
  };

  return (
    <div className={["flexColumn", styles.modeWrapper, "textCenter"].join(" ")}>
      <div className="mb20">
        <img src={logo} className={styles.logo} />
      </div>
      {emailSent ? (
        <div className="mb20">
          An email with a password reset link was sent to {email}
        </div>
      ) : (
        <Fragment>
          <h4 className={"mb10"}>
            Enter your email to receive a link to reset your password.
          </h4>
          <Input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(v) => {
              setEmail(v);
            }}
            className="mb10"
          />
          <Button
            onClick={handleSendEmail}
            className="mb20"
            loading={sendingEmail}
          >
            Send Link
          </Button>
          {errorMessage && <div>{errorMessage}</div>}
        </Fragment>
      )}
      <Anchor
        onClick={() => {
          props.onSetAuthMode("signin");
        }}
        className="textCenter"
        style={{ fontSize: "0.8em" }}
      >
        Back to sign in
      </Anchor>
    </div>
  );
};

type SignUpProps = {
  redirect: boolean;
  onEmailVerificationSent: (email: string) => void;
  onSetAuthMode: (v: AuthMode) => void;
};

const SignUp = (props: SignUpProps) => {
  const { userData, setUserData } = useContext(TMUserContext);

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [usernameIsValid, setUsernameIsValid] = useState(false);
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(false);
  const [usernameIsChecking, setUsernameIsChecking] = useState(false);
  const [authError, setAuthError] = useState<string>();
  const [signingUp, setSigningUp] = useState(false);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (usernameIsAvailable && usernameIsValid && !usernameIsChecking) {
      try {
        setSigningUp(true);
        setAuthError(undefined);
        const createUser = httpsCallable<
          { email: string; password: string; username: string },
          { success?: string; error?: string }
        >(functions, "createNewUser");
        const createResult = await createUser({
          email: emailAddress,
          password: password,
          username: username.toLowerCase(),
        });
        if (createResult.data.success) {
          const userCredential = await signInWithEmailAndPassword(
            auth,
            emailAddress,
            password
          );
          const user = userCredential.user;
          await sendEmailVerification(user);
          const getUserData = httpsCallable<undefined, MyUser>(
            functions,
            "getUserData"
          );
          const results = await getUserData();
          const u = results.data;
          setUserData(u);
          props.onEmailVerificationSent(emailAddress);
        } else {
          setAuthError(createResult.data.error);
        }
      } catch (err) {
        setAuthError("Error Signing Up");
      } finally {
        setSigningUp(false);
      }
    }
  };

  if (signingUp) {
    return <Spinner />;
  }

  return (
    <div className={styles.modeWrapper}>
      <div className={styles.headerWrapper}>
        <h2 className={styles.headerText}>Welcome to</h2>
        <img src={logo} className={styles.logo} />
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          value={emailAddress}
          label="Email Address"
          placeholder="Email Address"
          onChange={(v) => {
            setEmailAddress(v);
          }}
          style={{ marginBottom: "10px" }}
        ></Input>
        <PasswordInput
          value={password}
          placeholder="Password"
          label="Password"
          onChange={(v) => {
            setPassword(v);
          }}
          style={{ marginBottom: "10px" }}
        ></PasswordInput>

        <UsernameInput
          value={username}
          onChange={(v) => {
            setUsername(v);
          }}
          onValidityChange={(validity) => {
            setUsernameIsValid(validity);
          }}
          onAvailabilityChange={(availability) => {
            setUsernameIsAvailable(availability);
          }}
          onCheckingChange={(checkingStatus) => {
            setUsernameIsChecking(checkingStatus);
          }}
        />

        <Button type="submit" style={{ margin: "20px 0" }} disabled={signingUp}>
          Sign Up
        </Button>
        {authError && <div className={styles.error}>{authError}</div>}
      </form>
      <div style={{ textAlign: "center", fontSize: "0.8em" }}>
        <Anchor
          onClick={() => {
            props.onSetAuthMode("signin");
          }}
        >
          Already have an account? Sign in
        </Anchor>
      </div>
    </div>
  );
};

type SignInProps = {
  redirect: boolean;
  onSetAuthMode: (v: AuthMode) => void;
};

const SignIn = (props: SignInProps) => {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(TMUserContext);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [signingIn, setSigningIn] = useState(false);
  const [authError, setAuthError] = useState<string>();

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setSigningIn(true);
    setAuthError(undefined);
    signInWithEmailAndPassword(auth, emailAddress, password)
      .then(async (userCredential) => {
        const getUserData = httpsCallable(functions, "getUserData");
        const results = await getUserData();
        const u = results.data as MyUser;
        setUserData(u || null);
        setSigningIn(false);
        if (props.redirect) navigate("/home");
      })
      .catch((error) => {
        if (error.code === "auth/invalid-credential") {
          setAuthError("Error: Invalid Username or Password");
        } else {
          setAuthError(error.message);
        }
        setSigningIn(false);
      });
  };

  if (signingIn) {
    return <Spinner />;
  }

  return (
    <div className={styles.modeWrapper}>
      <div className={styles.headerWrapper}>
        <h2 className={styles.headerText}>Welcome to</h2>
        <img src={logo} style={{ height: "3.2em" }} />
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          value={emailAddress}
          label="Email Address"
          placeholder="Email Address"
          onChange={(v) => {
            setEmailAddress(v);
          }}
          style={{ marginBottom: "10px" }}
        ></Input>
        <PasswordInput
          value={password}
          placeholder="Password"
          label="Password"
          onChange={(v) => {
            setPassword(v);
          }}
          style={{ marginBottom: "10px" }}
        />

        <Button type="submit" style={{ margin: "20px 0" }} disabled={signingIn}>
          Sign In
        </Button>
        {authError && <div className={styles.error}>{authError}</div>}
        <div style={{ textAlign: "center", fontSize: "0.8em" }}>
          <Anchor
            onClick={() => {
              props.onSetAuthMode("forgotPassword");
            }}
          >
            Forgot your password?
          </Anchor>
          <Anchor
            onClick={() => {
              props.onSetAuthMode("signup");
            }}
          >
            Don't have an account? Sign up
          </Anchor>
        </div>
      </form>
    </div>
  );
};

type UsernameValidationProps = {
  username: string;
  onChange: (isValid: boolean) => void;
};

const UsernameValidation = (props: UsernameValidationProps) => {
  const [charactersAreValid, setCharactersAreValid] = useState(false);
  const [containsAtLeastThree, setContainsAtLeastThree] = useState(false);
  const [firstCharIsLetter, setFirstCharIsLetter] = useState(false);
  const [containsLessThan, setContainsLessThan] = useState(true);

  const containsValidCharacters = (val: string) => {
    var re = /^\w+$/;
    if (!re.test(val)) return false;
    return true;
  };

  const checkFirstChar = (val: string) => {
    const firstChar = val.charAt(0).toLowerCase();
    return firstChar.match(/[a-z]/i) ? true : false;
  };

  useEffect(() => {
    const validChars = containsValidCharacters(props.username);
    const longEnough = props.username.length >= 3;
    const shortEnough = props.username.length < 24;
    const firstCharValid = checkFirstChar(props.username);
    setCharactersAreValid(validChars);
    setContainsAtLeastThree(longEnough);
    setFirstCharIsLetter(firstCharValid);
    setContainsLessThan(shortEnough);
    props.onChange(validChars && longEnough && shortEnough && firstCharValid);
  }, [props.username]);

  return (
    <div className={styles.usernameValidationWrapper}>
      <h5>Username must meet the following criteria:</h5>
      <ul className={styles.usernameValidationList}>
        <li>
          <FontAwesomeIcon
            icon={charactersAreValid ? faCheckCircle : faXmarkCircle}
            className={[
              styles.textIcon,
              charactersAreValid
                ? styles.availableIcon
                : styles.unavailableIcon,
            ].join(" ")}
          />
          Only contains letters, numbers, and underscores
        </li>
        <li>
          <FontAwesomeIcon
            icon={firstCharIsLetter ? faCheckCircle : faXmarkCircle}
            className={[
              styles.textIcon,
              firstCharIsLetter ? styles.availableIcon : styles.unavailableIcon,
            ].join(" ")}
          />
          Starts with a letter
        </li>
        <li>
          <FontAwesomeIcon
            icon={containsAtLeastThree ? faCheckCircle : faXmarkCircle}
            className={[
              styles.textIcon,
              containsAtLeastThree
                ? styles.availableIcon
                : styles.unavailableIcon,
            ].join(" ")}
          />
          Contains at least 3 characters
        </li>
        <li>
          <FontAwesomeIcon
            icon={containsLessThan ? faCheckCircle : faXmarkCircle}
            className={[
              styles.textIcon,
              containsLessThan ? styles.availableIcon : styles.unavailableIcon,
            ].join(" ")}
          />
          Contains less than 24 characters
        </li>
      </ul>
    </div>
  );
};

type AvailabilityTextProps = {
  isChecking: boolean;
  isAvailable: boolean;
};

const AvailabilityText = (props: AvailabilityTextProps) => {
  return (
    <div className={styles.availabilityTextWrapper}>
      {props.isChecking ? (
        <div>
          <FontAwesomeIcon
            icon={faCircleHalfStroke}
            className={[styles.textIcon, styles.checkingIcon].join(" ")}
          />
          Checking if username is available
        </div>
      ) : props.isAvailable ? (
        <div>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={[styles.availableIcon, styles.textIcon].join(" ")}
          />
          Username is available
        </div>
      ) : (
        <div>
          <FontAwesomeIcon
            icon={faXmarkCircle}
            className={[styles.unavailableIcon, styles.textIcon].join(" ")}
          />{" "}
          Username is not available
        </div>
      )}
    </div>
  );
};

export default Auth;
