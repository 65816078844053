import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../components/Layout/Layout";
import styles from "./landingPageStyles.module.css";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import LandingPageAnimation2 from "../../components/LandingPageAnimation2/LandingPageAnimation2";
import Footer from "../../components/Footer/Footer";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <section className={styles.wrapper}>
        <h1 className={styles.header}>Find your next tattoo.</h1>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              navigate("/discover");
            }}
            className={styles.button}
            style={{ margin: "10px auto" }}
          >
            Get Started{" "}
            <FontAwesomeIcon icon={faArrowRightLong}></FontAwesomeIcon>
          </Button>
        </div>
      </section>
      <LandingPageAnimation2 />
      <Footer></Footer>
    </Layout>
  );
};

export default LandingPage;
