import { collection, getDocs, limit, query } from "firebase/firestore";
import { FlashPost } from "../../types";
import { converter, fetchFlashPosts, fetchImageUrl } from "../../utils";
import Button from "../Button/Button";
import PopUp from "../PopUp/PopUp";
import styles from "./flashPopUpStyles.module.css";
import { useEffect, useRef, useState } from "react";
import { db } from "../../services/firebase/firebase";

type FlashButtonProps = {
  data: FlashPost;
  onClick: (d: FlashPost) => void;
};

const FlashButton = (props: FlashButtonProps) => {
  const [imageUrl, setImageUrl] = useState<string>();
  useEffect(() => {
    fetchImageUrl(props.data.mainImageUrl).then((url) => {
      setImageUrl(url);
    });
  }, []);
  return (
    <Button
      noStyling
      onClick={() => {
        props.onClick(props.data);
      }}
      className={styles.flashButton}
    >
      <img src={imageUrl} className={styles.flashImage}></img>
    </Button>
  );
};

type FlashPopUpProps = {
  handleExit: () => void;
  handleSelect: (d: FlashPost) => void;
  artistId: string;
};

const FlashPopUp = (props: FlashPopUpProps) => {
  const [flashPosts, setFlashPosts] = useState<FlashPost[]>();

  const getPosts = async () => {
    const collectionRef = collection(db, "users", props.artistId, "flash");
    const q = query(collectionRef, limit(100)).withConverter(
      converter<FlashPost>()
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  };

  useEffect(() => {
    getPosts().then((posts) => {
      setFlashPosts(posts);
    });
  }, []);

  return (
    <PopUp handleExit={props.handleExit}>
      <h2>Available Flash</h2>
      <div className={styles.postsWrapper}>
        {flashPosts &&
          flashPosts.map((d) => (
            <FlashButton key={d.docId} data={d} onClick={props.handleSelect} />
          ))}
      </div>
    </PopUp>
  );
};

export default FlashPopUp;
