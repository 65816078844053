import styles from "./footerStyles.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p className="mb20">
        Developed by{" "}
        <a
          href="https://pulsewidthlabs.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pulsewidth Labs
        </a>
      </p>
      <div>© 2024 Pulsewidth LLC</div>
    </footer>
  );
};

export default Footer;
