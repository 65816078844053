import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  days,
  fetchAppointment,
  fetchFlashPost,
  fetchImageUrl,
  fetchUser,
  months,
} from "../../utils";
import { Appointment, FlashPost, TMUser } from "../../types";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import styles from "./appointmentDetailStyles.module.css";
import Button from "../../components/Button/Button";
import { TMUserContext, UserContext } from "../../App";
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import AppointmentStatus from "../../components/AppointmentStatus/AppointmentStatus";
import PopUp from "../../components/PopUp/PopUp";
import RadioButton from "../../components/RadioButton/RadioButton";
import Spinner from "../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

type RowProps = {
  children?: React.ReactNode;
};

const Row = (props: RowProps) => {
  return <div className={styles.row}>{props.children}</div>;
};

type CancelRequestPopUpProps = {
  handleExit: () => void;
  appointmentData: Appointment;
  appointment?: boolean;
};

const CancelRequestPopUp = (props: CancelRequestPopUpProps) => {
  const { appointmentData, handleExit } = props;
  const { userData } = useContext(TMUserContext);

  const navigate = useNavigate();

  const handleConfirm = async () => {
    setDoc(
      doc(db, "appointments", appointmentData.docId),
      {
        status: "cancelled",
      },
      { merge: true }
    ).then(() => {
      props.handleExit();
    });
  };

  return (
    <PopUp handleExit={handleExit}>
      <div className="mb20">
        Are you sure you want to cancel this appointment
        {props.appointment ? null : " request"}?
      </div>
      <Button color="red" className="mb10" onClick={handleConfirm}>
        Yes, cancel
      </Button>
      <Button color="gray" onClick={props.handleExit}>
        No
      </Button>
    </PopUp>
  );
};

type AcceptPopUpProps = {
  handleExit: () => void;
  appointmentData: Appointment;
  clientData: TMUser;
};

const AcceptPopUp = (props: AcceptPopUpProps) => {
  const { userData } = useContext(TMUserContext);
  const { appointmentData, clientData, handleExit } = props;

  const navigate = useNavigate();

  const handleConfirm = async () => {
    if (userData) {
      await setDoc(
        doc(db, "appointments", appointmentData.docId),
        {
          status:
            userData.isProUser && userData.depositRequired
              ? "accepted"
              : "confirmed",
        },
        { merge: true }
      );
      await setDoc(
        doc(db, "users", userData.docId, "openings", appointmentData.openingId),
        { status: "claimed" },
        { merge: true }
      );
      handleExit();
      navigate("/calendar");
    }
  };

  return (
    <PopUp handleExit={handleExit}>
      <div style={{ maxWidth: "600px" }}>
        <h3>Sending To</h3>
        <div className="flexRowCenter mb20">
          <ProfileImage
            imgLocation={clientData.imgLocation}
            size="xs"
            style={{ marginRight: "10px" }}
          />
          <div>
            <h4>
              {clientData.firstName} {clientData.lastName}
            </h4>
            <div>@{clientData.username}</div>
          </div>
        </div>
        <h3 className="mb5">Appointment Status</h3>
        <AppointmentStatus
          className="mb10"
          status={
            userData?.isProUser && userData?.depositRequired
              ? "accepted"
              : "confirmed"
          }
        />
        {userData?.isProUser &&
          userData.depositRequired &&
          userData.depositAmount && (
            <p style={{ color: "rgb(50,50,50)" }} className="mb20">
              Due to your booking settings, this appointment is not confirmed
              until the client pays a deposit of ${userData.depositAmount / 100}
              . They will be notified via email and TatMap notifications. The
              appointment status will be automatically updated to "confirmed"
              upon successful payment.
            </p>
          )}
        <div className={[styles.appointmentPreview, "mb20"].join(" ")}>
          <h5>{`${days[appointmentData.date.getDay()]} ${
            appointmentData.date.getMonth() + 1
          }/${appointmentData.date.getDate()}/${appointmentData.date.getFullYear()}`}</h5>
          <h3 className="mb10">{`${parseInt(appointmentData.startTime.hour)}:${
            appointmentData.startTime.minute
          } ${appointmentData.startTime.ampm}`}</h3>
          <div>
            <FontAwesomeIcon icon={faLocationDot} className="mr5" />{" "}
            {appointmentData.location.text.mainText}{" "}
            {appointmentData.location.text.secondaryText}
          </div>
        </div>
        <h4>Include a message (optional)</h4>
        <div className="mb20">
          <textarea className={styles.responseMessage}></textarea>
        </div>

        <div className="flexColumn">
          <Button onClick={handleConfirm} className="mb5">
            Accept
          </Button>
          <Button color="gray" onClick={handleExit}>
            Cancel
          </Button>
        </div>
      </div>
    </PopUp>
  );
};

type DeclinePopUpProps = {
  handleExit: () => void;
  appointmentData: Appointment;
  clientData: TMUser;
};

const DeclinePopUp = (props: DeclinePopUpProps) => {
  const { appointmentData, clientData, handleExit } = props;
  const [reason, setReason] = useState<string>(
    "Selected flash is no longer available"
  );
  const { userData } = useContext(TMUserContext);

  const navigate = useNavigate();

  const handleConfirm = () => {
    setDoc(
      doc(db, "appointments", appointmentData.docId),
      { status: "declined" },
      { merge: true }
    ).then(() => {
      navigate("/calendar");
    });
  };

  return (
    <PopUp handleExit={props.handleExit}>
      <div>
        <h4>Sending To</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ProfileImage
            imgLocation={clientData.imgLocation}
            size="xs"
            style={{ marginRight: "10px" }}
          />
          <div>
            <h4>
              {clientData.firstName} {clientData.lastName}
            </h4>
            <div>@{clientData.username}</div>
          </div>
        </div>
        <h4 style={{ marginTop: "20px" }}>Appointment Status</h4>
        <AppointmentStatus status="declined" />
        <div
          className={styles.appointmentPreview}
          style={{ marginTop: "20px" }}
        >
          <h5>{`${
            appointmentData.date.getMonth() + 1
          }/${appointmentData.date.getDate()}/${appointmentData.date.getFullYear()}`}</h5>
          <h3>{`${parseInt(appointmentData.startTime.hour)}:${
            appointmentData.startTime.minute
          } ${appointmentData.startTime.ampm}`}</h3>
          <div>Studio Location: {appointmentData.location.text.mainText}</div>
        </div>
        <h4 style={{ marginTop: "20px" }}>Reason</h4>
        <RadioButton
          onChange={() => {
            setReason("Selected flash is no longer available");
          }}
          checked={reason === "Selected flash is no longer available"}
          id={"flash"}
          label={"Selected flash is no longer available"}
          style={{ marginBottom: "5px" }}
        />
        <RadioButton
          onChange={() => {
            setReason("Selected appointment time is no longer available");
          }}
          checked={
            reason === "Selected appointment time is no longer available"
          }
          id={"time"}
          label={"Selected appointment time is no longer available"}
          style={{ marginBottom: "5px" }}
        />
        <RadioButton
          onChange={() => {
            setReason("Other");
          }}
          checked={reason === "Other"}
          id={"other"}
          label={"Other"}
          style={{ marginBottom: "5px" }}
        />
        <h4 style={{ marginTop: "20px" }}>Include a message (optional)</h4>
        <div style={{ marginBottom: "20px" }}>
          <textarea className={styles.responseMessage}></textarea>
        </div>
        <Button onClick={handleConfirm} style={{ marginRight: "10px" }}>
          Confirm
        </Button>
        <Button color="red" onClick={handleExit}>
          Cancel
        </Button>
      </div>
    </PopUp>
  );
};

const AppointmentDetail = () => {
  const session = useContext(UserContext);
  const { userData } = useContext(TMUserContext);
  const { appointmentId } = useParams();
  const [selectedFlash, setSelectedFlash] = useState<FlashPost>();
  const [selectedFlashUrl, setSelectedFlashUrl] = useState<string>();
  const [referencePhotoUrl, setReferencePhotoUrl] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [appointmentData, setAppointmentData] = useState<Appointment>();
  const [otherUserData, setOtherUserData] = useState<TMUser>();
  const [showAcceptPopUp, setShowAcceptPopUp] = useState(false);
  const [showDeclinePopUp, setShowDeclinePopUp] = useState(false);
  const [showCancelRequestPopUp, setShowCancelRequestPopUp] = useState(false);
  const [showCancelAppointmentPopUp, setShowCancelAppointmentPopUp] =
    useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [artistOrClient, setArtistOrClient] = useState<"artist" | "client">();

  useEffect(() => {
    if (appointmentData && session) {
      if (appointmentData?.artistId === session?.uid)
        setArtistOrClient("artist");
      else setArtistOrClient("client");
    }
  }, [appointmentData]);

  const clientData = () => {
    return artistOrClient === "artist"
      ? otherUserData
      : artistOrClient === "client"
      ? userData
      : undefined;
  };

  const artistData = () =>
    artistOrClient === "artist"
      ? userData
      : artistOrClient === "client"
      ? otherUserData
      : undefined;

  const loadData = async (apptId: string) => {
    try {
      const apptData = await fetchAppointment(apptId);
      setAppointmentData(apptData);
      const results = await Promise.all([
        fetchUser(
          apptData.clientId === session?.uid
            ? apptData.artistId
            : apptData.clientId
        ),
        ...(apptData.apptType === "Flash" && apptData.flashId
          ? [fetchFlashPost(apptData.flashId, apptData.artistId)]
          : []),
      ]);
      if (apptData.apptType === "Flash" && apptData.flashId)
        setSelectedFlash(results[1]);
      setOtherUserData(results[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (appointmentId) loadData(appointmentId);
  }, [showCancelRequestPopUp, appointmentId]);

  useEffect(() => {
    if (selectedFlash) {
      fetchImageUrl(selectedFlash?.mainImageUrl).then((url) => {
        setSelectedFlashUrl(url);
      });
    }
  }, [selectedFlash]);

  useEffect(() => {
    if (appointmentData && appointmentData.imgLocation) {
      fetchImageUrl(appointmentData.imgLocation).then((url) => {
        setReferencePhotoUrl(url);
      });
    }
  }, [appointmentData]);

  const handleAccept = () => {
    setShowAcceptPopUp(true);
  };

  const handleDecline = () => {
    setShowDeclinePopUp(true);
  };

  const handleCancelRequest = () => {
    setShowCancelRequestPopUp(true);
  };

  if (loadingData) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }

  if (otherUserData && appointmentData) {
    return (
      <Layout
        showPopUp={
          showAcceptPopUp ||
          showDeclinePopUp ||
          showCancelRequestPopUp ||
          showCancelAppointmentPopUp
        }
        popUpComponent={
          showAcceptPopUp ? (
            <AcceptPopUp
              clientData={clientData() || ({} as TMUser)}
              appointmentData={appointmentData}
              handleExit={() => {
                setShowAcceptPopUp(false);
              }}
            ></AcceptPopUp>
          ) : showDeclinePopUp ? (
            <DeclinePopUp
              clientData={clientData() || ({} as TMUser)}
              appointmentData={appointmentData}
              handleExit={() => {
                setShowDeclinePopUp(false);
              }}
            ></DeclinePopUp>
          ) : showCancelRequestPopUp ? (
            <CancelRequestPopUp
              handleExit={() => {
                setShowCancelRequestPopUp(false);
              }}
              appointmentData={appointmentData}
            ></CancelRequestPopUp>
          ) : showCancelAppointmentPopUp ? (
            <CancelRequestPopUp
              handleExit={() => {
                setShowCancelAppointmentPopUp(false);
              }}
              appointmentData={appointmentData}
              appointment
            ></CancelRequestPopUp>
          ) : null
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.bodyWrapper}>
            <div className={styles.leftWrapper}>
              <h3 className={styles.rowLabel}>appointment type</h3>
              <div className={styles.radioWrapper}>
                {appointmentData?.apptType}
              </div>
              <h5 className="mb10">
                {appointmentData.apptType === "Flash"
                  ? "Selected Flash"
                  : "Reference Photo"}
              </h5>
              <div className={styles.selectedFlashWrapper}>
                {selectedFlashUrl ? (
                  <img
                    src={selectedFlashUrl}
                    className={styles.selectedFlashImage}
                  />
                ) : referencePhotoUrl ? (
                  <img
                    src={referencePhotoUrl}
                    className={styles.selectedFlashImage}
                  />
                ) : (
                  <div>No Image</div>
                )}
              </div>
            </div>
            <div className={styles.rightWrapper}>
              <Row>
                <h3 className={styles.rowLabel}>appointment status</h3>
                {appointmentData ? (
                  <AppointmentStatus status={appointmentData.status} />
                ) : null}
              </Row>
              <Row>
                <h3 className={styles.rowLabel}>artist</h3>
                <div className={styles.artistDetails}>
                  <ProfileImage
                    imgLocation={artistData()?.imgLocation}
                    size="xxs"
                    style={{ marginRight: "10px" }}
                  />
                  <div>
                    <h4>
                      {artistData()?.firstName} {artistData()?.lastName}
                    </h4>
                    <div>@{artistData()?.username}</div>
                  </div>
                </div>
              </Row>
              <Row>
                <h3 className={styles.rowLabel}>client</h3>
                <div className={styles.artistDetails}>
                  <ProfileImage
                    imgLocation={clientData()?.imgLocation}
                    size="xxs"
                    style={{ marginRight: "10px" }}
                  />
                  <div>
                    <h4>
                      {clientData()?.firstName} {clientData()?.lastName}
                    </h4>
                    <div>@{clientData()?.username}</div>
                  </div>
                </div>
              </Row>
              <Row>
                <h3 className={styles.rowLabel}>date & time</h3>
                {appointmentData ? (
                  <div className={styles.dateAndTimeWrapper}>
                    <div style={{ marginRight: "10px" }}>
                      <h5>{`${parseInt(appointmentData.startTime.hour)}:${
                        appointmentData.startTime.minute
                      } ${appointmentData.startTime.ampm}`}</h5>
                      <div>{`${
                        months[appointmentData.date.getMonth()]
                      } ${appointmentData.date.getDate()}, ${appointmentData.date.getFullYear()}`}</div>
                    </div>
                  </div>
                ) : null}
              </Row>
              <Row>
                <h3 className={styles.rowLabel}>location</h3>
                {appointmentData ? (
                  <div>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ marginRight: "5px" }}
                    />
                    {appointmentData.location.text.mainText}{" "}
                    {appointmentData.location.text.secondaryText}
                  </div>
                ) : (
                  <div>N/A</div>
                )}
              </Row>
              <Row>
                <h3 className={styles.rowLabel}>message</h3>
                {appointmentData?.message ? (
                  <div>{appointmentData?.message}</div>
                ) : (
                  <div style={{ fontStyle: "italic" }}>None</div>
                )}
              </Row>
              {appointmentData.status === "accepted" &&
              artistOrClient === "artist" ? (
                <Button
                  onClick={() => {
                    setShowCancelAppointmentPopUp(true);
                  }}
                  color="red"
                >
                  Cancel Appointment
                </Button>
              ) : appointmentData.status === "pending" &&
                artistOrClient === "artist" ? (
                <Fragment>
                  <Button onClick={handleAccept} loading={submitting}>
                    Accept
                  </Button>
                  <Button
                    onClick={handleDecline}
                    loading={submitting}
                    color="red"
                  >
                    Decline
                  </Button>
                </Fragment>
              ) : appointmentData.status === "pending" &&
                artistOrClient === "client" ? (
                <Fragment>
                  <Button onClick={handleCancelRequest}>
                    Cancel Appointment Request
                  </Button>
                </Fragment>
              ) : appointmentData.status === "accepted" &&
                artistOrClient === "client" ? (
                <Fragment>
                  <Button href={`/pay-deposit/${appointmentData.docId}`}>
                    Pay Deposit
                  </Button>
                </Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <div>Error</div>
      </Layout>
    );
  }
};

export default AppointmentDetail;
