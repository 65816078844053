import { useEffect, useState, MouseEvent, useContext } from "react";
import { FlashPost, TMUser } from "../../types";
import { fetchFlashPost, fetchUser } from "../../utils";

import PostCard, { PostCardPlaceholder } from "../PostCard/PostCard";

type Props = {
  flashId: string;
  artistId: string;
  data?: FlashPost;
  showArtistLabel?: boolean;
  size?: string;
  onChangeLike?: () => void;
  style?: React.CSSProperties;
  onShowAuthPopUp?: () => void;
};

const FlashCard = (props: Props) => {
  const [flashData, setFlashData] = useState<FlashPost | null>(
    props.data || null
  );
  const [artistData, setArtistData] = useState<TMUser>();

  const getArtistData = async (artistId: string) => {
    const data = await fetchUser(artistId);
    setArtistData(data);
  };

  useEffect(() => {
    if (!flashData) {
      fetchFlashPost(props.flashId, props.artistId).then((data) => {
        setFlashData(data);
      });
    }
    getArtistData(props.artistId);
  }, []);

  if (artistData && flashData) {
    return (
      <PostCard
        data={flashData}
        artistData={artistData}
        showArtistLabel={props.showArtistLabel}
        onChangeLike={props.onChangeLike}
        onShowAuthPopUp={props.onShowAuthPopUp}
        type="flash"
        size={props.size}
        style={props.style}
      />
    );
  }

  // Loading/Placeholder Sqaure
  return (
    <PostCardPlaceholder
      size={props.size}
      showArtistLabel={props.showArtistLabel}
      style={props.style}
    />
  );
};

export default FlashCard;
