import { Fragment, useContext, useEffect, useState } from "react";
import { TMUserContext, UserContext } from "../../../../App";
import { MyUser, UserSettings } from "../../../../types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchUserSettings } from "../../../../utils";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../../../services/firebase/firebase";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  updatePassword,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

import styles from "../../settingsStyles.module.css";
import Spinner from "../../../../components/Spinner/Spinner";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import FormLabel from "../../../../components/FormLabel/FormLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { PasswordInput } from "../../../../components/Auth/Auth";
import ArtistOnboarding from "../ArtistOnboarding/ArtistOnboarding";

const NewPassword = (props: { onSuccess: () => void }) => {
  const session = useContext(UserContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [updating, setUpdating] = useState(false);

  const handleUpdatePassword = () => {
    if (session && session.email) {
      setUpdating(true);
      const authCredential = EmailAuthProvider.credential(
        session.email,
        currentPassword
      );
      reauthenticateWithCredential(session, authCredential)
        .then((userCredential) => {
          updatePassword(session, newPassword)
            .then(() => {
              props.onSuccess();
            })
            .catch((err) => {
              setUpdateError(String(err));
            })
            .finally(() => {
              setUpdating(false);
            });
        })
        .catch((err) => {
          setUpdateError(String(err));
          setUpdating(false);
        });
    }
  };
  return (
    <div>
      <FormLabel>Current Password</FormLabel>
      <PasswordInput
        value={currentPassword}
        onChange={(v) => {
          setCurrentPassword(v);
        }}
        className="mb10"
      ></PasswordInput>
      <FormLabel>New Password</FormLabel>
      <PasswordInput
        value={newPassword}
        onChange={(v) => {
          setNewPassword(v);
        }}
        className="mb10"
      ></PasswordInput>
      <Button
        color="gray"
        onClick={handleUpdatePassword}
        className="mb20"
        loading={updating}
      >
        Update Password
      </Button>
      {updateError && <div>{updateError}</div>}
    </div>
  );
};

const ManageMembership = () => {
  const { userData, setUserData } = useContext(TMUserContext);

  return (
    <div>
      <h2>Manage Membership</h2>
      <div></div>
    </div>
  );
};

const AccountSettings = () => {
  const session = useContext(UserContext);
  const { userData, setUserData } = useContext(TMUserContext);
  const [userSettings, setUserSettings] = useState<UserSettings>();
  const [pronouns, setPronouns] = useState<string>();
  const [sendingVerification, setSendingVerification] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationError, setVerificationError] = useState<string>();
  const [newPassword, setNewPassword] = useState(false);
  const navigate = useNavigate();
  const { subpage } = useParams();

  useEffect(() => {
    if (session) {
      fetchUserSettings(session.uid)
        .then((s) => {
          setUserSettings(s);
        })
        .catch((err) => {
          console.log(err);
        });
      const getUserData = httpsCallable(functions, "getUserData");
      getUserData()
        .then((results) => {
          const u = results.data as MyUser;
          setUserData(u);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (userSettings) {
      setPronouns(userSettings.pronouns);
    }
  }, [userSettings]);

  const handleResendVerification = async () => {
    if (session) {
      setSendingVerification(true);
      try {
        await sendEmailVerification(session);
        setVerificationSent(true);
      } catch (err) {
        let message;
        if (err instanceof Error) message = err.message;
        else message = String(err);
        setVerificationError(message);
      }
      setSendingVerification(false);
    }
  };

  const handleSaveChanges = async () => {
    if (session) {
      const docRef = doc(db, "userSettings", session.uid);
      await setDoc(docRef, { pronouns: pronouns }, { merge: true });
      const newUserSettings = await fetchUserSettings(session.uid);
      setUserSettings(newUserSettings);
    }
  };

  if (userData) {
    if (subpage && subpage === "manage-membership") {
      return <ManageMembership />;
    }
    if (subpage && subpage === "artist-onboarding") {
      return <ArtistOnboarding />;
    }
    return (
      <div>
        <h2>Account Settings</h2>
        <p className={[styles.description, "mb20"].join(" ")}>
          Make changes to your personal information or account type.
        </p>
        <h4 className="mb5">Email Address</h4>
        <p className="mb10">
          {session?.email}{" "}
          {session?.emailVerified ? (
            <span className={[styles.emailVerified, "mb20"].join(" ")}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ margin: "0 5px", color: "rgb(0,193,0)" }}
              />
              <span style={{ color: "rgb(100,100,100)" }}>Verified</span>
            </span>
          ) : (
            <span className={[styles.emailVerified, "mb20"].join(" ")}>
              <FontAwesomeIcon
                icon={faXmarkCircle}
                style={{ color: "red", margin: "0 5px" }}
              />
              <span style={{ color: "rgb(100,100,100)" }}>Not Verified</span>
            </span>
          )}
        </p>

        {verificationSent ? (
          <div className="mb20">
            Verification email sent to {session?.email}.
          </div>
        ) : (
          !session?.emailVerified && (
            <div className="mb20">
              <Button
                color="gray"
                onClick={handleResendVerification}
                disabled={sendingVerification}
              >
                Resend Verification Email
              </Button>
              {verificationError && (
                <div style={{ color: "red" }}>{verificationError}</div>
              )}
            </div>
          )
        )}

        <h4 className="mb5">Password</h4>
        {newPassword ? (
          <NewPassword
            onSuccess={() => {
              setNewPassword(false);
            }}
          ></NewPassword>
        ) : (
          <Button
            className={"mb20"}
            color="gray"
            onClick={() => {
              setNewPassword(true);
            }}
          >
            Change Password
          </Button>
        )}
        <div className="mb40">
          {userData?.accountType === "client" ? (
            <Fragment>
              <h4 className={"mb10"}>Convert to an artist account</h4>
              <div className={["flexRowCenter", "mb20"].join(" ")}>
                <div style={{ maxWidth: "300px" }}>
                  Artist accounts have additional features meant for practicing
                  tattoo artists or artists who sell tattoo designs.
                </div>
                <Button href="/onboarding/studio-location">
                  Convert account
                </Button>
              </div>
            </Fragment>
          ) : userData?.isProUser ? (
            <Fragment>
              <h4 className="mb5">Account Type</h4>
              <div className="mb20">Artist - Pro Account</div>
              <Button href="/settings/account/manage-membership" color="gray">
                Manage Membership
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <h4 className="mb5">Account Type</h4>
              <div className="mb20">Artist Account - Free Tier</div>

              <Button
                className="mb20"
                onClick={() => {
                  navigate("/pro-account-dashboard");
                }}
              >
                Upgrade to Pro
              </Button>
              <h5>
                Pro accounts have additional features to help professional
                artists monetize their businesses.
              </h5>
            </Fragment>
          )}
        </div>

        <h4 className={"mb10"}>Personal Information</h4>
        <FormLabel>Pronouns</FormLabel>
        <Input
          type="text"
          value={pronouns || ""}
          onChange={(v) => {
            setPronouns(v);
          }}
          className="mb20"
        ></Input>
        <Button
          onClick={handleSaveChanges}
          disabled={pronouns === userSettings?.pronouns}
        >
          Save Changes
        </Button>
      </div>
    );
  }

  return <Spinner />;
};

export default AccountSettings;
