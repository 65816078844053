import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button/Button";
import styles from "./selectionBubbleStyles.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

type SelectionBubbleProps = {
  children?: React.ReactNode;
  onRemove?: () => void;
  readonly?: boolean;
};

const SelectionBubble = (props: SelectionBubbleProps) => {
  return (
    <div className={styles.bubble}>
      {props.children}
      {!props.readonly && (
        <Button
          noStyling
          onClick={() => {
            if (props.onRemove) props.onRemove();
          }}
          style={{ marginLeft: "10px" }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      )}
    </div>
  );
};

export default SelectionBubble;
