import { ChangeEvent } from "react";
import styles from "./inputStyles.module.css";

type InputProps = {
  label?: string;
  value: string;
  round?: boolean;
  onChange: (v: string) => void;
  style?: React.CSSProperties;
  id?: string;
  hideValue?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: boolean;
  readonly?: boolean;
  type?:
    | "button"
    | "checkbox"
    | "text"
    | "password"
    | "submit"
    | "email"
    | "number"
    | "tel";
  autoComplete?: string;
  className?: string;
};

const Input = (props: InputProps) => {
  return (
    <input
      autoComplete={props.autoComplete}
      id={props.id}
      value={props.value}
      type={props.type || "text"}
      className={[
        styles.input,
        props.error && styles.error,
        props.className || null,
        props.round && styles.round,
      ].join(" ")}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (!props.readonly) props.onChange(e.currentTarget.value);
      }}
      disabled={props.disabled}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      readOnly={props.readonly}
      style={props.style}
    ></input>
  );
};

export default Input;
