import { Fragment, useContext, useState } from "react";
import NewLocationInput from "../../../../components/NewLocationInput/NewLocationInput";
import { StudioLocation } from "../../../../types";
import Button from "../../../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faCheck,
  faCheckCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./artistOnboardingStyles.module.css";
import { Checkout } from "../../../CheckoutPage/CheckoutPage";
import { doc, setDoc } from "firebase/firestore";
import { UserContext } from "../../../../App";
import { db } from "../../../../services/firebase/firebase";
import { useNavigate } from "react-router-dom";

export type ArtistMembership = "free" | "pro";

type MembershipRadioProps = {
  type: ArtistMembership;
  onClick: () => void;
  selected: boolean;
  className?: string;
};

export const MembershipRadio = (props: MembershipRadioProps) => {
  const sharedFeatures = [
    "Professional portfolio page",
    "Post flash & tattoos",
    "Appear in location-based searches",
    "Book & manage appointments",
  ];

  const proFeatures = [
    "Collect payments",
    "Require deposits with booking requests",
    "Sell Flash Tickets",
  ];

  const data = {
    free: {
      title: "Free Tier",
      included: [...sharedFeatures],
      excluded: [...proFeatures],
      price: "$0 / month",
    },
    pro: {
      title: "TatMap Pro",
      included: [...sharedFeatures, ...proFeatures],
      excluded: [],
      price: "$10 / month",
    },
  };

  return (
    <GenericMembershipRadio
      className={props.className}
      title={data[props.type].title}
      price={data[props.type].price}
      included={data[props.type].included}
      excluded={data[props.type].excluded}
      onClick={props.onClick}
      selected={props.selected}
    />
  );
};

type GenericMembershipRadioProps = {
  title: string;
  price: string;
  included: string[];
  excluded?: string[];
  selected?: boolean;
  onClick: () => void;
  className?: string;
};

const GenericMembershipRadio = (props: GenericMembershipRadioProps) => {
  return (
    <button
      className={[
        styles.membershipRadio,
        props.selected && styles.selected,
        props.className,
      ].join(" ")}
      onClick={props.onClick}
    >
      <FontAwesomeIcon className={styles.selectedIcon} icon={faCheckCircle} />
      <h5>{props.title}</h5>
      <p>{props.price}</p>
      <ul className={styles.featuresList}>
        {props.included.map((feature) => {
          return (
            <li key={feature}>
              <FontAwesomeIcon
                className={[styles.checkIcon, "mr5"].join(" ")}
                icon={faCheck}
              />
              {feature}
            </li>
          );
        })}
        {props.excluded?.map((feature) => {
          return (
            <li key={feature} className={styles.excluded}>
              <FontAwesomeIcon
                className={[styles.xIcon, "mr5"].join(" ")}
                icon={faXmark}
              />
              {feature}
            </li>
          );
        })}
      </ul>
    </button>
  );
};

const ArtistOnboarding = () => {
  const session = useContext(UserContext);
  const [studio, setStudio] = useState<StudioLocation>();
  const [step, setStep] = useState(1);
  const [tier, setTier] = useState<"free" | "pro" | undefined>();
  const [savingChanges, setSavingChanges] = useState(false);
  const navigate = useNavigate();

  const handleSaveUserChanges = async (stu: StudioLocation) => {
    setSavingChanges(true);
    const userDoc = setDoc(
      doc(db, `users/${session?.uid}`),
      {
        accountType: "artist",
        searchLocation: {
          googleId: stu.googleId,
          lat: stu.location.latitude,
          lng: stu.location.longitude,
        },
      },
      { merge: true }
    );
    const studioDoc = setDoc(
      doc(db, `users/${session?.uid}/studioLocations/${stu.googleId}`),
      { ...stu, isPrimary: true }
    );
    await Promise.all([userDoc, studioDoc]);
    setSavingChanges(false);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Fragment>
            <h4>
              Enter your studio location. You can always change this later.
            </h4>
            <div>
              <NewLocationInput
                className="mb40"
                onSelect={(l) => {
                  setStudio(l);
                }}
                value={studio}
                types={["establishment", "street_address", "premise", "store"]}
                placeholder="Enter Address or Studio Name"
              />
            </div>
            {studio && (
              <div>
                <Button
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Next: Membership Plan{" "}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </Button>
              </div>
            )}
          </Fragment>
        );
      case 2:
        return (
          <div>
            <h4 className="textCenter">Choose your plan</h4>
            <div className={[styles.radioWrapper, "mb40"].join(" ")}>
              <MembershipRadio
                className="m5"
                type="free"
                onClick={() => {
                  setTier("free");
                }}
                selected={tier === "free"}
              />
              <MembershipRadio
                className="m5"
                type="pro"
                onClick={() => {
                  setTier("pro");
                }}
                selected={tier === "pro"}
              />
            </div>
            {tier && (
              <div>
                <Button
                  loading={savingChanges}
                  onClick={async () => {
                    if (studio) {
                      if (tier === "free") {
                        await handleSaveUserChanges(studio);
                        setStep(3);
                      }
                      if (tier === "pro") {
                        await handleSaveUserChanges(studio);
                        navigate("/checkout");
                      }
                    }
                  }}
                >
                  Next: {tier === "free" && "Finish"}
                  {tier === "pro" && "Payment"}{" "}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </Button>
              </div>
            )}
          </div>
        );
      case 3:
        return <Checkout />;
    }
    return null;
  };

  return (
    <div>
      <h2 className="mb40">Convert to Artist Account</h2>
      {renderStep()}
    </div>
  );
};

export default ArtistOnboarding;
