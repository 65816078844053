import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useEffect, useState } from "react";
import { fetchFlashPost, fetchUserByUsername } from "../../utils";
import { TMUser, TattooPost } from "../../types";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../services/firebase/firebase";
import Spinner from "../../components/Spinner/Spinner";
import PostDetail from "../../components/PostDetail/PostDetail";

const FlashDetail = () => {
  const { artistId, docId } = useParams();
  const [postData, setPostData] = useState<TattooPost>();
  const [artistData, setArtistData] = useState<TMUser>();
  const [imgUrl, setImgUrl] = useState<string>();
  const [loadingData, setLoadingData] = useState(true);

  const getData = async (idArtist: string, idFlash: string) => {
    const artist = await fetchUserByUsername(idArtist);
    setArtistData(artist);
    const flash = await fetchFlashPost(idFlash, artist.docId);
    setPostData(flash);
    const url = await getDownloadURL(ref(storage, flash.mainImageUrl));
    setImgUrl(url);
    setLoadingData(false);
  };

  useEffect(() => {
    if (docId && artistId) {
      getData(artistId, docId);
    }
  }, []);

  if (loadingData) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }

  if (postData && artistData && imgUrl) {
    return (
      <PostDetail
        data={postData}
        artistData={artistData}
        loadingData={loadingData}
        imgUrl={imgUrl}
        type="flash"
      />
    );
  }
  return <div>Error</div>;
};

export default FlashDetail;
