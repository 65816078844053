import { Fragment, useContext, useEffect, useState } from "react";
import { MessageThread, TMUser } from "../../types";
import MessageThreadDetail from "../MessageThreadDetail/MessageThreadDetail";
import Anchor from "../Anchor/Anchor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import MessageThreadPreview from "../MessageThreadPreview/MessageThreadPreview";
import styles from "./messagesStyles.module.css";
import Button from "../Button/Button";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Input from "../Input/Input";
import {
  searchUsersByUsername,
  messageThreadConverter,
  fetchMessageThread,
} from "../../utils";
import ProfileImage from "../ProfileImage/ProfileImage";
import Spinner from "../Spinner/Spinner";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import { UserContext } from "../../App";

type UserButtonProps = {
  data: TMUser;
  onClick: () => void;
};

const UserButton = (props: UserButtonProps) => {
  const handleClick = () => {
    props.onClick();
  };
  return (
    <button
      key={props.data.docId}
      className={styles.userButton}
      onClick={handleClick}
    >
      <ProfileImage
        imgLocation={props.data.imgLocation}
        size="xs"
        style={{ marginRight: "5px" }}
      />
      <div>
        <div>
          {props.data.firstName} {props.data.lastName}
        </div>
        <div>@{props.data.username}</div>
      </div>
    </button>
  );
};

type NewMessageProps = {
  onExit: () => void;
};

const NewMessage = (props: NewMessageProps) => {
  const session = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [suggestedUsers, setSuggestedUsers] = useState<TMUser[]>();
  const [messageThread, setMessageThread] = useState<MessageThread>();
  const [loadingMessageThread, setLoadingMessageThread] = useState(false);

  useEffect(() => {
    searchUsersByUsername(username.toLowerCase().replace("@", ""))
      .then((data) => {
        setSuggestedUsers(data);
      })
      .catch((err) => {
        setSuggestedUsers(undefined);
      });
  }, [username]);

  const createThread = (uid1: string, uid2: string) => {
    const threadRef = doc(db, "users", uid1, "messageThreads", uid2);
    const data = {
      unreadMessages: 0,
      lastMessage: "",
      lastSender: uid1,
      updatedAt: new Date(),
      docId: threadRef.id,
    };
    return data;
  };

  const handleClick = async (u: TMUser) => {
    setLoadingMessageThread(true);
    fetchMessageThread(session!.uid, u.docId)
      .then((thread) => {
        if (thread) {
          setMessageThread(thread);
        } else {
          setMessageThread(createThread(session!.uid, u.docId));
        }
      })
      .catch((err) => {
        // ...
      })
      .finally(() => {
        setLoadingMessageThread(false);
      });
  };

  if (loadingMessageThread) {
    return <Spinner />;
  }

  if (messageThread) {
    return (
      <MessageThreadDetail
        data={messageThread}
        onExit={() => {
          setMessageThread(undefined);
        }}
        loadMessageThreads={() => {}}
      />
    );
  }

  return (
    <div>
      <div className={styles.headerWrapper}>
        <Button
          onClick={props.onExit}
          circle
          color="white"
          style={{ fontSize: "20px", marginRight: "10px" }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <div className={styles.nameWrapper}>
          <h4>New Message</h4>
        </div>
      </div>
      <div className={styles.bodyWrapper}>
        <Input
          type="text"
          value={username}
          onChange={(v) => {
            setUsername(v);
          }}
          placeholder="Enter Username"
          round
        />
        <div className={styles.suggestedUsersWrapper}>
          <h5 style={{ margin: "10px 0" }}>Suggested Users</h5>
          {suggestedUsers ? (
            suggestedUsers.map((u) => (
              <UserButton
                key={u.docId}
                data={u}
                onClick={() => {
                  handleClick(u);
                }}
              />
            ))
          ) : (
            <div>No Users Found</div>
          )}
        </div>
      </div>
    </div>
  );
};

type MessagesProps = {
  threads: MessageThread[];
  reloadThreads: () => void;
};

const Messages = (props: MessagesProps) => {
  const [currentThread, setCurrentThread] = useState<MessageThread | null>(
    null
  );
  const [newMessage, setNewMessage] = useState(false);
  return (
    <Fragment>
      {newMessage ? (
        <NewMessage
          onExit={() => {
            setNewMessage(false);
            props.reloadThreads();
          }}
        />
      ) : currentThread ? (
        <MessageThreadDetail
          data={currentThread}
          onExit={() => {
            setCurrentThread(null);
            props.reloadThreads();
          }}
          loadMessageThreads={() => {
            props.reloadThreads();
          }}
        />
      ) : (
        <Fragment>
          <h4 style={{ textAlign: "center", margin: "15px 0" }}>Inbox</h4>
          <Anchor
            className={styles.newMessage}
            onClick={() => {
              setNewMessage(true);
            }}
          >
            <div className={styles.newMessageIcon}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
            <div className={styles.newMessageText}>New Message</div>
          </Anchor>
          <h5 style={{ marginTop: "20px" }}>Messages</h5>
          {props.threads.length > 0 ? (
            props.threads.map((d) => (
              <MessageThreadPreview
                key={d.docId}
                data={d}
                onClick={() => {
                  setCurrentThread(d);
                }}
              />
            ))
          ) : (
            <div className={styles.nothingHere}>No Messages Yet</div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Messages;
