import { Link } from "react-router-dom";
import styles from "./buttonStyles.module.css";

export type ButtonColorOptions = "gray" | "white" | "red" | "blue";

type ButtonProps = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  href?: string;
  style?: React.CSSProperties;
  color?: ButtonColorOptions;
  round?: boolean;
  circle?: boolean;
  circleSmall?: boolean;
  loading?: boolean;
  noStyling?: boolean;
  disabled?: boolean;
  className?: string;
  thin?: boolean;
  type?: "button" | "submit" | "reset";
};

const Button = (props: ButtonProps) => {
  const className = [
    props.noStyling ? styles.noStyle : styles.button,
    props.color ? styles[props.color] : null,
    props.round ? styles.round : null,
    props.circle ? styles.circle : null,
    props.circleSmall ? styles.circleSmall : null,
    props.disabled ? styles.disabled : null,
    props.className ? props.className : null,
    props.thin ? styles.thin : null,
  ].join(" ");

  if (props.href) {
    return (
      <Link
        className={className}
        to={props.disabled ? "" : props.href}
        style={props.style}
      >
        {props.loading ? "..." : props.children}
      </Link>
    );
  }
  return (
    <button
      type={props.type}
      onClick={props.disabled || !props.onClick ? () => {} : props.onClick}
      className={className}
      style={props.style}
      disabled={props.loading}
    >
      {props.loading ? "..." : props.children}
    </button>
  );
};

export default Button;
