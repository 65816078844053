import { Fragment } from "react/jsx-runtime";
import { Appointment, TMNotification } from "../../types";
import styles from "./myNotificationStyles.module.css";
import { useContext, useEffect, useState } from "react";
import { fetchImageUrl } from "../../utils";
import Anchor from "../Anchor/Anchor";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import { UserContext } from "../../App";

type NotificationHeaderProps = {
  type: string;
};

const NotificationHeader = (props: NotificationHeaderProps) => {
  return <h5></h5>;
};

type NewAppointmentRequestProps = {
  appointmentData: Appointment;
};

const NewAppointmentRequest = (props: NewAppointmentRequestProps) => {
  return (
    <Fragment>
      <h5>New appointment request!</h5>
    </Fragment>
  );
};

type MyNotificationProps = {
  data: TMNotification;
};

const MyNotification = (props: MyNotificationProps) => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [imgUrl, setImgUrl] = useState("");

  const markAsSeen = async () => {
    if (user) {
      const docRef = doc(
        db,
        "users",
        user.uid,
        "notifications",
        props.data.docId
      );
      await setDoc(docRef, { viewed: true }, { merge: true });
    }
  };

  useEffect(() => {
    fetchImageUrl(props.data.imgLocation).then((url) => {
      setImgUrl(url);
    });
    if (props.data.viewed === false) {
      markAsSeen();
    }
  }, []);

  return (
    <Anchor
      onClick={() => {
        switch (props.data.type) {
          case "Appointment Request":
            navigate(`/appointment/${props.data.appointmentId}`);
            break;
          case "Appointment Accepted":
            navigate(`/appointment/${props.data.appointmentId}`);
            break;
          case "appointment":
            navigate(`/appointment/${props.data.appointmentId}`);
            break;
          default:
            navigate("/home");
        }
      }}
      className={styles.wrapper}
    >
      <img src={imgUrl} className={styles.image} />
      <div className={styles.text}>
        {props.data.text}
        <div className={styles.createdAt}>
          {props.data.createdAt.getMonth() + 1}/{props.data.createdAt.getDate()}
          /{props.data.createdAt.getFullYear()}
        </div>
      </div>
    </Anchor>
  );
};

export default MyNotification;
