import { ChangeEvent } from "react";
import styles from "./textAreaStyles.module.css";

type TextAreaProps = {
  value: string;
  onChange: (v: string) => void;
  className?: string;
};

const TextArea = (props: TextAreaProps) => {
  return (
    <textarea
      className={[styles.textarea, props.className].join(" ")}
      value={props.value}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        props.onChange(e.currentTarget.value);
      }}
    ></textarea>
  );
};

export default TextArea;
