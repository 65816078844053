import { Fragment, useContext, useEffect, useState } from "react";
import { TMUserContext, UserContext } from "../../../../App";
import { ref, uploadBytes } from "firebase/storage";
import { db, functions, storage } from "../../../../services/firebase/firebase";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { MyUser } from "../../../../types";
import styles from "../../settingsStyles.module.css";
import FormLabel from "../../../../components/FormLabel/FormLabel";
import TextArea from "../../../../components/TextArea/TextArea";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import { UsernameInput } from "../../../../components/Auth/Auth";
import ProfileImage from "../../../../components/ProfileImage/ProfileImage";
import FileUpload from "../../../../components/FileUpload/FileUpload";

const EditProfile = () => {
  const session = useContext(UserContext);
  const { userData, setUserData } = useContext(TMUserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(true);
  const [usernameIsValid, setUsernameIsValid] = useState(true);
  const [usernameIsChecking, setUsernameIsChecking] = useState(false);
  const [imgLocation, setImgLocation] = useState("");
  const [about, setAbout] = useState("");
  const [newImg, setNewImg] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const changesExist = () => {
    if (firstName !== userData?.firstName) return true;
    if (lastName !== userData?.lastName) return true;
    if (username !== userData?.username) return true;
    if (imgLocation !== userData?.imgLocation) return true;
    if (about !== userData?.about) return true;
    if (newImg) return true;
    return false;
  };

  const usernameIsInvalid = () => {
    if (username === userData?.username) return false;
    if (!usernameIsAvailable) return true;
    if (!usernameIsValid) return true;
    if (usernameIsChecking) return true;
    return false;
  };

  const saveChanges = async () => {
    setIsSaving(true);
    if (session) {
      if (newImg) {
        const location = `${session.uid}/profilepic/pic`;
        try {
          const storageRef = ref(storage, location);
          await uploadBytes(storageRef, newImg);
          await setDoc(
            doc(db, "users", session.uid),
            {
              firstName,
              lastName,
              username,
              about,
              imgLocation: location,
            },
            { merge: true }
          );
        } catch (e) {
          console.log(e);
        } finally {
          setIsSaving(false);
        }
      } else {
        try {
          await setDoc(
            doc(db, "users", session.uid),
            {
              firstName,
              lastName,
              username,
              about,
            },
            { merge: true }
          );
        } catch (e) {
          console.error("Error adding document: ", e);
        } finally {
          setIsSaving(false);
        }
      }
      const getUserData = httpsCallable(functions, "getUserData");
      const results = await getUserData();
      const u = results.data as MyUser;
      setUserData(u || null);
    }
  };

  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setUsername(userData.username);
      setImgLocation(userData.imgLocation);
      setAbout(userData.about || "");
    }
  }, [userData]);

  return (
    <Fragment>
      <div className={styles.header}>
        <h2>Edit Profile</h2>
        <p className={styles.description}>
          This is the information that will appear publicly on your profile.
        </p>
      </div>
      <div style={{ marginBottom: "15px" }}>
        <FormLabel>Photo</FormLabel>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProfileImage
            imgUrl={newImg ? undefined : userData?.imageURL}
            imgFile={newImg || undefined}
            size="m"
            style={{ marginRight: "15px" }}
          />
          <FileUpload
            setFile={(f) => {
              setNewImg(f);
            }}
          >
            Change
          </FileUpload>
        </div>
      </div>
      <div className={styles.row}>
        <FormLabel>First Name</FormLabel>
        <Input
          value={firstName || ""}
          onChange={(v) => {
            setFirstName(v);
          }}
        />
      </div>
      <div className={styles.row}>
        <FormLabel>Last Name</FormLabel>
        <Input
          value={lastName || ""}
          onChange={(v) => {
            setLastName(v);
          }}
        />
      </div>
      <div className={styles.row}>
        <FormLabel>Username</FormLabel>
        <UsernameInput
          value={username || ""}
          onChange={(v) => {
            setUsername(v);
          }}
          hideInfo={username === userData?.username}
          onAvailabilityChange={(v) => {
            setUsernameIsAvailable(v);
          }}
          onValidityChange={(v) => {
            setUsernameIsValid(v);
          }}
          onCheckingChange={(v) => {
            setUsernameIsChecking(v);
          }}
        />
      </div>
      <div className={styles.row}>
        <FormLabel>About</FormLabel>
        <TextArea
          value={about || ""}
          onChange={(v: string) => {
            if (v.length < 300) setAbout(v);
          }}
        ></TextArea>
      </div>
      <Button
        loading={isSaving}
        disabled={!changesExist() || usernameIsInvalid()}
        onClick={() => {
          saveChanges();
        }}
      >
        Save Changes
      </Button>
    </Fragment>
  );
};

export default EditProfile;
