import { getDownloadURL, ref } from "firebase/storage";
import {
  useEffect,
  useState,
  MouseEvent,
  useContext,
  CSSProperties,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { storage } from "../../services/firebase/firebase";
import { FlashPost, TMUser, TattooPost } from "../../types";
import {
  addToLikedPosts,
  postIsLiked,
  removeFromLikedPosts,
} from "../../utils";
import styles from "./postCardStyles.module.css";
import Anchor from "../Anchor/Anchor";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import ProfileImage from "../ProfileImage/ProfileImage";
import HeartIcon from "../HeartIcon/HeartIcon";
import { UserContext } from "../../App";

type Props = {
  data: FlashPost | TattooPost;
  artistData: TMUser;
  showArtistLabel?: boolean;
  size?: string;
  onChangeLike?: () => void;
  style?: React.CSSProperties;
  onShowAuthPopUp?: () => void;
  type: "flash" | "tattoo";
};

const PostCard = (props: Props) => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [imgUrl, setImgUrl] = useState<string>();
  const [liked, setLiked] = useState(false);

  const getImage = (post: FlashPost | TattooPost) => {
    getDownloadURL(ref(storage, `${post.mainImageUrl}`)).then((url) => {
      setImgUrl(url);
    });
  };

  useEffect(() => {
    getImage(props.data);
  }, [props.data]);

  useEffect(() => {
    if (user) {
      checkIfLiked(user.uid, props.data.docId);
    }
  }, [user]);

  const checkIfLiked = (uid: string, postId: string) => {
    postIsLiked(uid, postId).then((isLiked) => {
      setLiked(isLiked);
    });
  };

  const handleLike = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      if (props.onShowAuthPopUp) props.onShowAuthPopUp();
    }
    if (user) {
      if (!liked) {
        addToLikedPosts(
          user.uid,
          props.data.docId,
          props.data.artistId,
          props.type
        ).then(() => {
          setLiked(true);
          checkIfLiked(user.uid, props.data.docId);
          if (props.onChangeLike) props.onChangeLike();
        });
      } else {
        removeFromLikedPosts(user.uid, props.data.docId).then(() => {
          setLiked(false);
          checkIfLiked(user.uid, props.data.docId);
          if (props.onChangeLike) props.onChangeLike();
        });
      }
    }
  };

  return (
    <div
      className={[
        ...(props.size?.includes("responsive")
          ? [styles.responsiveWrapper, styles[props.size]]
          : [styles.fixedWrapper]),
      ].join(" ")}
      /*to={`/u/${props.artistData.username}/${props.type}/${props.data.docId}`}*/
      /*onClick={() => {
        navigate(
          `/u/${props.artistData.username}/${props.type}/${props.data.docId}`
        );
      }}*/
      style={props.style}
    >
      <Link
        to={`/u/${props.artistData.username}/${props.type}/${props.data.docId}`}
        className={[
          ...(props.size?.includes("responsive")
            ? [styles.responsiveChild]
            : [styles.fixedChild, styles[props.size || "m"]]),
        ].join(" ")}
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className={styles.overlay}>
          <div className={styles.iconsWrapper}>
            <Button
              onClick={handleLike}
              circleSmall
              color="white"
              style={{ marginRight: "5px" }}
            >
              <HeartIcon liked={liked} />
            </Button>
            <Button onClick={() => {}} circleSmall color="white">
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </div>
        </div>
      </Link>

      {props.showArtistLabel && (
        <div className={styles.artistLabelWrapper}>
          <ProfileImage
            imgLocation={props.artistData.imgLocation}
            size="xxxs"
            href={`/u/${props.artistData.username}`}
            style={{ marginRight: "3px" }}
          ></ProfileImage>
          <Link
            className={styles.artistLink}
            to={`/u/${props.artistData.username}`}
          >{`@${props.artistData.username}`}</Link>
        </div>
      )}
    </div>
  );
};

type PostCardPlaceholderProps = {
  size?: string;
  showArtistLabel?: boolean;
  style?: CSSProperties;
};

export const PostCardPlaceholder = (props: PostCardPlaceholderProps) => {
  return (
    <div
      className={[
        ...(props.size?.includes("responsive")
          ? [styles.responsiveWrapper, styles[props.size]]
          : [styles.fixedWrapper]),
      ].join(" ")}
      style={props.style}
    >
      <div
        className={[
          ...(props.size?.includes("responsive")
            ? [styles.responsiveChild]
            : [styles.fixedChild, styles[props.size || "m"]]),
        ].join(" ")}
        style={{
          backgroundColor: "pink",
        }}
      ></div>
      {props.showArtistLabel && (
        <div className={styles.artistLabelWrapper}>
          <ProfileImage
            size="xxxs"
            href={`/`}
            style={{ marginRight: "3px" }}
          ></ProfileImage>
          <div className={styles.artistLink}></div>
        </div>
      )}
    </div>
  );
};

export default PostCard;
