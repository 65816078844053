import { useEffect, useRef, useState } from "react";
import FormLabel from "../FormLabel/FormLabel";
import Input from "../Input/Input";
import styles from "./newLocationInputStyles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  AutoCompleteSuggestion,
  PlaceDetails,
  StudioLocation,
} from "../../types";
import { fetchAutocompleteSuggestions, fetchPlaceDetails } from "../../utils";
import useClickOutside from "../../hooks/useClickOutside";

type NewLocationInputProps = {
  onSelect: (place: StudioLocation | undefined) => void;
  value?: StudioLocation;
  types?: string[];
  placeholder?: string;
  label?: string;
  suggestionsType?: "absolute" | "relative";
  className?: string;
};

const NewLocationInput = (props: NewLocationInputProps) => {
  const suggestionsType = props.suggestionsType || "absolute";
  const [suggestions, setSuggestions] = useState<AutoCompleteSuggestion[]>();
  const [location, setLocation] = useState<AutoCompleteSuggestion>();
  const [placeDetails, setPlaceDetails] = useState<PlaceDetails>();
  const [placeDetailError, setPlaceDetailError] = useState<Error | null>(null);
  const [autoCompleteError, setAutoCompleteError] = useState<Error | null>(
    null
  );
  const [value, setValue] = useState("");

  const suggestionsRef = useRef(null);

  useClickOutside(suggestionsRef, () => {
    setSuggestions(undefined);
  });

  const getAndSetSuggestions = (v: string) => {
    fetchAutocompleteSuggestions(v, props.types)
      .then((results) => {
        setSuggestions(results);
      })
      .catch((err: Error) => setAutoCompleteError(err));
  };

  const handleInput = (v: string) => {
    setValue(v);
    // If the input value is not an empty string, fetch Google AutoComplete suggestions
    if (v) {
      getAndSetSuggestions(v);
    }
  };

  const handleFocus = () => {
    if (value && !suggestions) {
      getAndSetSuggestions(value);
    }
  };

  const handleSelect = (s: AutoCompleteSuggestion) => {
    setSuggestions(undefined);
    setValue(
      `${s.placePrediction.structuredFormat.mainText.text} ${s.placePrediction.structuredFormat.secondaryText?.text}`
    );
    setLocation(s);
    fetchPlaceDetails(s.placePrediction.placeId)
      .then((d) => {
        setPlaceDetails(d);
      })
      .catch((err: Error) => {
        setPlaceDetailError(err);
      });
  };

  useEffect(() => {
    if (placeDetails) {
      const localityComponent = placeDetails.addressComponents.find((c) =>
        c.types.includes("locality")
      );
      const postalCodeComponent = placeDetails.addressComponents.find((c) =>
        c.types.includes("postal_code")
      );
      const postalCodeSuffixComponent = placeDetails.addressComponents.find(
        (c) => c.types.includes("postal_code_suffix")
      );
      const administrativeAreaLevel1Component =
        placeDetails.addressComponents.find((c) =>
          c.types.includes("administrative_area_level_1")
        );
      const countryComponent = placeDetails.addressComponents.find((c) =>
        c.types.includes("country")
      );

      // Structure the data into StudioLocation format
      const structuredData = {
        location: placeDetails?.location,
        text: {
          mainText: location?.placePrediction.structuredFormat.mainText.text,
          secondaryText:
            location?.placePrediction.structuredFormat.secondaryText?.text,
        },
        googleId: placeDetails.id,
        types: placeDetails.types,
        formattedAddress: placeDetails.formattedAddress,
        shortFormattedAddress: placeDetails.shortFormattedAddress,
        addressComponents: placeDetails.addressComponents,
        utcOffsetMinutes: placeDetails.utcOffsetMinutes,
        viewport: {
          low: placeDetails.viewport.low,
          high: placeDetails.viewport.high,
        },
        googleMapsUri: placeDetails.googleMapsUri,
        // Only insert optional properties into the object if they are defined, thereby preventing errors when inserting the object into the datastore
        ...(localityComponent?.longText && {
          locality: localityComponent.longText,
        }),
        ...(postalCodeComponent && {
          postalCode: parseInt(postalCodeComponent.longText),
        }),
        ...(postalCodeSuffixComponent && {
          postalCodeSuffix: parseInt(postalCodeSuffixComponent.longText),
        }),
        ...(administrativeAreaLevel1Component && {
          administrativeAreaLevel1: administrativeAreaLevel1Component,
        }),
        ...(countryComponent && { country: countryComponent }),
      } as StudioLocation;
      props.onSelect(structuredData);
    }
  }, [placeDetails]);

  return (
    <div className={[styles.wrapper, props.className].join(" ")}>
      {props.label ? (
        <FormLabel htmlFor="location">{props.label}</FormLabel>
      ) : null}
      <div ref={suggestionsRef}>
        <div className={styles.inputWrapper}>
          <Input
            autoComplete="off"
            id="location"
            // If the component is being controlled, display the value from props and disable typing.
            value={
              props.value
                ? `${props.value.text.mainText} ${props.value.text.secondaryText}`
                : value
            }
            onFocus={handleFocus}
            onChange={handleInput}
            disabled={typeof props.value !== "undefined"}
            placeholder={
              props.placeholder || "Enter Address, City, or Zip Code"
            }
            readonly={typeof location !== "undefined"}
          />
          <button
            className={[
              styles.xWrapper,
              !props.value ? styles.hidden : null,
            ].join(" ")}
            onClick={() => {
              setValue("");
              props.onSelect(undefined);
              setLocation(undefined);
            }}
          >
            <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
          </button>
        </div>
        {suggestions && (
          <ul
            className={[
              styles.suggestionList,
              suggestionsType === "relative" ? styles.relative : null,
            ].join(" ")}
          >
            {suggestions.map((s) => (
              <li
                key={s.placePrediction.placeId}
                onClick={() => {
                  handleSelect(s);
                }}
                className={styles.suggestion}
              >
                <strong>
                  {s.placePrediction.structuredFormat.mainText.text}
                </strong>{" "}
                <small>
                  {s.placePrediction.structuredFormat.secondaryText?.text}
                </small>
              </li>
            ))}
          </ul>
        )}
        {placeDetailError && (
          <div className={styles.errorMessage}>
            There was an error retrieving details about this location. Try again
            later or try a different location.
          </div>
        )}
        {autoCompleteError && (
          <div className={styles.errorMessage}>
            There was an error retrieving place suggestions. Please try again
            later.
          </div>
        )}
      </div>
    </div>
  );
};

export default NewLocationInput;
