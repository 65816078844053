import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./appointmentStatusStyles.module.css";
import {
  faCheckCircle,
  faCircle,
  faCircleHalfStroke,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AppointmentStatus as AppointmentStatusType } from "../../types";

type AppointmentStatusProps = {
  status: AppointmentStatusType;
  className?: string;
  shortText?: boolean;
};

const AppointmentStatus = (props: AppointmentStatusProps) => {
  const text = {
    confirmed: {
      longText: "Confirmed",
      shortText: "Confirmed",
    },
    accepted: {
      longText: "Accepted by artist - deposit required to confirm",
      shortText: "Accepted",
    },
    pending: {
      longText: "Pending - waiting on artist response",
      shortText: "Pending",
    },
    declined: {
      longText: "Declined",
      shortText: "Declined",
    },
    cancelled: {
      longText: "Cancelled",
      shortText: "Cancelled",
    },
    expired: {
      longText: "Expired - artist did not accept or deny request",
      shortText: "Expired",
    },
  };

  const statusIcon = () => {
    switch (props.status) {
      case "confirmed":
        return faCheckCircle;
      case "accepted":
        return faCircleHalfStroke;
      case "pending":
        return faCircleHalfStroke;
      case "declined":
        return faXmarkCircle;
      case "cancelled":
        return faXmarkCircle;
      default:
        return faCircle;
    }
  };
  return (
    <div className={[styles.wrapper, props.className].join(" ")}>
      <FontAwesomeIcon
        icon={statusIcon()}
        className={[styles.circle, styles[props.status]].join(" ")}
      />
      <div className={styles.label}>
        {props.shortText
          ? text[props.status].shortText.toUpperCase()
          : text[props.status].longText.toUpperCase()}
      </div>
    </div>
  );
};

export default AppointmentStatus;
