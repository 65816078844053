import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useClickOutside from "../../hooks/useClickOutside";
import Button from "../Button/Button";
import styles from "./popUpStyles.module.css";
import { CSSProperties, ReactNode, useRef } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

type PopUpProps = {
  handleExit: () => void;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

const PopUp = (props: PopUpProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, props.handleExit);
  return (
    <div className={[styles.layout].join(" ")}>
      <div
        className={[styles.wrapper, props.className].join(" ")}
        style={props.style}
        ref={wrapperRef}
      >
        {props.children}
        <Button
          circle
          color="white"
          className={styles.exitButton}
          onClick={props.handleExit}
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </div>
    </div>
  );
};

export default PopUp;
