import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../../components/Button/Button";
import styles from "./signUpForProStyles.module.css";
import { faCreditCard, faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faFileInvoiceDollar,
  faReceipt,
  faTicket,
  faTicketAlt,
  faTicketSimple,
} from "@fortawesome/free-solid-svg-icons";

const SignUpForPro = () => {
  return (
    <div>
      <div className={styles.banner}>
        <h1>
          <FontAwesomeIcon
            icon={faStar}
            style={{ fontSize: "2em", marginBottom: "10px" }}
          />
        </h1>
        <h1 className="mb20">TatMap Pro</h1>
        <h2 className="mb20">Monetization tools for professional artists</h2>
        <p className="mb20">Pro is only $5 / month. Cancel anytime.</p>
        <Button href="/checkout" color="blue">
          Sign Up Now
        </Button>
      </div>
      <div className={styles.featuresWrapper}>
        <div className={styles.feature}>
          <FontAwesomeIcon icon={faCreditCard} className={styles.featureIcon} />
          <h2 className="mb5">Collect Deposits</h2>
          <p>
            Pro accounts can require deposits in order to confirm appointments.
            No more hounding clients to send you a deposit. TatMap makes it easy
            by allowing clients to pay via credit card and by sending reminder
            emails when a client hasn't paid.
          </p>
        </div>
        <div className={styles.feature}>
          <FontAwesomeIcon icon={faTicket} className={styles.featureIcon} />
          <h2 className="mb5">Sell Flash Tickets</h2>
          <p>
            Pro accounts can sell flash tickets, which clients can purchase as
            permission for another artist to tattoo a design. Artists choose
            whether or not they accept flash tickets. Flash tickets can be a way
            for new artists to get more business, or for non-tattoo artists to
            monetize their practice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpForPro;
