import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownLong,
  faArrowUpLong,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styles from "./numberInputStyles.module.css";

type NumberInputProps = {
  label?: string;
  value: string | null;
  onChange: (v: string | null) => void;
  numberType?: string;
  max?: number;
  disabled?: boolean;
  min?: number;
};

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
  const rx =
    props.numberType === "money"
      ? new RegExp(/^\d*\.?\d*$/)
      : new RegExp(/^\d+$/);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === "") {
      props.onChange(null);
    }
    if (rx.test(event.currentTarget.value)) {
      const val = event.currentTarget.value;
      if (props.max && props.min) {
        if (parseFloat(val) <= props.max && parseFloat(val) >= props.min)
          props.onChange(val);
      } else if (props.max) {
        if (parseFloat(val) <= props.max) props.onChange(val);
      } else if (props.min) {
        if (parseFloat(val) >= props.min) props.onChange(val);
      } else {
        props.onChange(val);
      }
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    let newVal;
    if (event.currentTarget.id === "add") {
      newVal = props.value ? parseFloat(props.value) + 1 : 1;
    } else {
      newVal = props.value ? parseFloat(props.value) - 1 : null;
    }

    props.onChange(newVal ? newVal.toString() : null);
  };
  return (
    <div className={styles.inputWrapper}>
      {props.label ? (
        <div className={styles.inputLabel}>{props.label}</div>
      ) : null}
      <input
        disabled={props.disabled}
        value={props.value || ""}
        onChange={handleChange}
        onBlur={() => {
          if (props.value === null) {
            props.onChange("1");
          }
        }}
        className={[
          styles.inputElement,
          props.numberType === "money" ? styles.adornment : "",
        ].join(" ")}
      ></input>
      {props.numberType === "money" ? (
        <span className={styles.dollarSign}>$</span>
      ) : null}
      <button
        id="add"
        onClick={handleClick}
        className={[styles.arrowButton, styles.upButton].join(" ")}
        disabled={props.disabled}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </button>
      <button
        id="subtract"
        onClick={handleClick}
        className={[styles.arrowButton, styles.downButton].join(" ")}
        disabled={props.disabled}
      >
        <FontAwesomeIcon icon={faCaretDown} />
      </button>
    </div>
  );
};

export default NumberInput;
