import { PropsWithChildren } from "react";
import styles from "./wrapperStyles.module.css";

type WrapperProps = {
  children?: React.ReactNode;
};

const Wrapper = (props: WrapperProps) => {
  return <div className={styles.wrapper}>{props.children}</div>;
};

export default Wrapper;
