import { useRef } from "react";
import styles from "./dropdownStyles.module.css";
import useClickOutside from "../../hooks/useClickOutside";

type DropdownProps = {
  left?: boolean;
  handleExit: () => void;
  children?: React.ReactNode;
  className?: string;
};

const Dropdown = (props: DropdownProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(wrapperRef, props.handleExit);

  return (
    <div
      className={[
        styles.dropdownWrapper,
        props.left ? styles.left : styles.right,
        props.className,
      ].join(" ")}
      ref={wrapperRef}
    >
      {props.children}
    </div>
  );
};

export default Dropdown;
