import { MouseEvent } from "react";
import styles from "./anchorStyles.module.css";

type AnchorProps = {
  children: React.ReactNode;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  style?: React.CSSProperties;
};

const Anchor = (props: AnchorProps) => {
  return (
    <a
      className={[styles.anchor, props.className || null].join(" ")}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </a>
  );
};

export default Anchor;
