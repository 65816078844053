import {
  Auth,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from "firebase/auth";
import { auth, functions } from "../../services/firebase/firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";
import styles from "./emailVerificationStyles.module.css";
import { TMUserContext, UserContext } from "../../App";
import { PasswordInput } from "../../components/Auth/Auth";
import { fetchUserWithImageUrl } from "../../utils";
import { httpsCallable } from "firebase/functions";
import { MyUser } from "../../types";

const logo = require("../../assets/logo_yellow.png");

type UserMgmtProps = {
  actionCode: string;
  continueUrl: string;
  lang: string;
};

const RecoverEmail = (props: UserMgmtProps) => {
  function handleRecoverEmail(authObj: Auth, actionCode: string, lang: string) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail: string | undefined;
    // Confirm the action code is valid.
    checkActionCode(auth, actionCode)
      .then((info) => {
        // Get the restored email address.
        restoredEmail = info["data"]["email"] || undefined;

        // Revert to the old email.
        return applyActionCode(auth, actionCode);
      })
      .then(() => {
        // Account email reverted to restoredEmail
        // TODO: Display a confirmation message to the user.
        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        /*
          sendPasswordResetEmail(auth, restoredEmail)
            .then(() => {
              // Password reset confirmation sent. Ask user to check their email.
            })
            .catch((error) => {
              // Error encountered while sending password reset code.
            });*/
      })
      .catch((error) => {
        // Invalid code.
      });
  }
  return <div></div>;
};

type VerificationErroProps = {
  code: string;
};

const VerificationError = (props: VerificationErroProps) => {
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [emailResent, setEmailResent] = useState(false);
  if (emailResent) {
    return <div>The verification email was resent to {user?.email}</div>;
  }
  return (
    <div className={["flexColumn", "textCenter"].join(" ")}>
      <FontAwesomeIcon
        icon={faXmarkCircle}
        style={{ fontSize: "3em" }}
        className={["mb20", styles.errorIcon].join(" ")}
      />
      <div className={["mb20"].join(" ")}>
        There was an error verifying your email address.
      </div>
      <div className={["mb20"].join(" ")}>Error code: {props.code}</div>
      <div>
        <Button
          onClick={async (e) => {
            e.preventDefault();
            if (user) {
              await sendEmailVerification(user);
              setEmailResent(true);
            }
          }}
        >
          Resend Verification Email
        </Button>
      </div>
    </div>
  );
};

const EmailVerified = () => {
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState(6);

  useEffect(() => {
    if (timeRemaining) {
      if (timeRemaining === 1) {
        navigate("/home");
      } else {
        const timeout = setTimeout(() => {
          setTimeRemaining((current) => current! - 1);
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [timeRemaining]);

  return (
    <div className={["flexColumn", "textCenter"].join(" ")}>
      <FontAwesomeIcon
        icon={faCheckCircle}
        className={["mb20", styles.successIcon].join(" ")}
      />
      <div>Your email address is now verified.</div>
      <div className={"mb20"}>
        You will be redirected to your dashboard in {timeRemaining} seconds.
      </div>
      <div>
        <Button
          onClick={() => {
            navigate("/home");
          }}
        >
          Go to Dashboard
        </Button>
      </div>
    </div>
  );
};

const VerifyEmail = (props: UserMgmtProps) => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailVerificationError, setEmailVerificationError] =
    useState<string>();

  function handleVerifyEmail(
    authObj: Auth,
    actionCode: string,
    continueUrl: string,
    lang: string
  ) {
    applyActionCode(authObj, actionCode)
      .then((resp) => {
        setEmailVerified(true);
      })
      .catch((error) => {
        setEmailVerificationError(error.code);
      });
  }

  useEffect(() => {
    handleVerifyEmail(auth, props.actionCode, props.continueUrl, props.lang);
  }, []);

  return (
    <div className="textCenter">
      <div>
        {emailVerificationError ? (
          <VerificationError code={emailVerificationError} />
        ) : emailVerified ? (
          <EmailVerified />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const ResetPassword = (props: UserMgmtProps) => {
  const [userEmail, setUserEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyErrorMessage, setVerifyErrorMessage] = useState<string>();
  const [updateErrorMessage, setUpdateErrorMessage] = useState<string>();
  const [resettingPassword, setResettingPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState<number>();
  const { setUserData } = useContext(TMUserContext);
  const navigate = useNavigate();

  useEffect(() => {
    verifyPasswordResetCode(auth, props.actionCode)
      .then((email) => {
        setUserEmail(email);
      })
      .catch((err) => {
        setVerifyErrorMessage(err.message);
      });
  }, []);

  useEffect(() => {
    if (timeRemaining) {
      if (timeRemaining === 1) {
        navigate("/home");
      } else {
        const timeout = setTimeout(() => {
          setTimeRemaining((current) => current! - 1);
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [timeRemaining]);

  const handleReset = () => {
    setResettingPassword(true);
    confirmPasswordReset(auth, props.actionCode, newPassword)
      .then((resp) => {
        signInWithEmailAndPassword(auth, userEmail, newPassword).then(
          (userCredential) => {
            const getUserData = httpsCallable(functions, "getUserData");
            getUserData().then((result) => {
              const u = result.data as MyUser;
              setUserData(u || null);
              setSuccess(true);
              setTimeRemaining(6);
            });
          }
        );
      })
      .catch((error) => {
        setUpdateErrorMessage(error.message);
      });
  };

  if (verifyErrorMessage) {
    return (
      <div className="textCenter">
        <FontAwesomeIcon icon={faXmarkCircle} className={styles.errorIcon} />
        <div>{verifyErrorMessage}</div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="textCenter">
        <FontAwesomeIcon icon={faCheckCircle} className={styles.successIcon} />
        <div>Password updated successfully!</div>
        <div className="mb20">
          Redirecting to dashboard in {timeRemaining ? timeRemaining - 1 : "-"}{" "}
          seconds
        </div>
        <Button
          onClick={() => {
            navigate("/home");
          }}
        >
          Go to Dashboard
        </Button>
      </div>
    );
  }

  return (
    <div className="flexColumn">
      <div className="mb10">{userEmail}</div>
      <PasswordInput
        value={newPassword}
        placeholder="Password"
        onChange={(v) => {
          setNewPassword(v);
        }}
        className="mb20"
      ></PasswordInput>
      <Button onClick={handleReset} loading={resettingPassword}>
        Save New Password
      </Button>
      {updateErrorMessage && (
        <div style={{ color: "red" }}>{updateErrorMessage}</div>
      )}
    </div>
  );
};

const EmailVerification = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode")!;
  const code = searchParams.get("oobCode")!;
  const apiKey = searchParams.get("apiKey")!;
  const continueUrl =
    searchParams.get("continueUrl") || "https://demo.tatmap.io";
  const lang = searchParams.get("lang") || "en";

  const renderMode = () => {
    switch (mode) {
      case "resetPassword":
        return (
          <ResetPassword
            actionCode={code}
            continueUrl={continueUrl}
            lang={lang}
          />
        );
      case "verifyEmail":
        return (
          <VerifyEmail
            actionCode={code}
            continueUrl={continueUrl}
            lang={lang}
          />
        );
      case "recoverEmail":
        return (
          <RecoverEmail
            actionCode={code}
            continueUrl={continueUrl}
            lang={lang}
          />
        );
      default:
        return <div>Invalid Code</div>;
    }
  };

  return (
    <div className={[styles.wrapper, "flexColumn"].join(" ")}>
      <div className="textCenter">
        <img src={logo} className={[styles.logo, "mb20"].join(" ")} />
      </div>
      {renderMode()}
    </div>
  );
};

export default EmailVerification;
