import { ChangeEvent, MouseEvent, useRef, useState } from "react";
import Button, { ButtonColorOptions } from "../Button/Button";
import useClickOutside from "../../hooks/useClickOutside";
import styles from "./dropdownButtonStyles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faChevronDown } from "@fortawesome/free-solid-svg-icons";

type DropdownButtonProps = {
  selected: string;
  options: string[];
  onChange: (newVal: string) => void;
  color?: ButtonColorOptions;
  thin?: boolean;
  direction?: "left" | "right";
};

const DropdownButton = (props: DropdownButtonProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, () => {
    setShowOptions(false);
  });
  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <Button
        thin={props.thin}
        color={props.color}
        onClick={() => {
          setShowOptions((current) => !current);
        }}
      >
        {props.selected}
        <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: "10px" }} />
      </Button>

      {showOptions && (
        <div
          className={[
            styles.optionsWrapper,
            props.direction === "left" && styles.left,
          ].join(" ")}
        >
          {props.options.map((o) => (
            <button
              className={styles.option}
              key={o}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                props.onChange(o);
                setShowOptions(false);
              }}
            >
              {o.toUpperCase()}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
