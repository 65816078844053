import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../App";
import { EmailNotification, UserSettings } from "../../../../types";
import { fetchUserSettings } from "../../../../utils";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../services/firebase/firebase";
import Spinner from "../../../../components/Spinner/Spinner";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Button from "../../../../components/Button/Button";
import styles from "../../settingsStyles.module.css";

const NotificationSettings = () => {
  const session = useContext(UserContext);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedEvents, setSelectedEvents] = useState<EmailNotification[]>([]);
  const [userSettings, setUserSettings] = useState<UserSettings>();
  const [savingChanges, setSavingChanges] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);

  useEffect(() => {
    if (session) {
      fetchUserSettings(session.uid).then((u) => {
        setUserSettings(u);
        setLoadingData(false);
      });
    }
  }, [session]);

  useEffect(() => {
    if (userSettings) {
      setSelectedEvents(userSettings.emailNotifications || []);
    }
  }, [userSettings]);

  useEffect(() => {
    if (changesSaved) {
      const timeout = setTimeout(() => {
        setChangesSaved(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [changesSaved]);

  const handleSaveChanges = async () => {
    if (session) {
      setSavingChanges(true);
      const docRef = doc(db, "userSettings", session.uid);
      await setDoc(
        docRef,
        { emailNotifications: selectedEvents },
        { merge: true }
      );
      const settings = await fetchUserSettings(session.uid);
      setUserSettings(settings);
      setSavingChanges(false);
      setChangesSaved(true);
    }
  };

  if (loadingData) {
    return <Spinner />;
  }

  return (
    <div>
      <h2 className="mb20">Notifications</h2>
      <h4>Email Alerts</h4>
      <p className={styles.description}>
        Control what types of email notifications you receive.
      </p>
      {(
        [
          "New Appointment Request",
          "Appointment Cancellation",
          "Artist Alerts",
        ] as EmailNotification[]
      ).map((event) => (
        <Checkbox
          key={event}
          label={event}
          checked={selectedEvents?.includes(event) || false}
          onChange={() => {
            if (selectedEvents.includes(event)) {
              setSelectedEvents((current) =>
                current.filter((element) => element !== event)
              );
            } else {
              setSelectedEvents((current) => [...current!, event]);
            }
          }}
        />
      ))}
      <Button onClick={handleSaveChanges} loading={savingChanges}>
        Save Changes
      </Button>
      {changesSaved ? (
        <div className={styles.fadeout}>Changes Saved!</div>
      ) : null}
    </div>
  );
};

export default NotificationSettings;
