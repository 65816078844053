import { Fragment } from "react/jsx-runtime";
import styles from "./stepperStyles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button/Button";
import { ReactNode } from "react";

type StepperFooterProps = {
  totalSteps: number;
  currentStep: number;
  onNext: () => void;
  nextDisabled?: boolean;
  onBack: () => void;
  onSubmit?: () => void;
  submitText?: string;
  submitButton?: ReactNode;
};

export const StepperFooter = (props: StepperFooterProps) => {
  return (
    <div className={styles.footerWrapper}>
      <div>
        {props.currentStep !== 0 && (
          <button
            className={[styles.footerButton, styles.back].join(" ")}
            onClick={props.onBack}
          >
            BACK
          </button>
        )}
      </div>
      <div>
        {props.currentStep !== props.totalSteps - 1 ? (
          <button
            className={[styles.footerButton, styles.next].join(" ")}
            onClick={props.onNext}
            disabled={props.nextDisabled}
          >
            NEXT
          </button>
        ) : (
          props.submitButton || null
        )}
      </div>
    </div>
  );
};

type StepperProps = {
  steps: string[];
  currentStep: number;
};

const Stepper = (props: StepperProps) => {
  return (
    <div className={styles.wrapper}>
      {props.steps.map((step, i) => {
        return (
          <div
            key={step}
            className={[
              styles.stepWrapper,
              i > props.currentStep && styles.futureStep,
              i === props.currentStep && styles.currentStep,
            ].join(" ")}
          >
            <div className={[styles.number].join(" ")}>
              {i < props.currentStep ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <span>{i + 1}</span>
              )}
            </div>
            <div className={styles.stepText}>{step}</div>
            {i !== props.steps.length - 1 && (
              <div className={styles.line}></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
