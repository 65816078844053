import { useContext, useEffect, useState } from "react";
import { MyUser } from "../types";
import { httpsCallable } from "firebase/functions";
import { functions } from "../services/firebase/firebase";
import { UserContext } from "../App";

const useUserData = (reloadTriggers?: any[]) => {
    const session = useContext(UserContext);
    const [userData, setUserData] = useState<MyUser>();

    useEffect(() => {
        if(session) {
            const getUserData = httpsCallable<undefined, MyUser>(functions, 'getUserData')
            getUserData().then(result => {
                setUserData(result.data)
            }).catch(err => {console.log(err)});
        }
    }, [session, ...(reloadTriggers || [])])

    return userData;
}

export default useUserData;