import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  days,
  fetchFlashPost,
  fetchImageUrl,
  fetchOpening,
  fetchUserByUsername,
  months,
} from "../../utils";
import { FlashPost, Opening, TMUser } from "../../types";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import styles from "./bookingRequestStyles.module.css";
import RadioButton from "../../components/RadioButton/RadioButton";
import Button from "../../components/Button/Button";
import FlashPopUp from "../../components/FlashPopUp/FlashPopUp";
import OpeningsPopUp from "../../components/OpeningsPopUp/OpeningsPopUp";
import { TMUserContext, UserContext } from "../../App";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db, functions, storage } from "../../services/firebase/firebase";
import TextArea from "../../components/TextArea/TextArea";
import Spinner from "../../components/Spinner/Spinner";
import Stepper, { StepperFooter } from "../../components/Stepper/Stepper";
import Input from "../../components/Input/Input";
import Anchor from "../../components/Anchor/Anchor";
import FormLabel from "../../components/FormLabel/FormLabel";
import Auth, { PasswordInput, UsernameInput } from "../../components/Auth/Auth";
import FileUpload from "../../components/FileUpload/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { ref, uploadBytes } from "firebase/storage";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import PhoneInput from "../../components/PhoneInput/PhoneInput";
import { httpsCallable } from "firebase/functions";

type RowProps = {
  children?: React.ReactNode;
};

const Row = (props: RowProps) => {
  return <div className={styles.row}>{props.children}</div>;
};

type SuccessProps = {
  email: string;
  isProUser: boolean;
  depositRequired?: boolean;
  depositAmount?: number;
};

const Success = (props: SuccessProps) => {
  const navigate = useNavigate();
  const session = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [usernameIsValid, setUsernameIsValid] = useState(false);
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(false);
  const [usernameIsChecking, setUsernameIsChecking] = useState(false);

  return (
    <div className={styles.successWrapper}>
      <FontAwesomeIcon
        icon={faCheckCircle}
        className={[styles.successIcon, "mb20"].join(" ")}
      />
      <h2 className="mb20">Your booking request has been submitted!</h2>
      <div className="mb20">
        An email confirmation has been sent to {props.email}
      </div>

      <div className={[styles.nextStepsWrapper, "mb40"].join(" ")}>
        <h3>Next Steps:</h3>
        <ol>
          <li className="mb10">
            The artist will either accept or deny your appointment request. You
            will be notified via email and TatMap notifications.
          </li>
          {props.isProUser && props.depositRequired && props.depositAmount ? (
            <li>
              If your appointment is accepted, you will be prompted to pay a
              deposit of ${props.depositAmount / 100}.{" "}
              <strong>
                Your appointment is not confirmed until you pay this deposit.
              </strong>
            </li>
          ) : (
            <li>
              This artist does not handle deposits through TatMap. Coordinate
              with your artist to determine whether or not a deposit is required
              to confirm your appointment.
            </li>
          )}
        </ol>
        <p>Thanks for using TatMap!</p>
      </div>
      {session ? (
        <Button
          onClick={() => {
            navigate("/home");
          }}
        >
          Go to Dashboard
        </Button>
      ) : (
        <div style={{ maxWidth: "500px" }} className={"flexColumn"}>
          <h4 style={{ textAlign: "center" }} className="mb20">
            Choose a username and password to track all of your appointments and
            discover new artists on TatMap:
          </h4>
          <FormLabel>Username</FormLabel>
          <UsernameInput
            value={username}
            onChange={(v) => {
              setUsername(v);
            }}
            onValidityChange={(validity) => {
              setUsernameIsValid(validity);
            }}
            onAvailabilityChange={(availability) => {
              setUsernameIsAvailable(availability);
            }}
            onCheckingChange={(status) => {
              setUsernameIsChecking(status);
            }}
          />
          <FormLabel>Password</FormLabel>
          <PasswordInput
            className={"mb20"}
            value={password}
            onChange={(v) => {
              setPassword(v);
            }}
          ></PasswordInput>
          <Button
            onClick={() => {}}
            disabled={
              !usernameIsAvailable ||
              !usernameIsValid ||
              usernameIsChecking ||
              !password
            }
          >
            Create Account
          </Button>
        </div>
      )}
    </div>
  );
};

type Artist = TMUser & { isProUser: boolean };

const BookingRequest = () => {
  const session = useContext(UserContext);
  const { userData } = useContext(TMUserContext);
  const artistId: string = useParams().artistId!;
  const idType: string | undefined = useParams().idType;
  const additionalId: string | undefined = useParams().additionalId;
  const [artistData, setArtistData] = useState<Artist>();
  const [appointmentType, setAppointmentType] = useState("Flash");
  const [selectedFlash, setSelectedFlash] = useState<FlashPost>();
  const [selectedFlashUrl, setSelectedFlashUrl] = useState<string>();
  const [openingData, setOpeningData] = useState<Opening>();
  const [showFlashPopUp, setShowFlashPopUp] = useState(false);
  const [showOpeningsPopUp, setShowOpeningsPopUp] = useState(false);
  const [showSignInPopUp, setShowSignInPopUp] = useState(false);
  const [showSignUpPopUp, setShowSignUpPopUp] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [placement, setPlacement] = useState("");
  const [height, setHeight] = useState("");
  const [firstName, setFirstName] = useState(userData?.firstName || "");
  const [lastName, setLastName] = useState(userData?.lastName || "");
  const [email, setEmail] = useState(session?.email || "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCountry, setPhoneCountry] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [referencePhoto, setReferencePhoto] = useState<File>();
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const getData = async () => {
    setLoadingData(true);
    const dataArtist = await fetchUserByUsername(artistId);
    const getArtist = httpsCallable<{ username: string }, Artist>(
      functions,
      "getArtistDataByUsername"
    );
    getArtist({ username: artistId }).then((result) => {
      setArtistData(result.data);
    });

    if (additionalId && idType === "o") {
      const dataOpening = await fetchOpening(dataArtist.docId, additionalId);
      setOpeningData(dataOpening);
    } else if (additionalId && idType === "f") {
      const dataFlash = await fetchFlashPost(additionalId, dataArtist.docId);
      setSelectedFlash(dataFlash);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedFlash) {
      fetchImageUrl(selectedFlash?.mainImageUrl).then((url) => {
        setSelectedFlashUrl(url);
      });
    }
  }, [selectedFlash]);

  const d = new Date();

  const referenceFilePath = `/${
    session?.uid || "guest"
  }/referencePhotos/${d.toString()}`;

  const handleSubmit = async () => {
    if (artistData) {
      const requestData = {
        clientId: session?.uid || "guest",
        clientData: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          pronouns: pronouns,
          phone: { country: phoneCountry, digits: phoneNumber },
        },
        artistId: artistData.docId,
        openingId: openingData?.docId,
        startTime: { ...openingData?.startTime },
        date: openingData?.date,
        location: { ...openingData?.location },
        ...(placement ? { placement: placement } : {}),
        ...(height ? { height: height } : {}),
        height: height,
        apptType: appointmentType,
        ...(appointmentType === "Flash"
          ? { flashId: selectedFlash?.docId }
          : {}),
        imgLocation:
          appointmentType === "Flash"
            ? selectedFlash?.mainImageUrl
            : referenceFilePath,
        status: "pending",
        message: message || null,
        createdAt: serverTimestamp(),
      };
      setSubmitting(true);
      if (referencePhoto) {
        const storageRef = ref(storage, referenceFilePath);
        await uploadBytes(storageRef, referencePhoto);
      }
      const apptRef = doc(collection(db, "appointments"));
      await setDoc(apptRef, requestData);
      setSubmitting(false);
      setSuccess(true);
    }
  };

  const formIsValid = () => {
    if (!openingData) return false;
    if (appointmentType === "Flash" && !selectedFlash) return false;
    if (appointmentType === "Custom" && !referencePhoto) return false;
    return true;
  };

  if (success) {
    return (
      <Layout>
        <Success
          email={email}
          isProUser={artistData?.isProUser || false}
          depositRequired={artistData?.depositRequired || false}
          depositAmount={artistData?.depositAmount}
        ></Success>
      </Layout>
    );
  }

  if (artistData && !loadingData) {
    return (
      <Layout
        showPopUp={
          showFlashPopUp ||
          showOpeningsPopUp ||
          showSignInPopUp ||
          showSignUpPopUp
        }
        popUpComponent={
          showFlashPopUp ? (
            <FlashPopUp
              artistId={artistData?.docId}
              handleExit={() => {
                setShowFlashPopUp(false);
              }}
              handleSelect={(data: FlashPost) => {
                setSelectedFlash(data);
                setShowFlashPopUp(false);
              }}
            />
          ) : showOpeningsPopUp ? (
            <OpeningsPopUp
              handleExit={() => {
                setShowOpeningsPopUp(false);
              }}
              handleSelect={(d) => {
                setOpeningData(d);
              }}
              artistId={artistData.docId}
            ></OpeningsPopUp>
          ) : showSignInPopUp ? (
            <Auth
              handleExit={() => {
                setShowSignInPopUp(false);
              }}
              redirect={false}
            />
          ) : (
            showSignUpPopUp && (
              <Auth
                handleExit={() => {
                  setShowSignUpPopUp(false);
                }}
                signup={true}
                redirect={false}
              />
            )
          )
        }
      >
        <h2 className={styles.header}>Booking Request</h2>
        <div className={styles.artistDetails}>
          <ProfileImage
            imgLocation={artistData?.imgLocation}
            size="xs"
            href={`/u/${artistData?.username}`}
          />
          <div style={{ textAlign: "center" }}>
            <h4>
              {artistData?.firstName} {artistData?.lastName}
            </h4>
            <Anchor
              style={{ fontSize: "0.8em" }}
              onClick={() => {
                navigate(`/u/${artistData?.username}`);
              }}
            >
              @{artistData?.username}
            </Anchor>
          </div>
        </div>

        {artistData.booksOpen ? (
          <div className={styles.wrapper}>
            <Stepper
              steps={["Your Info", "Design", "Date & Time"]}
              currentStep={currentStep}
            />
            {currentStep === 1 && (
              <div style={{ marginTop: "20px" }}>
                <Row>
                  <h4 className={styles.rowLabel}>Design Type</h4>
                  <div className={styles.radioWrapper}>
                    <RadioButton
                      checked={appointmentType === "Flash"}
                      id="Flash"
                      label="Flash"
                      onChange={(v) => {
                        setAppointmentType(v);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    <RadioButton
                      checked={appointmentType === "Custom"}
                      id="Custom"
                      label="Custom"
                      onChange={(v) => {
                        setAppointmentType(v);
                      }}
                    />
                  </div>
                  {appointmentType === "Flash" ? (
                    <Fragment>
                      <h5 style={{ marginBottom: "10px" }}>Selected Flash</h5>
                      {selectedFlashUrl ? (
                        <div className={styles.selectedFlashWrapper}>
                          <img
                            src={selectedFlashUrl}
                            className={styles.selectedFlashImage}
                          />
                          <Button
                            onClick={() => {
                              setShowFlashPopUp(true);
                            }}
                          >
                            Change
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            onClick={() => {
                              setShowFlashPopUp(true);
                            }}
                          >
                            Select a Design
                          </Button>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <div>
                      <h5>Upload a reference photo (optional)</h5>
                      {referencePhoto ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <img
                            alt="not found"
                            className={[styles.referencePhoto, "mb10"].join(
                              " "
                            )}
                            src={URL.createObjectURL(referencePhoto)}
                          />
                          <FileUpload
                            setFile={() => setReferencePhoto(undefined)}
                          >
                            Change
                          </FileUpload>
                        </div>
                      ) : (
                        <FileUpload
                          image
                          setFile={(f: File) => {
                            setReferencePhoto(f);
                          }}
                        ></FileUpload>
                      )}
                    </div>
                  )}
                </Row>
                <Row>
                  <h4>Placement</h4>
                  <Input
                    type="text"
                    value={placement}
                    onChange={(v) => {
                      setPlacement(v);
                    }}
                    placeholder="Body placement e.g. chest, shoulder, forearm..."
                  />
                </Row>
                <Row>
                  <h4>Height (inches)</h4>
                  <Input
                    type="text"
                    value={height}
                    onChange={(v) => {
                      setHeight(v);
                    }}
                    placeholder="Height in Inches"
                  />
                </Row>
                <Row>
                  <h4 className={styles.rowLabel}>Message (optional)</h4>
                  <TextArea
                    value={message}
                    onChange={(v: string) => {
                      setMessage(v);
                    }}
                  ></TextArea>
                </Row>
              </div>
            )}
            {currentStep === 2 && (
              <div style={{ marginTop: "30px" }}>
                <Row>
                  <h3 className={styles.rowLabel}>Date & Time</h3>
                  {openingData ? (
                    <Fragment>
                      <div
                        className={[styles.dateAndTimeWrapper, "mb10"].join(
                          " "
                        )}
                      >
                        <div className={"mb10"}>
                          <h4>{`${parseInt(openingData.startTime.hour)}:${
                            openingData.startTime.minute
                          } ${openingData.startTime.ampm}`}</h4>
                          <div>{`${days[openingData.date.getDay()]} ${
                            months[openingData.date.getMonth()]
                          } ${openingData.date.getDate()}, ${openingData.date.getFullYear()}`}</div>
                        </div>
                        <div style={{ fontSize: "0.8em" }}>
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className={"mr10"}
                          />
                          {openingData.location.text.mainText}{" "}
                          {openingData.location.text.secondaryText}
                        </div>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            setShowOpeningsPopUp(true);
                          }}
                        >
                          Change
                        </Button>
                      </div>
                    </Fragment>
                  ) : (
                    <Button
                      onClick={() => {
                        setShowOpeningsPopUp(true);
                      }}
                    >
                      Browse Openings
                    </Button>
                  )}
                </Row>
              </div>
            )}
            {currentStep === 0 && (
              <div>
                {!session && (
                  <div className={styles.signInWrapper}>
                    <Button
                      style={{ marginBottom: "10px" }}
                      onClick={() => {
                        setShowSignInPopUp(true);
                      }}
                    >
                      Sign In
                    </Button>

                    <Button
                      color="gray"
                      onClick={() => {
                        setShowSignUpPopUp(true);
                      }}
                    >
                      Create Account
                    </Button>
                    <div className={styles.orWrapper}>
                      <div className={styles.line}></div>
                      <div className={styles.orText}>or</div>
                      <div className={styles.line}></div>
                    </div>
                    <h5>Continue as Guest:</h5>
                  </div>
                )}
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div className={styles.nameWrapper}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      value={firstName}
                      onChange={(v) => {
                        setFirstName(v);
                      }}
                    ></Input>
                  </div>
                  <div className={styles.nameWrapper}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      value={lastName}
                      onChange={(v) => {
                        setLastName(v);
                      }}
                    ></Input>
                  </div>
                </div>
                <Row>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(v) => {
                      setEmail(v);
                    }}
                  ></Input>
                  <FormLabel>Phone Number</FormLabel>
                  <PhoneInput
                    value={phoneNumber}
                    onChange={(v) => {
                      setPhoneNumber(v);
                    }}
                  />
                  <FormLabel>Pronouns</FormLabel>
                  <Input
                    type="text"
                    value={pronouns}
                    onChange={(v) => {
                      setPronouns(v);
                    }}
                  ></Input>
                </Row>
              </div>
            )}
            <StepperFooter
              currentStep={currentStep}
              totalSteps={3}
              onNext={() => {
                setCurrentStep((current) => current + 1);
              }}
              onBack={() => {
                setCurrentStep((current) => current - 1);
              }}
              submitButton={
                <Button
                  onClick={handleSubmit}
                  loading={submitting}
                  disabled={!formIsValid()}
                >
                  Submit Booking Request
                </Button>
              }
            />
          </div>
        ) : (
          <div className="textCenter" style={{ marginTop: "30px" }}>
            This artist is not currently accepting appointments. Please check
            back later.
          </div>
        )}
      </Layout>
    );
  }

  return (
    <Layout>
      <Spinner />
    </Layout>
  );
};

export default BookingRequest;
