import { ChangeEvent, Fragment, useContext, useEffect, useState } from "react";
import { fetchImageUrl, fetchMessages, fetchUser } from "../../utils";
import { Message, MessageThread, TMUser } from "../../types";
import styles from "./messageThreadDetailStyles.module.css";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { TMUserContext, UserContext } from "../../App";
import ProfileImage from "../ProfileImage/ProfileImage";
import {
  collection,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import Spinner from "../Spinner/Spinner";

type ReceivedMessageProps = {
  data: Message;
  imgUrl?: string;
  username: string;
};

const ReceivedMessage = (props: ReceivedMessageProps) => {
  return (
    <div className={[styles.receivedMessageWrapper].join(" ")}>
      <ProfileImage
        href={`/u/${props.username}`}
        imgUrl={props.imgUrl}
        size="xxs"
      />
      <div className={[styles.receivedMessage, styles.message].join(" ")}>
        {props.data.content}
      </div>
    </div>
  );
};

type SentMessageProps = {
  data: Message;
  imgUrl?: string;
};

const SentMessage = (props: SentMessageProps) => {
  return (
    <div
      className={[styles.sentMessageWrapper, styles.messageWrapper].join(" ")}
    >
      <div className={[styles.sentMessage, styles.message].join(" ")}>
        {props.data.content}
      </div>
      <ProfileImage imgUrl={props.imgUrl} size="xxs" />
    </div>
  );
};

type MessageThreadDetailProps = {
  data: MessageThread;
  onExit: () => void;
  loadMessageThreads: () => void;
};

const MessageThreadDetail = (props: MessageThreadDetailProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [otherUser, setOtherUser] = useState<TMUser>();
  const [otherUserImgUrl, setOtherUserImgUrl] = useState<string>();
  const [messageContent, setMessageContent] = useState<string>("");
  const [loadingMessages, setLoadingMessages] = useState(true);

  const session = useContext(UserContext);

  const { userData } = useContext(TMUserContext);

  const loadMessages = (currentUid: string, otherUid: string) => {
    fetchMessages(currentUid, otherUid).then((data) => {
      setMessages(data);
      setLoadingMessages(false);
    });
  };

  const updateUnreadCount = async () => {
    if (session) {
      const threadRef = doc(
        db,
        "users",
        session.uid,
        "messageThreads",
        props.data.docId
      );
      const thread = await getDoc(threadRef);
      if (thread.exists()) {
        await setDoc(
          threadRef,
          {
            unreadMessages: 0,
          },
          { merge: true }
        );
      }
      props.loadMessageThreads();
    }
  };

  useEffect(() => {
    if (session && otherUser) {
      loadMessages(session.uid, otherUser.docId);
    }
  }, [session, otherUser]);

  useEffect(() => {
    fetchUser(props.data.docId).then((data) => {
      setOtherUser(data);

      fetchImageUrl(data.imgLocation).then((url) => {
        setOtherUserImgUrl(url);
      });
    });
  }, [props.data]);

  useEffect(() => {
    updateUnreadCount();
  }, []);

  const handleSend = async () => {
    if (messageContent && session && otherUser) {
      const content = messageContent;
      setMessageContent("");
      const threadRef = doc(
        db,
        "users",
        session.uid,
        "messageThreads",
        props.data.docId
      );
      const otherThreadRef = doc(
        db,
        "users",
        props.data.docId,
        "messageThreads",
        session.uid
      );
      const setCurrentDoc = setDoc(
        threadRef,
        {
          lastMessage: content,
          lastSender: session.uid,
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );
      const setOtherDoc = setDoc(
        otherThreadRef,
        {
          lastMessage: content,
          lastSender: session.uid,
          updatedAt: serverTimestamp(),
          unreadMessages: increment(1),
        },
        { merge: true }
      );
      await Promise.all([setCurrentDoc, setOtherDoc]);

      const messageRef = doc(
        collection(
          db,
          "users",
          session.uid,
          "messageThreads",
          threadRef.id,
          "messages"
        )
      );
      await setDoc(messageRef, {
        sender: session.uid,
        recipient: props.data.docId,
        content: content,
        createdAt: serverTimestamp(),
        read: false,
      });
      loadMessages(session.uid, otherUser.docId);
      props.loadMessageThreads();
    }
  };

  if (session && otherUser) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <Button
            onClick={props.onExit}
            circle
            color="white"
            style={{ fontSize: "20px", marginRight: "10px" }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <div className={styles.nameWrapper}>
            <h4>
              {otherUser?.firstName} {otherUser?.lastName}
            </h4>
            <div className={styles.handle}>@{otherUser?.username}</div>
          </div>
        </div>
        {loadingMessages ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className={styles.messagesWrapper}>
              {messages.map((d) =>
                d.sender === session.uid ? (
                  <SentMessage
                    key={d.docId}
                    data={d}
                    imgUrl={userData?.imageURL}
                  ></SentMessage>
                ) : (
                  <ReceivedMessage
                    key={d.docId}
                    data={d}
                    imgUrl={otherUserImgUrl}
                    username={otherUser.username}
                  ></ReceivedMessage>
                )
              )}
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.textAreaWrapper}>
                <textarea
                  className={styles.textArea}
                  value={messageContent}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    setMessageContent(e.currentTarget.value);
                  }}
                />
              </div>
              <Button onClick={handleSend} circle>
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default MessageThreadDetail;
