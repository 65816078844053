import { Fragment, useContext, useEffect, useState } from "react";
import { TMUserContext } from "../../App";
import Layout from "../../components/Layout/Layout";
import styles from "./settingsStyles.module.css";
import SideNav from "../../components/SideNav/SideNav";
import { Link, useNavigate, useParams } from "react-router-dom";
import EditProfile from "./views/EditProfile/EditProfile";
import AccountSettings from "./views/AccountSettings/AccountSettings";
import StudioLocationSettings from "./views/StudioLocationSettings/StudioLocationSettings";
import NotificationSettings from "./views/NotificationSettings/NotificationSettings";
import BookingSettings from "./views/BookingSettings/BookingSettings";
import {
  faBell,
  faCalendar,
  faCreditCard,
  faGear,
  faLocationDot,
  faPalette,
  faPencil,
  faUser,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const renderRoute = (path: string) => {
  switch (path) {
    case "edit-profile":
      return <EditProfile />;
    case "account":
      return <AccountSettings />;
    case "studio-location":
      return <StudioLocationSettings />;
    case "notifications":
      return <NotificationSettings />;
    case "booking":
      return <BookingSettings />;
    default:
      return <div>Page Not Found</div>;
  }
};

type MyPage = {
  path: string;
  text: string;
  description: string;
  icon: IconDefinition;
  children?: MyPage[];
};

const SettingsPageLink = (props: MyPage) => {
  return (
    <Link to={props.path} className={styles.pageLink}>
      <h4 className={[styles.head, "mb10"].join(" ")}>
        <FontAwesomeIcon icon={props.icon} className="mr5" />
        {props.text}
      </h4>
      <p className={styles.desc}>{props.description}</p>
    </Link>
  );
};

const Settings = () => {
  const { userData } = useContext(TMUserContext);
  const [currentPage, setCurrentPage] = useState<MyPage>();
  const [currentSubPage, setCurrentSubPage] = useState<MyPage>();
  const pages = [
    {
      path: "edit-profile",
      text: "Edit Profile",
      description:
        "Change the information that appears publicly on your profile.\nProfile picture, name, username, bio",
      icon: faPencil,
    },
    {
      path: "account",
      text: "Account",
      description: "Manage account type, password, and personal information",
      icon: faUser,
      children: [
        {
          path: "manage-membership",
          text: "Manage Membership",
          description: "Manage your Pro membership",
          icon: faCreditCard,
        },
        {
          path: "artist-onboarding",
          text: "Artist Onboarding",
          description: "Set up your artist account",
          icon: faPalette,
        },
      ],
    },
    {
      path: "notifications",
      text: "Notifications",
      description: "Choose which events will trigger an email notification",
      icon: faBell,
    },
    ...(userData?.accountType === "artist"
      ? [
          {
            path: "studio-location",
            text: "Studio Location",
            description:
              "Set your studio location for appointments and search results",
            icon: faLocationDot,
          },
        ]
      : []),
    ...(userData?.accountType === "artist"
      ? [
          {
            path: "booking",
            text: "Booking",
            description:
              "Open/close your books, edit your booking form, and manage booking preferences",
            icon: faCalendar,
          },
        ]
      : []),
  ];
  const navigate = useNavigate();
  const { page, subpage } = useParams();

  useEffect(() => {
    if (page) {
      const element = pages.find((p) => p.path === page);
      if (element) {
        setCurrentPage(element as MyPage);
      } else {
        navigate("/settings");
      }
    } else {
      setCurrentPage(undefined);
    }
  }, [page]);

  useEffect(() => {
    if (subpage) {
      const pageElement = pages.find((p) => p.path === page);
      const element = pageElement?.children?.find((c) => c.path === subpage);
      if (element) {
        setCurrentSubPage(element as MyPage);
      } else {
        navigate(`/settings/${pageElement?.path}`);
      }
    } else {
      setCurrentSubPage(undefined);
    }
  }, [subpage]);

  if (!page) {
    return (
      <Layout>
        <div className={styles.settingsPageWrapper}>
          <h2 className="mb20">Settings</h2>
          <div className={styles.pageLinks}>
            {pages.map((p) => (
              <div className={styles.pageLinkWrapper} key={p.text}>
                <SettingsPageLink
                  key={p.text}
                  path={p.path}
                  text={p.text}
                  description={p.description}
                  icon={p.icon}
                ></SettingsPageLink>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className="mb20">
          <Link to="/settings" className={styles.breadcrumb}>
            <FontAwesomeIcon icon={faGear} className="mr5" />
            Settings
          </Link>

          {currentPage && (
            <Fragment>
              <span>{" > "}</span>
              <Link
                to={`/settings/${currentPage.path}` || "/"}
                className={styles.breadcrumb}
              >
                <FontAwesomeIcon icon={currentPage.icon} className="mr5" />
                {currentPage?.text}
              </Link>
            </Fragment>
          )}
          {currentSubPage && (
            <Fragment>
              <span>{" > "}</span>
              <Link
                to={`/settings/${currentPage?.path}/${currentSubPage?.path}`}
                className={styles.breadcrumb}
              >
                <FontAwesomeIcon icon={currentSubPage.icon} className="mr5" />
                {currentSubPage.text}
              </Link>
            </Fragment>
          )}
        </div>

        <div className={styles.contentWrapper}>{renderRoute(page)}</div>
      </div>
    </Layout>
  );
};

export default Settings;
